<template>
    <div>
      <ion-card class="action-card">
        <ion-card-header>
          <ion-card-subtitle>Customer Information</ion-card-subtitle>
          <ion-card-title>{{ name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-card-subtitle>Phone: {{ phone }}</ion-card-subtitle>
          <ion-card-subtitle>Email: {{ emailAddress }}</ion-card-subtitle>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-button size="small" @click="toggleCreateManually">Create Manually</ion-button>
                <ion-item v-if="showPasswordInput">
                  <ion-label position="floating">Password</ion-label>
                  <ion-input class="input-style" type="text" placeholder="New password"
                    @input="password = $event.target.value" v-bind:value="password">
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-button size="small" @click="generatePassword">Generate Password</ion-button>
                <div v-if="password">
                  <ion-card-subtitle>
                    Generated Password:
                  </ion-card-subtitle>
                  <ion-card-title>
                    {{ password }}
                  </ion-card-title>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-button color="primary" class="menu-col-6" size="small" @click="resetPassword" :disabled="!password">Save</ion-button>
    </div>
  </template>
  
  <script>
  import { SendPassword } from "../utils/sendPassword.js";
  import { Api } from '../api/api.js';
  import Swal from "sweetalert2";
  
  export default {
    name: 'CreateNewPassword',
    props: {
      id: {
        type: String,
      }
    },
    created: function () {
      const data = this.$store.state.backConfig.allCustomer.find(c => c._id === this.id);
      if (data) {
        this.name = data?.Name;
        this.phone = data?.Phone;
        this.emailAddress = data?.EmailAddress;
  
        if (data.Company) {
          this.company = data?.Company;
        }
      }
    },
    data() {
      return {
        modelName: 'Customer',
        password: '',
        name: '',
        emailAddress: '',
        phone: '',
        company: '',
        showPasswordInput: false,
      }
    },
    methods: {
      toggleCreateManually() {
        this.showPasswordInput = !this.showPasswordInput;
      },
      generatePassword() {
        const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
        const uppercaseLetters = lowercaseLetters.toUpperCase();
        const numbers = "0123456789";
        const symbols = "!@#$%^&*()_+-=[]{};':\"\\|,.<>/?";
  
        const allChars = lowercaseLetters + uppercaseLetters + numbers + symbols;
        const minLowercase = 1;
        const minUppercase = 1;
        const minNumber = 1;
        const minSymbol = 1;
        const minLength = minLowercase + minUppercase + minNumber + minSymbol + (8 - (minLowercase + minUppercase + minNumber + minSymbol));
  
        function getRandomChar(str) {
          return str.charAt(Math.floor(Math.random() * str.length));
        }
  
        let password = "";
  
        password += getRandomChar(lowercaseLetters);
        password += getRandomChar(uppercaseLetters);
        password += getRandomChar(numbers);
        password += getRandomChar(symbols);
  
        for (let i = password.length; i < minLength; i++) {
          password += getRandomChar(allChars);
        }
  
        password = password.split("").sort(() => Math.random() - 0.5).join("");
        Swal.fire({
          title: "Your temporary password must be at least 8 characters long and include",
          text: "* At least 1 lowercase letter (a-z)\n * At least 1 uppercase letter (A-Z)\n * At least 1 number (0-9)\n * At least 1 symbol (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?)",
          icon: 'warning',
          backdrop: false,
        })
        this.password = password;
        return this.password;
      },
  
      alertRequiredDatas() {
        return this.$ionic.alertController
          .create({
            cssClass: "my-custom-class",
            header: "",
            message: this.$t("frontend.home.errorRequired"),
            buttons: [
              {
                text: this.$t("frontend.home.acept"),
                handler: () => { },
              },
            ],
          })
          .then((a) => a.present());
      },
  
      resetFields(){
        this.password = "";
        this.showPasswordInput = false;
      },
  
      resetPassword() {
        if (this.password === "")
          return this.alertRequiredDatas();
  
        const item = {
          Password: this.password,
          _id: this.id,
        };
        this.spinner = true;
        Api.customerResetPassword(item)
        .then((response) => {
          this.spinner = false;
          if (response.status === 200) {
           
            // Enviar la contraseña por correo después de guardarla
            this.sendPassword();
            //return this.$ionic.modalController.dismiss();
          }
        })
        .catch((e) => {
          this.spinner = false;
          return this.$ionic.alertController
            .create({
              cssClass: "my-custom-class",
              header: "",
              message: e,
              buttons: [
                {
                  text: this.$t("frontend.home.acept"),
                  handler: () => { },
                },
              ],
            })
            .then((a) => a.present());
        });
      },

      isEmail: (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },

      sendPassword() {

        if (this.password === "") {
          return this.alertRequiredDatas();
        }

        SendPassword.showSendPasswordPopup(this.emailAddress, (isCancelled, result) => {
          if (!isCancelled && result.email) {
            const emailContent = `
              <p>Hello ${this.name},</p>
              <p>Your new password is: <strong>${this.password}</strong></p>
              <p>Please change it after your first login.</p>
            `;
  
            const items = {
              email: result.email,
              subject: "Your New Password",
              body: emailContent,
              scheduledAt: new Date().getTime() + 5000
            };
  
            Api.scheduleEmailNotification(items)
            .then(response => {
              console.log(response);
              if (response.request.status === 201) {
                Swal.fire({
                  title: this.$t('backoffice.menu.alertEmailSend'),
                  icon: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                });
                Swal.fire({
                  title: "Email Sent",
                  text: `The password has been sent to ${result.email}`,
                  icon: 'success',
                  backdrop: false,
                }).then(() => {
                  this.resetFields();
                });
              }
            })
            .catch(e => {
              Swal.fire({
                title: "Error Sending Email",
                text: e.message,
                icon: 'error',
                backdrop: false,
              });
            });
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .input-style {
    width: 100%;
  }
  </style>
  