<template>
  <div>
    <ion-backdrop v-if="isBackdrop"></ion-backdrop>

    <modal name="create-address" width="90%" height="80%">
      <AddressComponent :isDraft="true" :isModal="true" :userId="id" :customerInformation="{name: name, phone: phone, email: emailAddress}" :addressType="selectedAddressType"
        @address="addressAdded">
      </AddressComponent>
    </modal>

    <ion-header v-if="!noShow || !isCustomerModal || !fromInvoice">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/controlPanel" @click="$router.push({ name: 'Customer' })"></ion-back-button>
        </ion-buttons>
        <ion-label style="padding: 20px 100px;">
          <h1>{{ title }}</h1>
        </ion-label>
      </ion-toolbar>
    </ion-header>
    <br />

    <!-- <ion-card> -->
    <ion-loading v-if="spinner" cssClass="my-custom-class"
      :message="$t('frontend.tooltips.loadRestaurant')"></ion-loading>

    <ion-grid>
      <ion-row v-if="!noShow">

        <ion-col size="12" size-md="6">

          <ion-item style="--border-style: none;">
            <ion-label position="floating"><span style="color: red">*</span>{{ $t('backoffice.form.fields.name')
              }}</ion-label>
            <ion-input class="input-style" type="text" name="name" @input="name = $event.target.value"
              v-bind:value="name">
            </ion-input>
          </ion-item>

          <ion-item style="--border-style: none;">
            <country-flag :country="country.country" />
            <ion-select class="required" interface="alert" @ionChange="
              (country = JSON.parse($event.target.value)), (phone = '')
              " style="padding: 0" :selected-text="country.code">
              <ion-select-option v-for="con in countries" :key="`${con.country}+${con.code}`" :text="con.name"
                :value="JSON.stringify(con)">{{ con.name }} ({{ con.code }})
              </ion-select-option>
            </ion-select>
            <ion-input class="input-style" type="tel" name="name" :placeholder="$t('frontend.orderType.phone')"
              @input="phone = $event.target.value" id="phone-validator" v-bind:value="phone"
              @change="phone = validatePhone($event.target.value)">
            </ion-input>

          </ion-item>
              <ion-item>
                <ion-label position="floating">{{ $t('backoffice.form.titles.customerType') }}</ion-label>
                <ion-select @ionChange="handleCustomerTypeChange" v-bind:value="customerTypeSelect">
                  <ion-select-option v-for="customerType in customerTypeOption" :key="customerType.value" :value="customerType.value">{{customerType.label}}</ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item>
                <ion-label position="floating">{{ $t('backoffice.form.titles.customerStatus') }}</ion-label>
                <ion-select @ionChange="handleCustomerStatusChange" v-bind:value="customerStatusSelect">
                  <ion-select-option v-for="customerStatus in customerStatusOption" :key="customerStatus.value" :value="customerStatus.value">{{customerStatus.label}}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-label position="floating">{{ $t('backoffice.form.titles.gender') }}</ion-label>
                <ion-select 
                    class="required" 
                    interface="alert" 
                    @ionChange="(gender = JSON.parse($event.target.value))" 
                    style="padding: 0" 
                    :selected-text="gender?.label || 'Select Gender'">
                    <ion-select-option 
                      v-for="gender in genderOptions" 
                      :key="`${gender?.label}+${gender.value}`" 
                      :value="JSON.stringify(gender)">
                      {{ gender?.label }}
                    </ion-select-option>
                  </ion-select>
              </ion-item>
        </ion-col>

        <ion-col size="12" size-md="6">


          <ion-item style="--border-style: none;">
            <ion-label position="floating"><span style="color: red">*</span>{{ $t('backoffice.form.fields.email')
              }}</ion-label>
            <ion-input class="input-style" type="email" name="emailAddress" @input="emailAddress = $event.target.value"
              v-bind:value="emailAddress">
            </ion-input>
          </ion-item>

          <!-- <p style="text-align: left;">{{ $t('backoffice.form.fields.marketingConsent') }}</p> -->
          <p style="text-align: left;">{{ $t('backoffice.form.titles.preferredContact') }}</p>
          <ion-button @click="toggleAllPreferred" size="small" fill="clear">
            {{ allEnabled ?  $t('backoffice.form.titles.disableAll')  : $t('backoffice.form.titles.enableAll') }}
          </ion-button>

          <ion-row>
            <ion-col size="12" size-md="4">
              <ion-item>
                <ion-label>{{ $t('backoffice.form.fields.email') }}</ion-label>
                <ion-toggle slot="end" name="mcemail" @ionChange="mcemail = $event.target.checked" :checked="mcemail">
                </ion-toggle>
              </ion-item>
            </ion-col>
            <ion-col size="12" size-md="4">
              <ion-item>
                <ion-label>{{ $t('backoffice.form.fields.phone') }}</ion-label>
                <ion-toggle slot="end" name="mcphone" @ionChange="mcphone = $event.target.checked"
                  v-bind:checked="mcphone">
                </ion-toggle>
              </ion-item>
            </ion-col>
            <ion-col size="12" size-md="4">
              <ion-item>
                <ion-label>SMS</ion-label>
                <ion-toggle slot="end" name="mcsms" @ionChange="mcsms = $event.target.checked" v-bind:checked="mcsms">
                </ion-toggle>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item>
                <ion-label position="floating">Notes</ion-label>
                <ion-textarea class="input-style" @input="notes = $event.target.value" v-bind:value="notes"></ion-textarea>
              </ion-item>
        </ion-col>
      </ion-row>

      <ion-row v-if="!noShow">
        <ion-col>
          <ion-card>
            <ion-card-header>
              <ion-title>Company Details</ion-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item>
                  <ion-label position="floating">{{ $t('backoffice.form.fields.companyTitle') }}</ion-label>
                  <ion-input class="input-style" @input="company.jobTitle = $event.target.value" v-bind:value="company.jobTitle" type="text"></ion-input>
                </ion-item>
                <ion-item style="--border-style: none;">
                  <ion-label position="floating"><span style="color: red"></span>{{ $t('backoffice.form.fields.companyName')
                    }}<ion-note v-if="errorCompanyName != null" color="danger"> {{ errorCompanyName
                      }}</ion-note></ion-label>
                  <ion-input class="input-style" type="text" name="company.name"
                    @input="company.name = $event.target.value" v-bind:value="company.name">
                  </ion-input>
                </ion-item>

                <ion-item style="--border-style: none;">
                  <country-flag :country="companyCountry.country" />
                  <ion-select class="required" interface="alert" @ionChange="
                    (companyCountry = JSON.parse($event.target.value)), (company.phone = '')
                    " style="padding: 0" :selected-text="companyCountry.code">
                    <ion-select-option v-for="con in countries" :key="`${con.country}+${con.code}`" :text="con.name"
                      :value="JSON.stringify(con)">{{ con.name }} ({{ con.code }})
                    </ion-select-option>
                  </ion-select>
                  <ion-input class="input-style" type="tel" name="name" :placeholder="$t('backoffice.form.fields.companyPhone')"
                    @input="company.phone = $event.target.value" id="phone-validator" v-bind:value="company.phone"
                    @change="company.phone = validateCompanyPhone($event.target.value)">
                  </ion-input>

                </ion-item>

                <ion-item style="--border-style: none;">
                  <ion-label position="floating"><span style="color: red"></span>{{ $t('backoffice.form.fields.companyEmail')
                    }}<ion-note v-if="errorCompanyEmail != null" color="danger"> {{ errorCompanyEmail
                      }}</ion-note></ion-label>
                  <ion-input class="input-style" type="email" name="company.email"
                    @input="company.email = $event.target.value" v-bind:value="company.email">
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">{{ $t('backoffice.form.fields.companyWebSite') }}</ion-label>
                  <ion-input class="input-style" @input="company.companyWebsite = $event.target.value" v-bind:value="company.companyWebsite" type="url"></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">{{ $t('backoffice.form.fields.companyIndustry') }}</ion-label>
                  <ion-select @ionChange="handleIndustryChange" v-bind:value="company?.industrySelect">
                    <ion-select-option v-for="industry in company.industryOption" :key="industry.value" :value="industry.value">{{industry.label}}</ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item v-if="company.industrySelect == 'Other'">
                  <ion-input class="input-style" @input="company.otherIndustry = $event.target.value" :value="company.otherIndustry"></ion-input>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <!-- <ion-row v-if="id == null"> -->
      <ion-row v-if="!noShow">
        <ion-col>
          <ion-card>
            <ion-card-header style="text-align: left">
            </ion-card-header>
            <ion-card-content>
              <div v-if="billingAddress != null">
                <p class="address-display"><span>Billing Address:</span> {{ getDisplayAddress(billingAddress)
                  }}
                </p>
              </div>
              <ion-button @click="selectedAddressType = 'billing'; $modal.show('create-address')" size="small" fill="clear">
                <span style="text-transform: capitalize;">{{ billingAddress != null ? 'Update' : 'Add' }} Billing Address</span>
              </ion-button>
              <!-- <ion-button @click="selectedAddressType = 'billing'; $modal.show('create-address')">{{
      billingAddress
        !=
        null ? 'Update' : 'Add' }} Billing
                Address</ion-button> -->
            </ion-card-content>
          </ion-card>
        </ion-col>

        <ion-col>
          <ion-card>
            <ion-card-header style="text-align: left">
            </ion-card-header>
            <ion-card-content>
              <div v-if="shippingAddress != null">
                <p class="address-display"><span>Shipping Address:</span> {{ getDisplayAddress(shippingAddress)
                  }}
                </p>
              </div>
              <ion-button @click="selectedAddressType = 'shipping'; $modal.show('create-address')" size="small" fill="clear">
                <span style="text-transform: capitalize;">{{ shippingAddress != null ? 'Update' : 'Add' }} Shipping Address</span>
              </ion-button>
              <!-- <ion-button @click="selectedAddressType = 'shipping'; $modal.show('create-address')">{{
      shippingAddress
        !=
        null ? 'Update' : 'Add' }} Shipping
                Address</ion-button> -->
            </ion-card-content>
          </ion-card>
        </ion-col>

      </ion-row>

      <ion-grid v-if="!noShow">
        <IdentificationComponent @update-identification="updateIdentification" :identification="customerData"></IdentificationComponent>
      </ion-grid>

      <ion-grid v-if="!noShow">
        <SocialNetworkComponent :modelValue="socialMedia" @update:modelValue="socialMedia = $event"></SocialNetworkComponent>
      </ion-grid>

      <ion-grid v-if="showInteractionDetails || !showInteractionDetails">
        <ion-row>
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-title>Interaction Details</ion-title>
              </ion-card-header>
              <ion-card-content>
                <ion-item>
                  <ion-label position="floating">Last Contacted Date</ion-label>
                  <ion-datetime @ionChange="lastContacted = $event.target.value" v-bind:value="lastContacted" display-format="MM/DD/YYYY"></ion-datetime>
                  <ion-textarea style="margin-top: 0;" class="input-style" @input="notesLastContacted = $event.target.value" :value="notesLastContacted" placeholder="Note of Last Contacted Date"></ion-textarea>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Next Follow-Up Date</ion-label>
                  <ion-datetime @ionChange="nextFollowUp = $event.target.value" v-bind:value="nextFollowUp" display-format="MM/DD/YYYY"></ion-datetime>
                  <ion-textarea style="margin-top: 0;" class="input-style" @input="notesNextFollowUp = $event.target.value" :value="notesNextFollowUp" placeholder="Note of Next Follow-Up Date"></ion-textarea>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Preferred Contact Time (Range)</ion-label>
                  <ion-row>
                    <ion-col>
                      <ion-input @input="preferredContactTime = $event.target.value" v-bind:value="preferredContactTime" type="time"></ion-input>
                    </ion-col>
                    <ion-col>
                      <ion-input @input="preferredContactToTime = $event.target.value" v-bind:value="preferredContactToTime" type="time"></ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-textarea style="margin-top: 0;" class="input-style" @input="notesContactToTime = $event.target.value" v-bind:value="notesContactToTime" placeholder="Note of Preferred Contact Time"></ion-textarea>
                </ion-item>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col>
            <!-- Tags -->
            <ion-card>
              <ion-card-content>
                <ion-item
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <ion-label position="stacked">Add Tag</ion-label>
                  <ion-input class="input-style" @input="textTag = $event.target.value" v-bind:value="textTag"></ion-input>
                  <ion-label>
                    <ion-button
                      fill="clear"
                      @click="addTags()"
                      :disabled="textTag != '' ? false : true"
                    >
                      <ion-icon slot="icon-only" icon="add"></ion-icon>
                    </ion-button>
                  </ion-label>
                </ion-item>
                <ion-list
                  class="content-list"
                  v-if="tags?.length > 0"
                  style="margin: 0 15px"
                >
                  <ion-item
                    v-for="(tag, index) in tags"
                    v-bind:key="index"
                  >
                    <ion-label>
                      <h2>
                        <span style="padding: 0 10px">
                          {{ tag.name }}
                        </span>
                      </h2>
                    </ion-label>

                    <ion-button
                      fill="clear"
                      shape="round"
                      color="secondary"
                      class="list-group-btn"
                      side="end"
                      @click.stop="removeTag(tag)"
                    >
                      <ion-icon
                        slot="icon-only"
                        icon="trash"
                        class="more-grid"
                      ></ion-icon>
                    </ion-button>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-row v-if="!noShow">
        <ion-col>
          <ion-item style="--border-style: none;">
            <ion-label position="floating"><span style="color: red"></span>Referrer</ion-label>
            <ion-input class="input-style" type="text" name="referrer" @input="referrer = $event.target.value"
              v-bind:value="referrer">
            </ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button expand="full" shape="round" color="primary" :disabled="!isValidForm()" @click="saveCustomer()">{{
      $t('backoffice.form.buttons.save') }}</ion-button>

  </div>
</template>

<script>

import { Api } from '../api/api.js';
import Swal from 'sweetalert2'
import { findNumbers } from "libphonenumber-js";
import LibCodes from "zipcodes";
import { countries } from "../../backoffice/utils/countries.js";
import { genderOptions  } from "../../backoffice/utils/GenderOptions.js";
import CountryFlag from "vue-country-flag";
import { NotificationUtil } from '../utils/email.js';
import AddressComponent from "../../frontend/components/AddressComponent.vue";
import IdentificationComponent from "../../frontend/components/IdentificationComponent.vue";
import SocialNetworkComponent from "../../frontend/components/SocialNetworkComponent.vue";
import { DateTime } from 'luxon';

export default {

  name: 'customerForm',

  data() {
    return {
      errorPhone: null,
      pacInput: null,
      modelName: 'Customer',
      abreviature: '',
      /****** Form Data ******/
      id: null,
      customerdData:{},
      name: '',
      emailAddress: '',
      phone: '',
      mcemail: false,
      mcphone: false,
      mcsms: false,
      customerTypeSelect: '',
      customerTypeOption: [
        { label: 'Individual', value: 'Individual' },
        { label: 'Business', value: 'Business' },
      ],
      customerStatusSelect: '',
      customerStatusOption: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Suspended', value: 'Suspended' },
        { label: 'Lead', value: 'Lead' },
        { label: 'Prospect', value: 'Prospect' },
      ],

      notes: '',

      isBackdrop: false,

      spinner: false,
      countries: countries,
      country: {
        country: "US",
        code: "+1",
        name: "United States",
      },
      countryCode: "+1",
      company: {
        jobTitle: '',
        name: '',
        phone: '',
        email: '',
        companyWebsite: '',
        industrySelect: 'Technology',
        otherIndustry:'',
        industryOption: [
          { label: this.$t('backoffice.titles.technology'), value: 'Technology' },
          { label: this.$t('backoffice.titles.retail'), value: 'Retail' },
          { label: this.$t('backoffice.titles.healthcare'), value: 'Healthcare' },
          { label: this.$t('backoffice.titles.finance'), value: 'Finance' },
          { label: this.$t('backoffice.titles.retailGeneralMerchandise'), value: 'Retail (General Merchandise)' },
          { label: this.$t('backoffice.titles.healthcareServices'), value: 'Healthcare Services' },
          { label: this.$t('backoffice.titles.foodAndBeverage'), value: 'Food and Beverage (Restaurants, Cafes, Bars)' },
          { label: this.$t('backoffice.titles.construction'), value: 'Construction' },
          { label: this.$t('backoffice.titles.eCommerce'), value: 'E-Commerce' },
          { label: this.$t('backoffice.titles.informationTechnology'), value: 'Information Technology (IT Services and Software Development)' },
          { label: this.$t('backoffice.titles.realEstate'), value: 'Real Estate (Residential and Commercial)' },
          { label: this.$t('backoffice.titles.transportationLogistics'), value: 'Transportation and Logistics' },
          { label: this.$t('backoffice.titles.professionalServices'), value: 'Professional Services (Consulting, Legal, Accounting)' },
          { label: this.$t('backoffice.titles.manufacturing'), value: 'Manufacturing (General and Specialized)' },
          { label: this.$t('backoffice.titles.educationTraining'), value: 'Education and Training Services' },
          { label: this.$t('backoffice.titles.entertainmentMedia'), value: 'Entertainment and Media' },
          { label: this.$t('backoffice.titles.financialServices'), value: 'Financial Services (Banks, Investment Firms)' },
          { label: this.$t('backoffice.titles.personalCareServices'), value: 'Personal Care Services (Salons, Spas)' },
          { label: this.$t('backoffice.titles.hospitality'), value: 'Hospitality (Hotels, Travel Agencies)' },
          { label: this.$t('backoffice.titles.insuranceAgencies'), value: 'Insurance Agencies' },
          { label: this.$t('backoffice.titles.homeImprovementRepair'), value: 'Home Improvement and Repair' },
          { label: this.$t('backoffice.titles.automotiveServices'), value: 'Automotive Services (Repair, Dealerships, Body Shops)' },
          { label: this.$t('backoffice.titles.telecommunications'), value: 'Telecommunications' },
          { label: this.$t('backoffice.titles.nonprofitSocialServices'), value: 'Nonprofit and Social Services' },
          { label: this.$t('backoffice.titles.agricultureFarming'), value: 'Agriculture and Farming' },
          { label: this.$t('backoffice.titles.pharmaceuticalsBiotechnology'), value: 'Pharmaceuticals and Biotechnology' },
          { label: this.$t('backoffice.titles.energy'), value: 'Energy (Renewable and Non-Renewable)' },
          { label: this.$t('backoffice.titles.sportsRecreation'), value: 'Sports and Recreation' },
          { label: this.$t('backoffice.titles.fitnessWellness'), value: 'Fitness and Wellness' },
          { label: this.$t('backoffice.titles.marketingAdvertising'), value: 'Marketing and Advertising' },
          { label: this.$t('backoffice.titles.eventPlanningServices'), value: 'Event Planning and Services' },
          { label: this.$t('backoffice.titles.cleaningServices'), value: 'Cleaning Services (Residential and Commercial)' },
          { label: this.$t('backoffice.titles.fashionApparel'), value: 'Fashion and Apparel' },
          { label: this.$t('backoffice.titles.foodProductionProcessing'), value: 'Food Production and Processing' },
          { label: this.$t('backoffice.titles.petServices'), value: 'Pet Services (Grooming, Boarding, Veterinary)' },
          { label: this.$t('backoffice.titles.artsCrafts'), value: 'Arts and Crafts (Creative Services)' },
          { label: this.$t('backoffice.titles.aerospaceDefense'), value: 'Aerospace and Defense' },
          { label: this.$t('backoffice.titles.environmentalServices'), value: 'Environmental Services' },
          { label: this.$t('backoffice.titles.technologyHardwareElectronics'), value: 'Technology Hardware and Electronics' },
          { label: this.$t('backoffice.titles.childcareServices'), value: 'Childcare Services' },
          { label: this.$t('backoffice.titles.securityServices'), value: 'Security Services (Cybersecurity, Physical Security)' },
          { label: this.$t('backoffice.titles.publishing'), value: 'Publishing (Books, Magazines, Digital Media)' },
          { label: this.$t('backoffice.titles.transportationEquipment'), value: 'Transportation Equipment Manufacturing' },
          { label: this.$t('backoffice.titles.architectureDesign'), value: 'Architecture and Design' },
          { label: this.$t('backoffice.titles.accountingTax'), value: 'Accounting and Tax Preparation' },
          { label: this.$t('backoffice.titles.landscapingGardening'), value: 'Landscaping and Gardening' },
          { label: this.$t('backoffice.titles.beverageProduction'), value: 'Beverage Production (Craft Beer, Wine, Spirits)' },
          { label: this.$t('backoffice.titles.medicalDevicesSupplies'), value: 'Medical Devices and Supplies' },
          { label: this.$t('backoffice.titles.warehousingStorage'), value: 'Warehousing and Storage' },
          { label: this.$t('backoffice.titles.freelancing'), value: 'Freelancing (Writing, Graphic Design, Programming)' },
          { label: this.$t('backoffice.titles.tourismAdventure'), value: 'Tourism and Adventure Services' },
          { label: this.$t('backoffice.titles.utilitiesPublicServices'), value: 'Utilities and Public Services' },
          { label: this.$t('backoffice.titles.blockchainCryptocurrency'), value: 'Blockchain and Cryptocurrency' },
          { label: this.$t('backoffice.titles.specializedRetail'), value: 'Specialized Retail (Niche Products and Services)' },
          { label: this.$t('frontend.genderOptions.other'), value: 'Other' }
        ],
      },
      companyCountry: {
        country: "US",
        code: "+1",
        name: "United States",
      },
      errorCompanyEmail: null,
      errorCompanyPhone: null,
      errorCompanyName: null,
      referrer: '',
      selectedAddressType: 'billing',
      shippingAddress: null,
      billingAddress: null,
      lastContacted: DateTime.local().toFormat('yyyy-MM-dd'),
      nextFollowUp: DateTime.local().toFormat('yyyy-MM-dd'),
      preferredContactTime: "",
      notesLastContacted: '',
      notesNextFollowUp: '',
      preferredContactToTime: '',
      notesContactToTime: '',
      tags: [],
      interactions: [],
      addInteraction: false,
      addTag: false,
      textTag: "",
      genderOptions,
      gender: '',
      identificationData: {},
      customerInformation: {},
      customerData: {},
      socialMedia: {},
    }
  },
  components: {
    CountryFlag,
    AddressComponent,
    IdentificationComponent,
    SocialNetworkComponent
  },
  props: {
    fromInvoice: { type: Boolean, default: false },
    redirect: {
      type: Boolean,
      default: true
    },
    isModal: { type: Boolean, default: false },
    recivedEmail: {
      type: String,
      default: ''
    },
    recivedPhone: {
      type: String,
      default: ''
    },
    isCustomerModal: { 
      type: Boolean,
      default: false
    },
    showInteractionDetails: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String,
      default: ''
    },
    noShow:{
      type: Boolean,
      default: false
    }
  },
  created: function () {    
    if (this.recivedEmail) {
      this.emailAddress = this.recivedEmail; // Asignar el email recibido
    }
    if (this.recivedPhone) {
      this.phone = this.recivedPhone; // Asignar el número de teléfono recibido
    }
    this.init();
  },
  computed: {
    allEnabled() {
      return this.mcemail && this.mcphone && this.mcsms;
    },
    title() {
      return this.id ? this.$t('backoffice.form.titles.customerEditTitle') : this.$t('backoffice.form.titles.customerNewTitle');
    }
  },
  mounted() {
    this.initApp();
  },
  methods: {
    addTags() {
      if (
        this.textTag != "" &&
        !this.tags.find((i) => i.name == this.textTag)
      ) {
        this.tags.push({ name: this.textTag, selected: 1 });
        this.textTag = "";
      } else {
        this.ShowMessage(
          this.$t("backoffice.form.validate.validate"),
          "",
          this.$t("backoffice.form.validate.ingredient")
        );
      }
    },

    removeTag(index) {
      this.tags.splice(index, 1);
    },

    submitForm() {
     //console.log("Form Data:", this.socialMedia);
    },
    updateIdentification(data) {
      this.identificationData = data;
    },
    toggleAllPreferred() {
      const newValue = !this.allEnabled;
      this.mcemail = newValue;
      this.mcphone = newValue;
      this.mcsms = newValue;
    },
    handleIndustryChange(event) {
      this.company.industrySelect = event.target.value;
    },
    handleCustomerTypeChange(event){
      this.customerTypeSelect = event.target.value;
    },
    handleCustomerStatusChange(event){
      this.customerStatusSelect = event.target.value
    },
    getDisplayAddress(address) {
      const result = `${address.street1} ${address.street2} ${address.street3} ${address.city}, ${address.state}, ${address.county}, ${address.country} ${address.zip}`;
      return result;
    },

    addressAdded(address) {
      //address
      //console.log('addressAdded', address);
      if (this.selectedAddressType == 'billing') {
        this.billingAddress = address;
      } else {
        this.shippingAddress = address;
      }
      this.$modal.hide('create-address');
    },

    handleFlagClick() {
      this.$refs.countrySelector.click();
    },

    /**
     * A modular validation function
     * TODO: move it to utils
     * @param {*} phoneNumber 
     */
    isPhoneValid(countryCode, phoneNumber) {
      if (!phoneNumber) {

        return false;
      }
      try {
        var nn = countryCode + phoneNumber;
        const n = findNumbers(nn, { v2: true });
        if (n.length < 1) {

          return false;
        } else {
          //console.log('isPhoneValid', n[0].number.number);
          return true;
        }
      } catch (err) {
        return false;
      }
    },

    validatePhone(phone) {
      if (!phone) {

        this.errorPhone = 'Please enter a valid phone';
        return "";
      }
      try {
        var nn = this.country.code + phone;
        const n = findNumbers(nn, { v2: true });
        if (n.length < 1) {
          document
            .querySelector("#phone-validator")
            .style("border-bottom", "2px solid red");
          this.errorPhone = 'Please enter a valid phone';

          return "";
        } else {
          this.phone = n[0].number.number;
          this.errorPhone = null;
          return n[0].number.nationalNumber;
        }
      } catch (err) {

        this.errorPhone = 'Please enter a valid phone';
        return false;
      }
    },

    validateCompanyPhone(phone) {
      if (!phone) {

        this.errorCompanyPhone = 'Please enter a valid phone';
        return "";
      }
      try {
        var nn = this.companyCountry.code + phone;
        const n = findNumbers(nn, { v2: true });
        if (n.length < 1) {
          document
            .querySelector("#phone-validator")
            .style("border-bottom", "2px solid red");
          this.errorCompanyPhone = 'Please enter a valid phone';

          return "";
        } else {
          this.company.phone = n[0].number.number;
          this.errorCompanyPhone = null;
          return n[0].number.nationalNumber;
        }
      } catch (err) {

        this.errorCompanyPhone = 'Please enter a valid phone';
        return false;
      }
    },

    async initApp() {
      // await new Promise(resolve => setTimeout(resolve, 500));
      // const input = document.querySelector('#pacInput input');
      // const autocomplete = new google.maps.places.Autocomplete(input);
      //
      // autocomplete.setTypes(['(regions)']); // Restrict autocomplete to countries
      //
      // autocomplete.addListener("place_changed", () => {
      //   const place = autocomplete.getPlace();
      //   if (place && place.address_components) {
      //     // Loop through address components to find the country
      //     for (const addressComponent of place.address_components) {
      //       if (addressComponent.types.includes("country")) {
      //         this.country = addressComponent.long_name;
      //         this.abreviature = addressComponent.short_name;
      //         //console.log("Country:", this.country);
      //         //console.log("Abbreviation:", this.abreviature);
      //         break; // We found the country, so exit the loop
      //       }
      //     }
      //   }
      // });
    },

    init() {
      this.id = this.$route.params.customerId || this.customerId;

      const data = this.$store.state.backConfig.allCustomer.find(c => c._id === this.id)
      //console.log(data)
      if (data) {
        this.name = data.Name;
        //this.country = data?.country;
        this.phone = data.Phone;
        this.emailAddress = data.EmailAddress;
        this.customerTypeSelect = data?.customerTypeSelect;
        this.customerStatusSelect = data?.customerStatusSelect;
        this.notes = data?.notes;
        // this.notesLastContacted = data?.notesLastContacted;
        // this.notesNextFollowUp = data?.notesNextFollowUp;
        // this.lastContacted = data?.lastContacted ? DateTime.fromISO(data?.lastContacted).toFormat('yyyy-MM-dd') : null;
        // this.nextFollowUp = data?.nextFollowUp ? DateTime.fromISO(data?.nextFollowUp).toFormat('yyyy-MM-dd') : null;
        // this.preferredContactTime = data?.preferredContactTime;
        // this.preferredContactToTime = data?.preferredContactToTime;
        // this.notesContactToTime = data?.notesContactToTime;
        this.gender = data?.gender
        this.customerData = data?.identificationData?? {};
        this.socialMedia = data?.socialMedia
        this.tags = data.tags;
        if (this.tags?.length > 0) this.addTag = true;
        this.interactions = data?.interactions?? [];
        if (this.interactions?.length > 0) this.addInteraction = true;

        if (data.MarketingConsent) {
          this.mcemail = data.MarketingConsent.Email;
          this.mcphone = data.MarketingConsent.Phone;
          this.mcsms = data?.MarketingConsent.sms;
        }

        if (data.Company) {
          this.company = data?.Company;
        }

        this.referrer = data?.Referrer;
      }

      if (this.$route.params.customerName)
        this.name = this.$route.params.customerName;
      if (this.$route.params.NameCountry)
        this.country.country = this.$route.params.NameCountry;
      if (this.$route.params.Code)
        this.country.code = this.$route.params.Code;
      if (this.$route.params.Phone)
        this.phone = this.$route.params.Phone;
      if (this.$route.params.EmailAddress)
        this.emailAddress = this.$route.params.EmailAddress;
    },

    ifErrorOccured(action) {
      return this.$ionic.alertController.create({
        title: this.$t('backoffice.list.messages.connectionError'),
        message: this.$t('backoffice.list.messages.connectionErrorMessage'),
        buttons: [
          {
            text: this.$t('backoffice.list.messages.buttons.goToList'),
            handler: () => {
              this.$router.push({
                name: 'Customer',
              });
            }
          },
          {
            text: this.$t('backoffice.list.messages.buttons.retry'),
            handler: () => {
              action();
            }
          }
        ]
      })
        .then(a => a.present());
    },

    isValidForm() {
      if (this.name == "") return false
      if (this.emailAddress == "") return false
      if (this.coutry == "") return false
      if (this.phone == "") return false
      if (this.emailAddress != "") {
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (emailRegex.test(this.emailAddress) == false)
          return false
      }
      return true
    },

    ShowMessage(type, message, topic = '') {
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: type,
          subHeader: topic,
          message: message,
          buttons: [this.$t('backoffice.form.messages.buttons.ok')],
        })
        .then(a => a.present())
    },

    showToastMessage(message, tColor) {
      return this.$ionic.toastController.create({
        color: tColor,
        position: 'top',
        duration: 3000,
        message: message,
        showCloseButton: false
      }).then(a => a.present())
    },
    //Create or edit a new category

    saveCustomer: function () {
      console.log(this.identificationData.file)

      if (this.isValidForm()) {
        this.isBackdrop = true;
        this.interactions.push({ 
          lastContacted: this.lastContacted, 
          notesLastContacted: this.notesLastContacted, 
          nextFollowUp: this.nextFollowUp,
          notesNextFollowUp: this.notesNextFollowUp,
          preferredContactTime: this.preferredContactTime,
          preferredContactToTime: this.preferredContactToTime,
          notesContactToTime: this.notesContactToTime,
          selected: 1 
        });
        let item = {
          "Name": this.name,
          "NameCountry": this.country.country,
          "Code": this.country.code,
          "Phone": this.phone,
          "EmailAddress": this.emailAddress,
          "customerTypeSelect": this.customerTypeSelect,
          "customerStatusSelect": this.customerStatusSelect,
          "notes": this.notes,
          // "notesLastContacted": this.notesLastContacted,
          // "notesNextFollowUp": this.notesNextFollowUp,
          // "lastContacted": DateTime.fromISO(this.lastContacted),
          // "nextFollowUp": DateTime.fromISO(this.nextFollowUp),
          // "preferredContactTime": this.preferredContactTime,
          // "preferredContactToTime": this.preferredContactToTime,
          // "notesContactToTime": this.notesContactToTime,
          "gender": this.gender,
          "identificationData": this.identificationData,
          "socialMedia": this.socialMedia,
          MarketingConsent: {
            Email: this.mcemail,
            Phone: this.mcphone,
            sms: this.mcsms
          },
          Company: {
            ...this.company,
            country: this.companyCountry
          },
          Referrer: this.referrer
        };
        if (this.tags?.length > 0) {
          item["tags"] = this.tags;
        }
        if(this.interactions?.length > 0){
          item["interactions"] = this.interactions
        }
        if (this.identificationData.file != null){
          item.identificationData["imageUrl"] = this.identificationData.file;
          item.identificationData["imageName"] = this.identificationData.fileName;
          delete item.identificationData.file;
        }

        //delete item.Company.industryOption;

        //if at least one is field up
        if (item.Company.email.length > 0 || item.Company.phone.length > 0 || item.Company.name.length > 0) {

          //if the company is not empty, then validate it
          if (item.Company.email != '' && !NotificationUtil.isEmail(item?.Company?.email)) {
            //Swal.fire({ title: 'Please enter a valid company email addresss' });
            this.errorCompanyEmail = 'Please enter a valid company email address';
            return;
          } else if (item.Company.name && item.Company.name.length < 4) {
            //Swal.fire({ title: 'Please enter a valid company name' });
            this.errorCompanyName = 'Please enter a valid company name';
            return;
          } else if (item.Company.phone && !this.isPhoneValid(this.companyCountry.code, item.Company.phone)) {
            //Swal.fire({ title: 'Please enter a valid company phone number' });
            this.errorCompanyPhone = 'Please enter a valid company phone';
            return;
          }
        }



        //If I am editing
        if (this.id) {
          item['_id'] = this.id;
          this.spinner = true;
          Api.putIn(this.modelName, item)
            .then(response => {
              const index = this.$store.state.backConfig.allCustomer.findIndex(c => c._id === this.id);
              if (index !== -1) this.$store.state.backConfig.allCustomer[index] = item;
              Swal.fire({
                title: this.$t('backoffice.list.messages.messageEditSuccessCustomer'),
                icon: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
              });
              //this.showToastMessage(this.$t('backoffice.list.messages.messageEditSuccessCustomer'), "success");
              this.name = '';
              this.country = '';
              this.phone = '';
              this.emailAddress = '';
              this.customerTypeSelect = '';
              this.customerStatusSelect = '';
              this.notes = '';
              this.notesLastContacted = '',
              this.notesNextFollowUp = '',
              this.tags = [];
              this.interactions = [];
              this.mcemail = false;
              this.mcphone = false;
              this.mcsms = false;
              this.spinner = false;

              this.errorCompanyEmail = null;
              this.errorCompanyPhone = null;
              this.errorCompanyName = null;

              if (this.isModal || this.noShow) {
                this.$emit('dismiss-modal', response.data);
              } else {
                if (this.$route.params.invoice) {
                  //this.$router.push({ name: 'InvoiceData' });
                  this.$router.push({
                    name: 'InvoiceData',
                    params: { forInvoice: true },
                  });
                } else {
                  this.$router.push({ name: 'Customer' });
                }
              }
              return response;
            })
            .catch(e => {
              this.isBackdrop = false
              e;
              this.spinner = false;
              this.ifErrorOccured(this.saveCustomer)
            })
        }
        else {
          //Else, I am created a new category
          this.spinner = true

          //try and save
          if (this.billingAddress != null) {
            item.billingAddress = this.billingAddress;
          }


          if (this.shippingAddress != null) {
            item.shippingAddress = this.shippingAddress;
          }

          Api.customerSoftCreate(item)
            .then(response => {
              this.$store.state.backConfig.allCustomer.push(response.data)
              Swal.fire({
                title: this.$t('backoffice.list.messages.messageCreateSuccessCustomer'),
                icon: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
              });
              //this.showToastMessage(this.$t('backoffice.list.messages.messageCreateSuccessCustomer'), "success");
              this.name = '';
              this.country = '';
              this.phone = '';
              this.emailAddress = '';
              this.mcemail = false;
              this.mcphone = false;
              this.mcsms = false;
              this.spinner = false;
              this.errorCompanyEmail = null;
              this.errorCompanyPhone = null;
              this.errorCompanyName = null;

              //if presented as modal, just dismiss the modal
              if(this.isCustomerModal || this.noShow){
                this.$emit('close-modal', response.data);
                this.$emit('dismiss-modal', response.data);

              }else {
                // this.$router.push({
                //   name: 'Customer',
                // });
                if (this.$route.params.invoice) {
                  //this.$router.push({ name: 'InvoiceData' });
                  this.$router.push({
                    name: 'InvoiceData',
                    params: { forInvoice: true },
                  });
                } else {
                  this.$router.push({ name: 'Customer' });
                }
              }


              if (!this.redirect) {
                return response;
              }

              return response;
            })
            .catch(e => {
              this.isBackdrop = false
              e;
              this.spinner = false;
              this.ifErrorOccured(this.saveCustomer)
            })
        }
      }

    },
  },

}

</script>

<style>
@media only screen and (min-width : 1024px) {

  .screen {
    padding-left: 10%;
    padding-right: 10%;
    border: #edf1ee solid 1px;
    margin-right: 10%;
    margin-left: 10%;
    /* border-radius: 25px; */
  }

}

.center-vertically {
  margin-top: auto;
  margin-bottom: auto;
}

.address-display {
  span {
    font-weight: bold;
  }

  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>