import Swal from "sweetalert2";

export const SendPassword = {
  isEmail: (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },

  showSendPasswordPopup: async (toEmail, cb) => {
    const result = await Swal.fire({
      title: "Send Password via Email",
      html: `
      <label for="swal-input2">You are about to send the password to:</label>
      <input required style="width: 90%; margin-left: auto; margin-right: auto;" id="swal-input2" class="swal2-input" type="email" value="${toEmail}">
      <p><i>Note: You may change the email address in the field above.</i></p>
      `,
      focusConfirm: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const emailStr = document.getElementById("swal-input2").value;
        if (!SendPassword.isEmail(emailStr)) {
          Swal.showValidationMessage("Please enter a valid email");
          return null;
        }
        return emailStr;
      },
      showCancelButton: true,
      confirmButtonText: "Send Email",
      backdrop: false,
      didOpen: () => {
        // Aplicar estilos directamente al elemento del popup
        const popup = document.querySelector('.swal2-popup');
        if (popup) {
          popup.style.zIndex = '9999'; // O el valor que necesites
        }
      }
    });

    if (result.isConfirmed) {
      cb(false, { email: result.value });
    } else {
      cb(true, null);
    }
  },
};
