import Vue from "vue";
import VueRouter from "vue-router";
import store from "../../store/index";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  //  BACKOFFICE
  {
    path: "/bulkdelete",
    name: "BulkDelete",
    component: () =>
      import(
        /* webpackChunkName: "frontend/newRestaurant" */ "../components/BulkDelete.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/newbusiness",
    name: "NewBusiness",
    component: () =>
      import(
        /* webpackChunkName: "frontend/newRestaurant" */ "../../frontend/views/NewRestautant.vue"
      ),
    meta: { requiresAuth: false, isNewBusiness: true },
  },
  {
    path: "/category",
    name: "Category",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/category" */ "../views/Category.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/category-form/",
    name: "CategoryForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/category-form" */ "../views/CategoryForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/product/:type?",
    name: "Product",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/product" */ "../views/Product.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/product-form/",
    name: "ProductForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/product-form" */ "../views/ProductForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/cubicvolume/:type?",
    name: "cubicvolume",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/package-cubic" */ "../../frontend/components/CubicVolume.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/package-cubic-form/",
    name: "CubicVolumePricing",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/package-cubic-form" */ "../../frontend/components/CubicVolumePricing.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/productmanagement/:type?",
    name: "ProductManagement",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/productmanagement" */ "../views/ProductManagement.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/product-management-form/",
    name: "ProductManagementForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/product-management-form" */ "../views/ProductManagementForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/productByCategory/?",
    name: "ProductByCategory",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/productByCategory" */ "../views/ProductByCategory.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/variantGroup",
    name: "VariantGroup",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/variantGroup" */ "../views/VariantGroup.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/variantGroup-form/",
    name: "VariantGroupForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/variantGroup-form" */ "../views/VariantGroupForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/menu",
    name: "Menu",
    component: () =>
      import(/* webpackChunkName: "backoffice/menu" */ "../views/Menu.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/menu-form/",
    name: "MenuForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/menu-form" */ "../views/MenuForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer",
    name: "Customer",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/customer" */ "../views/Customer.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer-form/",
    name: "CustomerForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/customer-form" */ "../views/CustomerForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/table",
    name: "Table",
    component: () =>
      import(/* webpackChunkName: "backoffice/table" */ "../views/Table.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/table-form/",
    name: "TableForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/table-form" */ "../views/TableForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/order",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "backoffice/order" */ "../views/Order.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/quotes",
    name: "QuoteData",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/quotes" */ "../views/QuoteData.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/quotedetails",
    name: "QuoteDetails",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/quotes" */ "../views/QuoteDetails.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/quote",
    name: "Quote",
    component: () =>
      import(/* webpackChunkName: "backoffice/quote" */ "../views/Quote.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/quotedetail",
    name: "QuoteDetail",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/quotedetail" */ "../views/QuoteDetail.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/invoices",
    name: "InvoiceData",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/invoice" */ "../views/InvoiceData.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/transaction-data",
    name: "transactionData",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/transaction-data" */ "../views/TransactionData.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/bank-account-connection',
    name: 'bankAccountConnection',
    component: () =>
      import(
        /* webpackChunkName: "backoffice/bank-account-connection" */ "../../frontend/components/BankAccountConnection.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/customer-account-transactions',
    name: 'customerAccountTransactions',
    component: () =>
      import(
        /* webpackChunkName: "backoffice/customer-account-transactions" */ "../../frontend/components/CustomerAccountTransactions.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/invoicedetails",
    name: "InvoiceDetails",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/invoicedetails" */ "../views/InvoiceDetail.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/quotedetail",
    name: "QuoteDetailAccept",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/quoteDetailAccept" */ "../views/QuoteDetailAccept.vue"
      ),
    props: true,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: () =>
      import(/* webpackChunkName: "backoffice/invoice" */ "../views/Quote.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/invoicedetail",
    name: "InvoiceDetail",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/invoicedetail" */ "../views/QuoteDetail.vue"
      ),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/invoicedetail",
    name: "InvoiceDetailAccept",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/invoiceDetailAccept" */ "../views/QuoteDetailAccept.vue"
      ),
    options: { preserveQueryParams: true },
    props: true,
  },
  {
    path: "/asset-detail",
    name: "AssetPickupDetail",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/assetPickupDetail" */ "../views/CustomerPickupRequestAccept.vue"
      ),
    props: true,
  },
  {
    path: "/orderdetails/",
    name: "OrderDetails",
    component: () => import("../views/OrderDetails.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cateringOrderForm/",
    name: "OrderCateringForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/cateringOrderForm" */ "../views/OrderCateringForm.vue"
      ),
    meta: { requiresAuth: true },
  },

  {
    path: "/ticket/",
    name: "TicketForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/ticket" */ "../views/TicketForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/credit",
    name: "Credit",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/credit" */ "../views/CustomerCredit.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/credit/",
    name: "Credit-Form",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/credit-form" */ "../views/CustomerCreditForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/workSheet",
    name: "WorkSheet",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/workSheet" */ "../views/WorkSheet.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/workSheetForm/:workSheetId?",
    name: "WorkSheet-Form",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/workSheet-from" */ "../views/WorkSheetForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/preview-print",
    name: "preview-print",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/workSheet-from" */ "../views/PrintPreview.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/tax",
    name: "Tax",
    component: () =>
      import(/* webpackChunkName: "backoffice/tax" */ "../views/Tax.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tax-form/",
    name: "TaxForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/tax-from" */ "../views/TaxForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/shipping",
    name: "Shipping",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/shipping" */ "../views/Shipping.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/shipping-form/",
    name: "ShippingForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/shipping-from" */ "../views/ShippingForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/otherCharge",
    name: "OtherCharge",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/othercharges" */ "../views/OtherCharge.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/barcode",
    name: "BarCode",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/othercharges" */ "../views/BarcodeGenerator.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/otherCharge-form/",
    name: "OtherChargeForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/othercharges-from" */ "../views/OtherChargeForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/reservationbackoffice",
    name: "ReservationBackoffice",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/reservtion" */ "../views/Reservation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/driverDeliveries",
    name: "DriverDeliveries",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/reservtion" */ "../views/DriverDeliveries.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/driverOrderDetails",
    name: "DriverOrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/reservtion" */ "../views/DriverOrderDetails"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/customerPickupRequests",
    name: "CustomerPickupRequests",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/reservtion" */ "../views/CustomerPickupRequests.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Subscription" */ "../views/Subscription.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/vt",
    name: "VirtualTerminal",
    component: () => import("../../frontend/components/VirtualTerminal.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reservationDetails/:id",
    name: "ReservationDetails",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/reservationDetails" */ "../views/ReservationDetails.vue"
      ),
  },
  {
    path: "/user",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "backoffice/user" */ "../views/User.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/driver",
    name: "Driver",
    component: () =>
      import(/* webpackChunkName: "backoffice/driver" */ "../views/User.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user-form/",
    name: "UserForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/user-from" */ "../views/UserForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/subscriber",
    name: "Subscriber",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/subscriber" */ "../views/Subscriber.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/subscriber-form/",
    name: "SubscriberForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/subscriber-from" */ "../views/SubscriberForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/role",
    name: "Role",
    component: () =>
      import(/* webpackChunkName: "backoffice/role" */ "../views/Role.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/role-form/",
    name: "RoleForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/role-from" */ "../views/RoleForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "backoffice/login" */ "../views/Login.vue"),
  },
  {
    path: "/controlPanel/",
    name: "ControlPanel",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/controlPanel" */ "../views/ControlPanel.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/assistance",
    name: "Assistance",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/assistance" */ "../views/ClockInClockOut.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/houseaccount",
    name: "HouseAccount",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/houseaccount" */ "../views/HouseAccount.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/payment",
    name: "Payment",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/payment" */ "../views/Payment.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/sales-by-customers",
    name: "SalesByCustomers",
    component: () => import("../views/SalesByCustomers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sales-by-products",
    name: "SalesByProducts",
    component: () => import("../views/SalesByProducts.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/dynamic-report",
    name: "DynamicReport",
    component: () => import("../views/DynamicReport.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/paymentdetail",
    name: "PaymentDetail",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/paymentDetail" */ "../views/PaymentDetail.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/support/",
    name: "Support",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/support" */ "../views/SupportPanel.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/basicDataSettingsForm/",
    name: "BasicSettingForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/BasicSettingForm" */ "../views/BasicDataSettingsForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/paymentSettingsForm/",
    name: "PaymentSettingsForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/PaymentSettingsForm" */ "../views/PaymentSettingsForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/KeySettingsForm/",
    name: "KeySettingForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/KeySettingForm" */ "../views/KeySettingForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/funDataSettingsForm/",
    name: "FunSettingForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/FunSettingForm" */ "../views/FunDataSettingForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/termsAndPrivacy/",
    name: "TermsAndPrivacy",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/TermsAndPrivacy" */ "../views/TermsAndPrivacy.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/ColourDataSettingsForm/",
    name: "ColourSettingForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/ColourSettingForm" */ "../views/ColourDataSettingForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/rich-text/",
    name: "RichText",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/RichText" */ "../views/RichText.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/aboutDataSettings",
    name: "About",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/aboutDataSettings" */ "../views/AboutDataSetting.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/pickup-request-form",
    name: "PickupRequestForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/PickupRequestForm" */ "../views/PickupRequestForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/aboutDataSettingsForm/",
    name: "AboutForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/aboutDataSettingsForm" */ "../views/AboutDataSettingsForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/event",
    name: "Event",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Events" */ "../views/Events.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/event-form",
    name: "EventForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/EventForm" */ "../views/EventsForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/google-calendar",
    name: "GoogleCalendar",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/GoogleCalendarComponent" */ "../views/GoogleCalendarComponent.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/auth/google-auth-success",
    name: "GoogleAuthSuccess",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/GoogleAuthSuccess" */ "../views/GoogleAuthSuccess.vue"
      ),
    meta: { requiresAuth: false, successgoogle: true },
  },
  {
    path: "/digitalDisplayForm/",
    name: "DigitalDisplayForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/digitalDisplayForm" */ "../views/DigitalDisplayForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/occupation",
    name: "Occupation",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Occupation" */ "../views/Occupation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/occupation-form/",
    name: "OccupationForm",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/OccupationForm" */ "../views/OccupationForm.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/localization/",
    name: "Localization",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Localization" */ "../views/Localization.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/orderForDelivered",
    name: "OrderForDelivered",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/OrderForDelivered" */ "../views/OrderForDelivered.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/emarketingpreview",
    name: "EMPreview",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/emarketingpreview" */ "../views/EMarketingPreview.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Newsletter" */ "../views/Newsletter.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/demo",
    name: "Demo",
    component: () =>
      import(/* webpackChunkName: "backoffice/Demo" */ "../views/demo.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/restaurantype",
    name: "RestaurantType",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/RestaurantType" */ "../views/RestaurantTypeDetail.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/specialsprice",
    name: "Specialsprice",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Specialsprice" */ "../views/SpecialsPrice.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/specialspricedetail",
    name: "SpecialsPriceDetail",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/SpecialsPriceDetail" */ "../views/SpecialsPriceDetail.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/chat",
    name: "Chat",
    component: () =>
      import(/* webpackChunkName: "Chat" */ "../../frontend/views/Chat.vue"),
  },
  {
    path: "/connections",
    name: "Connections",
    component: () =>
      import(
        /* webpackChunkName: "Connections" */ "../../frontend/views/Connections.vue"
      ),
    meta: { requiresAuth: true },
  },

  {
    path: "/eMarketing",
    name: "eMarkeeting",
    component: () =>
      import(
        /* webpackChunkName: "backoffice/Emarkeeting" */ "../../frontend/views/EMarkeeting.vue"
      ),
    meta: { requiresAuth: true },
  },

  // FRONTEND

  {
    path: "/journal",
    name: "Journal",
    component: () =>
      import(
        /* webpackChunkName: "frontend/journal" */ "../../frontend/views/JournalView.vue"
      ),
  },
  {
    // path: '/menus',
    path: "/:url/menu",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "frontend/home" */ "../../frontend/views/HomeGrid.vue"
      ),
  },
  {
    // path: '//catering',
    path: "/:url/catering",
    name: "Catering",
    component: () =>
      import(
        /* webpackChunkName: "frontend/home" */ "../../frontend/views/HomeGrid.vue"
      ),
  },
  {
    path: "",
    name: "App",
  },
  {
    // path: '/home',
    path: "/:url/about",
    name: "AboutFront",
    component: () =>
      import(
        /* webpackChunkName: "frontend/about" */ "../../frontend/views/About.vue"
      ),
  },
  {
    // path: '/home',
    path: "/:url/ads",
    name: "Ads",
    component: () =>
      import(
        /* webpackChunkName: "frontend/ads" */ "../../frontend/views/Ads.vue"
      ),
  },
  {
    //  path: '/products',
    path: "/:url",
    name: "Products",
    component: () =>
      import(
        /* webpackChunkName: "frontend/product" */ "../../frontend/views/Products.vue"
      ),
  },
  {
    // path: '/order',
    path: "/:url/order",
    name: "OrderFront",
    component: () =>
      import(
        /* webpackChunkName: "frontend/order" */ "../../frontend/views/Order.vue"
      ),
  },
  {
    // path: '/orderDetail',
    path: "/:url/orderdetail",
    name: "OrderState",
    component: () =>
      import(
        /* webpackChunkName: "frontend/orderstate" */ "../../frontend/views/OrderState.vue"
      ),
  },
  {
    // path: '/listOrder',
    path: "/:url/orderlist",
    name: "ListOrder",
    component: () =>
      import(
        /* webpackChunkName: "frontend/orderlist" */ "../../frontend/views/ListOrder.vue"
      ),
  },
  {
    // path: '/reservation',
    path: "/:url/reservation/:orderId",
    name: "ReservationOrder",
    component: () =>
      import(
        /* webpackChunkName: "frontend/reservation" */ "../../frontend/views/Reservation.vue"
      ),
  },
  {
    // path: '/reservation',
    path: "/:url/reservation",
    name: "Reservation",
    component: () =>
      import(
        /* webpackChunkName: "frontend/reservation" */ "../../frontend/views/Reservation.vue"
      ),
  },
  {
    path: "/:url/myaccount",
    name: "Myaccount",
    component: () =>
      import(
        /* webpackChunkName: "frontend/myaccount" */ "../../frontend/views/MyAccount.vue"
      ),
  },
  {
    path: "/:url/credit-card-form",
    name: "CreditCardSaved",
    component: () =>
      import(
        /* webpackChunkName: "frontend/CreditCardSaved" */ "../../frontend/components/CreditCardSaved.vue"
      ),
  },
  {
    path: "/:url/credit-card-form",
    name: "CreditCardForm",
    component: () =>
      import(
        /* webpackChunkName: "frontend/CreditCardForm" */ "../../frontend/components/CreditCardForm.vue"
      ),
  },
  {
    path: "/:url/addresses",
    name: "BillingAddressComponent",
    component: () =>
      import(
        /* webpackChunkName: "frontend/billingaddresscomponent" */ "../../frontend/components/AddressComponent.vue"
      ),
  },
  {
    path: "/:url/addresses/:addressId",
    name: "BillingAddressView",
    component: () =>
      import(
        /* webpackChunkName: "frontend/billingaddresscomponent" */ "../../frontend/components/AddressComponent.vue"
      ),
  },
  {
    path: "/:url/addresses",
    name: "ShippingAddressComponent",
    component: () =>
      import(
        /* webpackChunkName: "frontend/shippingaddresscomponent" */ "../../frontend/components/ShippingAddressComponent.vue"
      ),
  },
  {
    path: "/:url/addresses/:addressId",
    name: "ShippingAddressView",
    component: () =>
      import(
        /* webpackChunkName: "frontend/shippingaddresscomponent" */ "../../frontend/components/ShippingAddressComponent.vue"
      ),
  },
  {
    // path: '/reservationDetail',
    path: "/:url/reservationdetail",
    name: "ReservationDetail",
    component: () =>
      import(
        /* webpackChunkName: "frontend/reservationDetail" */ "../../frontend/views/ReservationState.vue"
      ),
  },
  {
    path: "/newbusiness",
    name: "NewRestaurant",
    component: () =>
      import(
        /* webpackChunkName: "frontend/newRestaurant" */ "../../frontend/views/NewRestautant.vue"
      ),
  },
  {
    path: "/page/imenurevenue",
    name: "iMenuRevenueLanding",
    component: () =>
      import(
        /* webpackChunkName: "frontend/iMenuRevenueLanding" */ "../../frontend/views/iMenuRevenueLanding.vue"
      ),
    meta: { requiresAuth: false, imenurevenue: true }, // Permitir acceso sin autenticación
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import(
        /* webpackChunkName: "frontend/Test" */ "../../frontend/views/Test.vue"
      ),
  },
  {
    path: "/trackingpackage",
    name: "TrackingPackage",
    component: () =>
      import(
        /* webpackChunkName: "frontend/Test" */ "../../frontend/views/TrackingPackage.vue"
      ),
  },
  {
    path: "/shipping",
    name: "ShippingComponent",
    component: () =>
      import(
        /* webpackChunkName: "frontend/ShippingComponent" */ "../../frontend/components/ShippingComponent.vue"
      ),
  },
  {
    path: "/newonboarding",
    name: "NewOnboardingNAB",
    component: () =>
      import(
        /* webpackChunkName: "frontend/newRestaurant" */ "../../frontend/views/NewOnboardingNAB.vue"
      ),
  },
  // {
  //   path: "/new-business",
  //   name: "NewBusiness",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "frontend/newBusiness" */ "../../frontend/views/NewBusiness.vue"
  //     ),
  // },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: () =>
      import(
        /* webpackChunkName: "frontend/unsubscribe" */ "../../frontend/views/Unsubscribe.vue"
      ),
  },
  {
    // path: '/listCredit',
    path: "/:url/creditlist",
    name: "ListCredit",
    component: () =>
      import(
        /* webpackChunkName: "frontend/creditList" */ "../../frontend/views/ListCredit.vue"
      ),
  },
  {
    // path: '/listCreditDetail',
    path: "/:url/creditdetail",
    name: "ListCreditDetail",
    component: () =>
      import(
        /* webpackChunkName: "frontend/creditDetail" */ "../../frontend/views/ListCreditDetail.vue"
      ),
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "NotFound/App" */ "../../App.vue"),
    name: "NotFound",
  },
  {
    path: "/:url",
    name: "ContactUs",
    component: () =>
      import(
        /* webpackChunkName: "frontend/ContactUs" */ "../../frontend/views/ContactUs.vue"
      ),
  },
  //  {
  //   path: '/:url',
  //   name: 'Chat',
  //   component: () => import(/* webpackChunkName: "frontend/chat" */ '../../frontend/views/Chat.vue')
  //  },
  {
    path: "/:url",
    name: "ChatWithUrl",
    component: () =>
      import(
        /* webpackChunkName: "frontend/chatwww" */ "../../frontend/views/Chat.vue"
      ),
  },
  //  {
  //   path: '/:url',
  //   name: 'Connections',
  //   component: () => import(/* webpackChunkName: "Connection" */ '../../frontend/views/Connections.vue')
  //  },
  {
    path: "/:url",
    name: "ConnectionsWithUrl",
    component: () =>
      import(
        /* webpackChunkName: "Connection" */ "../../frontend/views/Connections.vue"
      ),
  },
  //{
  //  path: "/:url",
  //  name: "eMarkeeting",
  //  component: () =>
  //    import(
  //      /* webpackChunkName: "Emarketing" */ "../../frontend/views/EMarkeeting.vue"
  //    ),
  //},
  {
    path: "/:url",
    name: "eMarkeetingWithUrl",
    component: () =>
      import(
        /* webpackChunkName: "Emarketing" */ "../../frontend/views/EMarkeeting.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.isNewBusiness) {
    next({ name: "AppVue", query: { new: true } });
    //next();
  } else if (to.meta.imenurevenue) {
    next({ name: "AppVue", query: { revenue: true } });
    //next();
  } else if (to.meta.successgoogle) {
    next({ name: "AppVue", query: { success: true } });
    //next();
  }  else if (to.meta.requiresAuth && !store.state.authenticated) {
    next({ name: "AppVue", params: { openEnd: true } });
  } else {
    next();
  }
});

export default router;
