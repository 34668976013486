import Swal from "sweetalert2";
import { Utils } from "./utils.js";
import { Api } from "../api/api.js";
import store from '../../store/index'
import { i18n } from '@/plugins/i18n'

export const NotificationUtil = {
  /**
   * Checks if the text is a valid email format
   */
  isEmail: (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },
  /**
   * Show cancel scheduled email notification
   * @param {*} order
   * @param {*} cb
   */
  showCancelEmailPopup: async (order, userId, cb) => {
    //console.log("showCancelEmailPopup", order);
    const swalAlertResult = await Swal.fire({
      title: "Warning",
      html: `<p>There is already a scheduled email to be sent to <span style="color:red">${
        order.scheduledNotification.email
      }</span> on 
          <span style="color:red">${Utils.getDateDisplayInLocalTime(
            order.scheduledNotification.scheduledAt
          )}</span></p>`,
      showCancelButton: true,
      confirmButtonText: "Cancel Email",
      cancelButtonText: "Dismiss",
    });

    if (
      swalAlertResult.dismiss == "cancel" ||
      swalAlertResult.dismiss == "backdrop"
    ) {
      //do nothing
      cb(false);

      return;
    } else if (swalAlertResult.isConfirmed) {
      //cancel the scheduledEmail
      const cancelResult = await Api.cancelScheduledEmailNotification(
        order.scheduledNotification._id || order.scheduledNotification.id,
        userId
      );
      if (cancelResult) {
        return cb(true);
      }
      return cb(false);
    }
  },

  emailExists: (email) => {
    const subscribers = store.state.backConfig.subscriber || [];
    
    return subscribers.some(subscriber => subscriber.Email === email || subscriber.EmailAddress === email);
  },

  emailCustomerExist: (email) => {
    const customers = store.state.backConfig.allCustomer || [];
    
    return customers.some(customer => customer.Email === email || customer.EmailAddress === email)
  },

  showNotificationPopup: async (toEmail, toPhone, scheduledAt, cb, emailLabel = null,showButtons) => {
    if (!emailLabel) {
      emailLabel = i18n.t('backoffice.titles.sendNotification');
    }

    // Get current date and time
    var now = scheduledAt || new Date();
    var year = now.getFullYear();
    var month = String(now.getMonth() + 1).padStart(2, "0");
    var day = String(now.getDate()).padStart(2, "0");
    var hours = String(now.getHours()).padStart(2, "0");
    var minutes = String(now.getMinutes()).padStart(2, "0");
    var dateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;

    const result = await Swal.fire({
      title: i18n.t('backoffice.titles.shareViaEmailAndSMS'),
      html: `
        <p>${i18n.t('backoffice.titles.textSend')}</p>
        <input id="customer-search" class="swal2-input" type="text" placeholder="${i18n.t('backoffice.titles.searchCustomer')}"><br>
        <p style="color:gray; list-style: none; text-align: start; margin-left: 60px;" id="customer-list"></p>
        <label for="swal-input2">${emailLabel}</label>
        <input id="swal-input2" class="swal2-input" type="email" value="${toEmail}" placeholder="${i18n.t('backoffice.titles.emailPlaceholder')}"><br>
        <input id="swal-input3" class="swal2-input" type="text" value="${toPhone}" placeholder="${i18n.t('backoffice.titles.phonePlaceholder')}"><br>
        <label><input type="checkbox" id="email-checkbox" checked>${i18n.t('backoffice.titles.sendEmail')}</label><br>
        <label><input type="checkbox" id="sms-checkbox" checked>${i18n.t('backoffice.titles.sendSMS')}</label><br>
        <label for="swal-input1">${i18n.t('backoffice.titles.whenToSend')}</label>
        <input id="swal-input1" class="swal2-input" type="datetime-local" value="${dateTimeString}">
        <div style="display: flex; justify-content: center; gap: 10px; margin-top: 20px;">
          <button id="add-subscriber-btn" class="swal2-input" style="display: none; background:#00A550; border-radius:4px; color: white; font-weigth:bold;">${i18n.t('backoffice.titles.createSubscriptor')}</button>
          <button id="add-customer-btn" class="swal2-input" style="display: none; background:#00A550; border-radius:4px; color: white; font-weigth:bold;">${i18n.t('backoffice.titles.createCustomer')}</button>
        </div>
        `,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: i18n.t('backoffice.titles.sendNow'),
      denyButtonText: i18n.t('backoffice.titles.schedule'),
      cancelButtonText: i18n.t('backoffice.titles.cancel'),
      focusConfirm: false,
      backdrop: false,
      didOpen: () => {
        const showSearchBtn = document.getElementById('show-search-btn');
        const searchContainer = document.getElementById('search-container');
        const customerSearchInput = document.getElementById('customer-search');
        const customerList = document.getElementById('customer-list');
        const emailInput = document.getElementById("swal-input2");
        const addSubscriberBtn = document.getElementById("add-subscriber-btn");
        const addCustomerBtn = document.getElementById("add-customer-btn");
        if (showButtons) {
          emailInput.addEventListener('input', () => {
            const emailStr = emailInput.value;
            if (!NotificationUtil.isEmail(emailStr)) {
              // Si no es un correo válido, oculta ambos botones
              addSubscriberBtn.style.display = 'none';
              addCustomerBtn.style.display = 'none';
              return; // Salir de la función
          }
            const emailExists = NotificationUtil.emailExists(emailStr);
            const emailCustomerExists = NotificationUtil.emailCustomerExist(emailStr);
        
            if (emailStr && !emailExists && !emailCustomerExists) {
                addSubscriberBtn.style.display = 'block';
                addCustomerBtn.style.display = 'block';
            } else if (emailStr && !emailExists) {
                addSubscriberBtn.style.display = 'none';
                addCustomerBtn.style.display = 'block';
            } else if (emailStr && !emailCustomerExists) {
                addSubscriberBtn.style.display = 'block';
                addCustomerBtn.style.display = 'none';
            } else {
                addSubscriberBtn.style.display = 'none';
                addCustomerBtn.style.display = 'none';
            }
          });

          addSubscriberBtn.addEventListener('click', () => {
            const emailStr = document.getElementById("swal-input2").value; 
            const phoneStr = document.getElementById("swal-input3").value;

            const event = new CustomEvent('email-not-found', { detail: { recivedEmail: emailStr, recivedPhone: phoneStr } });
            window.dispatchEvent(event);
            Swal.close();
          });

          addCustomerBtn.addEventListener('click', () => {
            const emailStr = document.getElementById("swal-input2").value; 
            const phoneStr = document.getElementById("swal-input3").value;

            const event = new CustomEvent('open-customer-modal', { detail: { recivedEmail: emailStr, recivedPhone: phoneStr } });
            window.dispatchEvent(event);
            Swal.close();
          });
        }
        if (showSearchBtn && searchContainer) {
            showSearchBtn.addEventListener('click', () => {
                searchContainer.style.display = 'block';
                showSearchBtn.style.display = 'none';
            });
        }
    
        if (customerSearchInput && customerList) {
          customerSearchInput.addEventListener('input', () => {
              const searchTerm = customerSearchInput.value.toLowerCase();
              customerList.innerHTML = '';
      
              if (searchTerm.length > 0) {
                  const filteredCustomers = store.state.backConfig.allCustomer.filter(
                      customer => (
                          (customer.Name && customer.Name.toLowerCase().includes(searchTerm)) ||
                          (customer.Email && customer.Email.toLowerCase().includes(searchTerm)) ||
                          (customer.EmailAddress && customer.EmailAddress.toLowerCase().includes(searchTerm)) ||
                          (customer.Phone && customer.Phone.toLowerCase().includes(searchTerm))
                      )
                  );
      
                  const filteredSubscribers = store.state.backConfig.subscriber.filter(
                      subscriber => (
                          (subscriber.Name && subscriber.Name.toLowerCase().includes(searchTerm)) ||
                          (subscriber.Email && subscriber.Email.toLowerCase().includes(searchTerm)) ||
                          (subscriber.EmailAddress && subscriber.EmailAddress.toLowerCase().includes(searchTerm)) ||
                          (subscriber.Phone && subscriber.Phone.toLowerCase().includes(searchTerm))
                      )
                  );
      
                  const combinedList = [...filteredCustomers, ...filteredSubscribers];
      
                  combinedList.forEach(item => {
                      const listItem = document.createElement('ion-item');
                      listItem.textContent = `${item.Name} (${item.Email || item.EmailAddress}) ${item.Phone || ''}`;
                      listItem.addEventListener('click', () => {
                          document.getElementById('swal-input2').value = item.Email || item.EmailAddress;
                          document.getElementById('swal-input3').value = item.Phone;
                          customerList.innerHTML = '';
                      });
                      customerList.appendChild(listItem);
                  });
              }
          });
      }
    },
      preConfirm: () => {
        const emailStr = document.getElementById("swal-input2").value;
        const phoneStr = document.getElementById("swal-input3").value;
        const sendEmail = document.getElementById("email-checkbox").checked;
        const sendSms = document.getElementById("sms-checkbox").checked;

        if (!sendEmail && !sendSms) {
          return Swal.showValidationMessage("Please select at least one method (Email or SMS)");
        }

        // if (sendEmail && !NotificationUtil.isEmail(emailStr)) {
        //   return Swal.showValidationMessage("Please enter a valid email");
        // }

        // if (sendSms && !phoneStr) {
        //   return Swal.showValidationMessage("Please enter a valid phone number");
        // }

        let method = [];
        if (sendEmail) method.push('email');
        if (sendSms) method.push('phone');
        
        let emails = [];
        if (emailStr) emails.push(emailStr);
        return {
          email: emailStr,
          mobile: phoneStr,
          method: method.join(','),
        };
      },
      preDeny: () => {
        const emailStr = document.getElementById("swal-input2").value;
        const phoneStr = document.getElementById("swal-input3").value;
        const scheduleVal = document.getElementById("swal-input1").value;
        const sendEmail = document.getElementById("email-checkbox").checked;
        const sendSms = document.getElementById("sms-checkbox").checked;

        if (!sendEmail && !sendSms) {
          return Swal.showValidationMessage("Please select at least one method (Email or SMS)");
        }

        // if (sendEmail && !NotificationUtil.isEmail(emailStr)) {
        //   return Swal.showValidationMessage("Please enter a valid email");
        // }

        // if (sendSms && !phoneStr) {
        //   return Swal.showValidationMessage("Please enter a valid phone number");
        // }

        if (!scheduleVal || new Date() > new Date(scheduleVal)) {
          return Swal.showValidationMessage("The scheduled date and time must be in the future.");
        }

        let method = [];
        if (sendEmail) method.push('email');
        if (sendSms) method.push('phone');

        return {
          schedule: new Date(scheduleVal).getTime(),
          email: emailStr,
          mobile: phoneStr,
          method: method.join(','),
        };
      },
    });

    // Handle actions based on the button selected
    if (result.isConfirmed) {
      // Send Now
      cb(false, { email: result.value.email, mobile: result.value.mobile, method: result.value.method, scheduledAt: null });
    } else if (result.isDenied) {
      // Schedule
      const value = result.value;
      cb(false, { email: value.email, mobile: value.mobile, method: value.method, scheduledAt: value.schedule });
    } else {
      // Cancel
      cb(true, null);
    }
  },

  // async bulkSendEmail(cb) {
  //   // Obtener la lista de clientes y suscriptores
  //   const customers = store.state.backConfig.allCustomer || [];
  //   const subscribers = store.state.backConfig.subscriber || [];

  //   // Crear el HTML para mostrar la lista de clientes y suscriptores con títulos
  //   const customerListHtml = customers.map((item, index) => `
  //     <div>
  //       <input type="checkbox" id="customer-${index}" value="${item.Email || item.EmailAddress}">
  //       <label for="customer-${index}">${item.Name} (${item.Email || item.EmailAddress})</label>
  //     </div>
  //   `).join('');

  //   const subscriberListHtml = subscribers.map((item, index) => `
  //     <div>
  //       <input type="checkbox" id="subscriber-${index}" value="${item.Email || item.EmailAddress}">
  //       <label for="subscriber-${index}">${item.Name} (${item.Email || item.EmailAddress})</label>
  //     </div>
  //   `).join('');

  //   // Mostrar el modal para seleccionar destinatarios
  //   const { value: selectedRecipients } = await Swal.fire({
  //     title: 'Select Recipients',
  //     html: `
  //       <div>
  //         <input type="checkbox" id="select-all" onclick="toggleSelectAll(this)">
  //         <label for="select-all">Select All</label>
  //       </div>
  //       <h3>Customers</h3>
  //       ${customerListHtml}
  //       <h3>Subscribers</h3>
  //       ${subscriberListHtml}
  //     `,
  //     focusConfirm: false,
  //     preConfirm: () => {
  //       const selected = [];
  //       customers.forEach((item, index) => {
  //         const checkbox = document.getElementById(`customer-${index}`);
  //         if (checkbox && checkbox.checked) {
  //           selected.push({
  //             email: item.Email || item.EmailAddress,
  //             phone: item.Phone
  //           });
  //         }
  //       });
  //       subscribers.forEach((item, index) => {
  //         const checkbox = document.getElementById(`subscriber-${index}`);
  //         if (checkbox && checkbox.checked) {
  //           selected.push({
  //             email: item.Email || item.EmailAddress,
  //             phone: item.Phone
  //           });
  //         }
  //       });
  //       return selected;
  //     }
  //   });

  //   // Enviar notificaciones a los destinatarios seleccionados
  //   if (selectedRecipients && selectedRecipients.length > 0) {
  //     for (const recipient of selectedRecipients) {
  //       const email = recipient.email;
  //       const mobile = recipient.phone;

  //       await this.showNotificationPopup(email, mobile, new Date(), (isCancelled, result) => {
  //         if (!isCancelled) {
  //           cb({
  //             CustomerEmail: result.email,
  //             CustomerMobile: result.mobile,
  //             Title: 'Bulk Notification',
  //             scheduledAt: result.scheduledAt,
  //             method: result.method
  //           });
  //         }
  //       });
  //     }
  //   }
  // },

  async bulkSendEmail(cb) {
    // Obtener la lista de clientes y suscriptores
    const customers = store.state.backConfig.allCustomer || [];
    const subscribers = store.state.backConfig.subscriber || [];

    // Crear el HTML para mostrar la lista de clientes y suscriptores con títulos
    const customerListHtml = customers.map((item, index) => `
      <ion-item>
        <input type="checkbox" class="customer-checkbox" id="customer-${index}" value="${item.Email || item.EmailAddress}">
        <label for="customer-${index}">${item.Name} (${item.Email || item.EmailAddress})</label>
      </ion-item>
    `).join('');

    const subscriberListHtml = subscribers.map((item, index) => `
      <ion-item>
        <input type="checkbox" class="subscriber-checkbox" id="subscriber-${index}" value="${item.Email || item.EmailAddress}">
        <label for="subscriber-${index}">${item.Name} (${item.Email || item.EmailAddress})</label>
      </ion-item>
    `).join('');

    // Mostrar el modal para seleccionar destinatarios
    const { value: selectedRecipients } = await Swal.fire({
      title: 'Select Recipients',
      html: `
        <h3>Customers</h3>
        <input type="checkbox" id="select-all-customers" onclick="toggleSelectAll('customer')">
        <label for="select-all-customers">Select All Customers</label>
          ${customerListHtml}
        <h3>Subscribers</h3>
        <input type="checkbox" id="select-all-subscribers" onclick="toggleSelectAll('subscribers')">
        <label for="select-all-subscribers">Select All Subscribers</label>
          ${subscriberListHtml}
      `,
      focusConfirm: false,
      preConfirm: () => {
        const selected = [];
        customers.forEach((item, index) => {
          const checkbox = document.getElementById(`customer-${index}`);
          if (checkbox && checkbox.checked) {
            console.log("emaillll", item.EmailAddress, "phonee", item.Phone)

            selected.push({
              email: item.EmailAddress,
              phone: item.Phone,

            });
          }
        });
        subscribers.forEach((item, index) => {
          const checkbox = document.getElementById(`subscriber-${index}`);
          if (checkbox && checkbox.checked) {
            selected.push({
              email: item.Email || item.EmailAddress,
              phone: item.Phone
            });
          }
        });
        return selected;
      }
    });

    // Si hay destinatarios seleccionados, mostrar opciones de envío
    if (selectedRecipients && selectedRecipients.length > 0) {
      const result = await Swal.fire({
        title: "Bulk Send Options",
        html: `
          <label><input type="checkbox" id="email-checkbox" checked> Send Email</label><br>
          <label><input type="checkbox" id="sms-checkbox" checked> Send SMS</label><br>
          <label for="swal-input1">When do you want to send it?</label>
          <input id="swal-input1" class="swal2-input" type="datetime-local" value="${new Date().toISOString().slice(0, 16)}">
        `,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Send Now',
        denyButtonText: 'Schedule',
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          const sendEmail = document.getElementById("email-checkbox").checked;
          const sendSms = document.getElementById("sms-checkbox").checked;

          let method = [];
          if (sendEmail) method.push('email');
          if (sendSms) method.push('phone');

          if (!sendEmail && !sendSms) {
            return Swal.showValidationMessage("Please select at least one method (Email or SMS)");
          }

          const selected = [];
          customers.forEach((item, index) => {
            const checkbox = document.getElementById(`customer-${index}`);
            if (checkbox && checkbox.checked) {
              console.log("emaillll", item.EmailAddress, "phonee", item.Phone)

              selected.push({
                email: item.EmailAddress,
                phone: item.Phone,

              });
            }
          });

          return {
            // method: `${sendEmail ? 'email' : ''}${sendEmail && sendSms ? ',' : ''}${sendSms ? 'phone' : ''}`,
            // scheduledAt: null
            email: selected.email,
            mobile: selected.phone,
            method: method.join(','),
          };
        },
        preDeny: () => {
          const sendEmail = document.getElementById("email-checkbox").checked;
          const sendSms = document.getElementById("sms-checkbox").checked;
          const scheduleVal = document.getElementById("swal-input1").value;

          if (!sendEmail && !sendSms) {
            return Swal.showValidationMessage("Please select at least one method (Email or SMS)");
          }

          if (!scheduleVal || new Date() > new Date(scheduleVal)) {
            return Swal.showValidationMessage("The scheduled date and time must be in the future.");
          }

          return {
            method: `${sendEmail ? 'email' : ''}${sendEmail && sendSms ? ',' : ''}${sendSms ? 'phone' : ''}`,
            scheduledAt: new Date(scheduleVal).getTime()
          };
        }
      });

      if (result.isConfirmed || result.isDenied) {
        const notificationData = selectedRecipients.map(recipient => ({
          email: recipient.email,
          mobile: recipient.phone,
          title: 'Bulk Notification',
          scheduledAt: result.value.scheduledAt,
          method: result.value.method
        }));

        // Llamar al callback con todos los datos de notificación
        cb(notificationData);
      }
    }
  },

};
