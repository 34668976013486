<template>
    <ion-content>

        <ion-loading v-if="showLoading" cssClass=""
            :message="addressType == 'billing' ? 'Loading Billing Addresses' : 'Loading Shipping Addresses'"></ion-loading>

        <ion-header>
            <ion-toolbar>

                <ion-buttons slot="end" v-if="isModal">
                    <ion-button @click="$modal.hide('list-address')">❌</ion-button>
                </ion-buttons>
                <ion-label style="padding: 20px 100px;">
                    <h1>{{ addressType == 'billing' ? 'Billing Addresses' : 'Shipping Addresses' }}</h1>
                </ion-label>
            </ion-toolbar>
        </ion-header>

        <ion-row class="ion-padding-vertical header-row" style="align-items: start;">
            <ion-col style="display: flex; align-items: flex-start;">
                <ion-button class="header-btn" @click="listActive = false" :style="!listActive
                    ? 'color: var(--ion-color-primary);'
                    : 'color: #9d9d9d;'
                    " size="small" fill="clear">
                    <ion-icon slot="icon-only" icon="md-apps"></ion-icon>
                </ion-button>
                <ion-button class="header-btn" @click="listActive = true" :style="listActive
                    ? 'color: var(--ion-color-primary);'
                    : 'color: #9d9d9d;'
                    " size="small" fill="clear">
                    <ion-icon slot="icon-only" icon="md-list"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>

        <div v-if="!showLoading && addresses.count == 0">No addresses yet. Create one?</div>
        <!--CARD-->
        <ion-grid v-if="!listActive">
            <ion-row>
                <ion-col size="12" size-md="3" v-for="address in addresses" :key="address._id">
                    <ion-card class="min-height">
                        <ion-card-header>
                            <ion-card-title>{{ addressType == 'billing' ? 'Billing Address' : 'Shipping Address'
                            }}</ion-card-title>
                            <ion-card-subtitle>Street 1: <span>{{ address.street1 }}</span></ion-card-subtitle>
                            <ion-card-subtitle>City: <span>{{ address.city }}</span></ion-card-subtitle>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-label>First Name:<span>{{ address.firstName }}</span></ion-label>
                            <br>
                            <ion-label>Phone: <span>{{ address.phone }}</span></ion-label>
                        </ion-card-content>
                        <ion-footer>
                            <ion-chip @click="addressSelected(address)">Select</ion-chip>
                        </ion-footer>
                    </ion-card>
                </ion-col>
            </ion-row>
        </ion-grid>
        <!--LIST-->
        <ion-grid v-if="listActive">
            <ion-row v-for="address in addresses" :key="address._id" class="full-width-row">
                <ion-col size="12">
                    <ion-list class="content-list">
                        <ion-item class="select-hover" @click="addressSelected(address)">
                            <ion-label>
                                <h2>{{ address.firstName }}</h2>
                                <h3>Street 1: <span>{{ address.street1 }}</span></h3>
                                <p>City: <span>{{ address.city }}</span></p>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
</template>



<script>
import { Api } from '../../backoffice/api/api.js';
import Swal from 'sweetalert2';

export default {
    name: 'SameAddressList',
    props: {
        addressType: String,
        userId: String,
        isModal: {
            type: Boolean,
            default: true
        }
    },
    components: {

    },
    data() {
        return {
            addresses: [],
            showLoading: false,
            listActive: false,
        }
    },
    methods: {
        addressSelected(address) {
            this.$emit('address-selected', address);
        },
        createNewAddress() {
            this.$emit('create-address', this.addressType);
        },
        /**
     * Set Address as default
     * @param {} addressId 
     */
        setAsDefaultShipping(addressId) {

            Api.setAddressAsDefault(addressId).then(response => {
                console.log(response);
                if (response) {
                    //reload the data
                    this.loadAddresses();
                    Swal.fire({
                        title: 'Updated default shipping address',
                        backdrop:false,
                    });
                } else {
                    Swal.fire({
                        title: 'Something went wrong',
                        backdrop:false,
                    });
                }

            });
        },
        setAsDefaultBilling(addressId) {

            Api.setAddressAsDefault(addressId).then(response => {
                console.log(response);
                if (response) {
                    //reload the data
                    this.loadAddresses();
                    Swal.fire({
                        title: 'Updated default shipping address',
                        backdrop:false,
                    });
                } else {
                    Swal.fire({
                        title: 'Something went wrong',
                        backdrop:false,
                    });
                }

            });
        },
        loadAddresses() {

            this.showLoading = true;
            //this.$store.commit('setAddresses', JSON.parse(JSON.stringify(allRest)))
            Api.getAddressByUser(this.userId, this.addressType).then(response => {

                if (response) {
                    this.addresses = response.data.data.filter(item => item.type == this.addressType);
                    console.log({response})
                } else {
                    //do nothing
                }
                this.showLoading = false;
            });
        }
    },
    created() {
        //load all the addresses and filter
        this.loadAddresses();
    },

};
</script>

<style scoped>
.select-hover {
  cursor: pointer;
}
ion-item.select-hover:hover {
  background-color: rgb(241, 241, 241)!important; /* Para dar una indicación visual adicional cuando el cursor está sobre el enlace */
}
</style>