<template>
  <ion-content>
    <modal name="list-addresses" width="90%" height="80%">
      <SameAddressList :isModal="true" :userId="customerId" :addressType="selectedAddressType"
            @address-selected="handleSelectedAddress" @address="addressAdded">
      </SameAddressList>
    </modal>


    <ion-loading v-if="spinner" cssClass="my-custom-class"
      :message="$t('frontend.tooltips.loadRestaurant')"></ion-loading>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" v-if="!isModal">
          <ion-back-button default-href="/myaccount" @click="goBack()"></ion-back-button>
        </ion-buttons>

        <ion-buttons slot="end" v-if="isModal">
          <ion-button @click="$modal.hide('create-address')">❌</ion-button>
        </ion-buttons>
        <ion-label style="padding: 20px 100px;">
          <h1>{{ addressType == 'billing' ? $t("backoffice.options.billingAddress") :
      $t("backoffice.options.shippingAddress") }}</h1>
        </ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-grid class="accordion-item active">
      <ion-row class="accordion-header">
        <h5>{{ addressType == 'billing' ? $t("backoffice.options.billingAddress") :
      $t("backoffice.options.shippingAddress") }}</h5>
        <span class="accordion-icon">+</span>
      </ion-row>
      <ion-grid class="accordion-content">
        <ion-row>
          <ion-col size-xs="12" size="8" size-lg="8">
            <ion-item v-if="addressesBilling.length > 0 || isModal">
              <ion-label>Same As Billing Address</ion-label>
              <ion-checkbox :checked="sameAddress" @ionChange="showBillingAddresses(addressType == 'billing' ? 'shipping' : 'billing' )"></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label position="floating"><span style="color: red">*</span>Contact Person</ion-label>
              <ion-input class="input-style" :value="billingAddress.firstName"
                @input="billingAddress.firstName = $event.target.value"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-xs="12" size="4" size-lg="4">
            <ion-item>
              <ion-label position="floating"><span style="color: red">*</span>Email</ion-label>
              <ion-input class="input-style" :value="billingAddress.email"
                @input="billingAddress.email = $event.target.value" @change="validateEmail"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size-xs="12" size="4" size-lg="4">
            <ion-item>
              <country-flag :country="country.country" />
              <ion-select class="required" interface="alert" @ionChange="
      (country = JSON.parse($event.target.value)), (phone = '')
      " style="padding: 0" :selected-text="country.code">
                <ion-select-option v-for="con in countries" :key="`${con.country}+${con.code}`" :text="con.name"
                  :value="JSON.stringify(con)">{{ con.name }} ({{ con.code }})
                </ion-select-option>
              </ion-select>

              <ion-input class="input-style" type="text" autocomplete="tel"
                :placeholder="$t('frontend.orderType.phone')" v-model="billingAddress.phone" name="phone"
                id="phone-validator" @input="billingAddress.phone = $event.target.value"
                v-bind:value="billingAddress.phone" @change="billingAddress.phone = validatePhone($event.target.value)">
              </ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-xs="12" size="8" size-lg="8">
           
            <!--HERE START-->
            <ion-item>
              <ion-label position="floating"><span style="color: red">*</span>Street 1</ion-label>
              <PlacesAutoComplete @change="billingAddress.street1" :initialAddress="billingAddress.street1" @place_changed="placeChanged" :value="billingAddress.street1"/>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Street 2</ion-label>
              <ion-input class="input-style" type="text" :value="billingAddress.street2"
                @input="billingAddress.street2 = $event.target.value"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Street 3</ion-label>
              <ion-input class="input-style" type="text" :value="billingAddress.street3"
                @input="billingAddress.street3 = $event.target.value"></ion-input>
            </ion-item>
            <ion-row>
              <ion-col size="6">
                <ion-item>
                  <ion-label position="floating">Country<span style="color: red">*</span></ion-label>
                  <ion-select class="required" interface="alert" @ionChange="
      (countryFrom = JSON.parse($event.target.value))
      " style="padding: 0" :selected-text="billingAddress.countryFrom.name">
                    <ion-select-option v-for="con in countries" :key="`${con.country}`" :text="con.name"
                      :value="JSON.stringify(con)">{{ con.name }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item>
                  <ion-label position="floating"><span style="color: red">*</span>{{ $t("frontend.home.stateProvince")
                    }}</ion-label>
                  <ion-input class="input-style" type="text" :value="billingAddress.state"
                    @input="billingAddress.state = $event.target.value" placeholder="Example: NJ"> </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">
                <ion-item>
                  <ion-label position="floating"><span style="color: red">*</span>{{ this.$t("frontend.home.city")
                    }}</ion-label>
                  <ion-input class="input-style" type="text" :value="billingAddress.city"
                    @input="billingAddress.city = $event.target.value"> </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="4">
                <ion-item>
                  <ion-label position="floating"><span style="color: red"></span>{{ $t("frontend.home.county")
                    }}</ion-label>
                  <ion-input class="input-style" type="text" :value="billingAddress.county"
                    @input="billingAddress.county = $event.target.value">
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="4">
                <ion-item>
                  <ion-label position="floating"><span style="color: red">*</span>{{ $t("frontend.orderType.code")
                    }}</ion-label>
                  <ion-input class="input-style" type="text" :value="billingAddress.zip"
                    @input="billingAddress.zip = $event.target.value">
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <!-- <ion-item>
              <ion-label position="floating"><span style="color: red">*</span>Country</ion-label>
              <ion-input class="input-style" type="text" :value="billingAddress.country"
                @input="billingAddress.country = $event.target.value">
              </ion-input>
            </ion-item> -->
            <ion-item>
              <ion-label position="floating">Additional details</ion-label>
              <ion-input class="input-style" type="text" :value="billingAddress.additionalDetails"
                @input="billingAddress.additionalDetails = $event.target.value"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-grid>
    <ion-row class="flex-row">
      <ion-button size="small" @click="handleCancel()">{{ $t('backoffice.list.messages.buttons.cancel') }}</ion-button>
      <ion-button size="small" :disabled="validateFields()" @click="handleAdd()">{{ $t('backoffice.form.buttons.save')
        }}</ion-button>
      <ion-button v-if="!addressDefault?.isDefault" :disabled="validateFields()" size="small" @click="handleAdd(true)">Set as default</ion-button>
    </ion-row>
  </ion-content>
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../backoffice/api/api";
import CountryFlag from "vue-country-flag";
import { countries } from "../../backoffice/utils/countries.js";
// import PhoneNumber from 'libphonenumber-js';
import { findNumbers } from "libphonenumber-js";
// import LibCodes from "zipcodes";
import PlacesAutoComplete from "@/backoffice/components/PlacesAutoComplete.vue";
import SameAddressList from "../../frontend/components/SameAddressList.vue";

export default {
  name: "AddressComponent",
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    addressType: {
      type: String,
      default: "billing", //shipping or billing
    },
    address: {
      type: Object,  // JSON with these fields: street1, street2, street3, state, county, country, zipcode
      default: null,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    /**
     * set if the display should be modal toolbar
     */
    isModal: {
      type: Boolean,
      default: false
    },

    /**
     * The userId from which this address will be attached
     */
    userId: {
      type: String,
      default: null
    },

    customer: {
      type: Object,
    },

    /**
     * Checks if the data should not be submitted to API
     */
    isDraft: { type: Boolean, default: false },
    customerInformation:{
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modelName: 'address',
      spinner: false,
      editButton: false,
      deleteButton: false,
      cancelButton: false,
      addressComplete: null,
      billingAddress: {
        firstName: "",
        email: "",
        phone: "",
        street1: "",
        street2: "",
        street3: "",
        additionalDetails: "",
        state: "",
        city: "",
        //country: "",
        countryFrom: {
          country: "US",
          name: "United States",
        },
        county: "",
        zip: "",
        addressType: "billing",
      },
      dataAddress: this.address,
      customerId: "",
      id: null,
      countries: countries,
      country: {
        country: "US",
        code: "+1",
        name: "United States",
      },
      countryCode: "",
      addressDefault: null,
      isDefault: true,
      sameAddress: false,
      selectedAddressType: 'billing',
      contentUpdate: Math.random(),
    };
  },

  mounted() {
    document.querySelectorAll('.accordion-header').forEach(header => {
      header.addEventListener('click', () => {
        const item = header.parentNode;
        const isOpen = item.classList.contains('active');
        document.querySelectorAll('.accordion-item').forEach(item => item.classList.remove('active'));
        if (!isOpen) {
          item.classList.add('active');
        }
      });
    });
    //this.addressAutocompleteInput();
  },

  components: {
    PlacesAutoComplete,
    CountryFlag,
    SameAddressList
  },

  computed: {
    //If is empty doesnt show checkbox
    addressesBilling() {
      let addressCount = this.$store?.state?.addresses?.filter((address) => address.type === 'billing');
      return addressCount;
    },
  },

  created: async function () {

    if (this.isModal) {
      const responses = await Api.fetchAddress('address' + '?customerId=' + this.userId);
      this.$store.commit('setAddresses', JSON.parse(JSON.stringify(responses.data)))
      if(this.customer){
        this.billingAddress.firstName = this.customer?.name?? this.customer?.Name;
        this.billingAddress.email = this.customer?.email?? this.customer?.EmailAddress;
        this.billingAddress.phone = this.customer?.phone?? this.customer?.Phone;
      }
      let addressCount = this.$store?.state?.addresses?.filter((address) => address.type === 'shipping');
      //console.log({addressCount })
    }

    this.customerId = (this.userId) ? this.userId : this.$store.state.customer._id;
    this.id = this.$route.params.addressId;
    this.addressDefault = this.$store.state.addresses.find(a => a._id === this.id)?? ""
    //console.log('this.customerId', this.customerId);
    if(this.customerInformation){
      this.billingAddress.firstName = this?.customerInformation?.name?? "";
      this.billingAddress.email = this?.customerInformation?.email?? "";
      this.billingAddress.phone = this?.customerInformation?.phone?? "";
    }

    //JQD: this should not be accessed like this
    // it should be submitted through props

    const data = this.$store.state.addresses.find(a => a._id === this.id);
    // console.log("store address: ",this.$store.state.addresses);
    // console.log("Address id: ",this.id);
    if (data) {
      this.billingAddress.firstName = data.firstName;
      this.billingAddress.email = data.email;
      this.billingAddress.phone = data.phone;
      this.billingAddress.street1 = data.street1;
      this.billingAddress.state = data.state;
      //this.billingAddress.countryFrom.country = data.country;
      this.billingAddress.countryFrom = this.countries?.find(countryFrom => countryFrom?.country === data?.country) ?? ""
      this.billingAddress.county = data.county;
      this.billingAddress.city = data.city;
      this.billingAddress.zip = data.zip;
      this.country = this.countries?.find(country => country?.code === data?.countryCode) ?? ""
    }


    this.billingAddress.addressType = this.addressType;
    // console.log(this.customerId)
  },
  methods: {
    addressAdded(address) {
      //console.log({address})
      if(!address) {
          return;
      }
      //console.log('addressAdded', address);
      if(this.selectedAddressType == 'billing') {
          //mapping this just for consistency but will create a new field to hold the values
          this.billingAddress.contactPerson = address.firstName;
          this.billingAddress.email = address.email;
          this.billingAddress.phone = address.phone;
          this.billingAddress.street1 = address.street1;
          this.billingAddress.street2 = address.street2;
          this.billingAddress.street3 = address.street3; 
          this.billingAddress.city = address.city;
          this.billingAddress.country = address.country;
          this.billingAddress.state = address.state;
          this.billingAddress.zip = address.zip;
          this.billingAddress.billingAddress = address;
          this.country = this.countries.find(country => country.code === address.countryCode)
      }else{
        this.billingAddress.contactPerson = address.firstName;
          this.billingAddress.email = address.email;
          this.billingAddress.phone = address.phone;
          this.billingAddress.street1 = address.street1;
          this.billingAddress.street2 = address.street2;
          this.billingAddress.street3 = address.street3; 
          this.billingAddress.city = address.city;
          this.billingAddress.country = address.country;
          this.billingAddress.state = address.state;
          this.billingAddress.zip = address.zip;
          this.billingAddress.billingAddress = address;
          this.country = this.countries.find(country => country.code === address.countryCode)
      }

      this.contentUpdate++;

      //handle update on shipping cost
      //put the code here
    },
    handleSelectedAddress(address) {
      this.addressAdded(address);
      this.$modal.hide('list-addresses');
    },
    showBillingAddresses(type) {
      //console.log({type})
      this.selectedAddressType = type;
      this.$modal.show('list-addresses');
      this.$forceUpdate(); 
    },
    goBack() {
      this.$router.push({ 
        name: 'Myaccount', 
        params: { 
          viewSelected: 'address', 
          segmentValueAddress: 'billing' 
        }
      });
    },
    validatePhone(phone) {
      if (!phone) {
        document
          .querySelector("#phone-validator")
          .style("border-bottom", "2px solid red");
        return "";
      }
      try {
        var nn = this.country.code + phone;
        const n = findNumbers(nn, { v2: true });
        if (n.length < 1) {
          document
            .querySelector("#phone-validator")
            .style("border-bottom", "2px solid red");
          return "";
        } else {
          this.phone = n[0].number.number;
          return n[0].number.nationalNumber;
        }
      } catch (err) {
        return false;
      }
    },

    validateEmail() {
      let emailRegex =
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      if (emailRegex.test(this.billingAddress.email) == false) {
        this.billingAddress.email = "";
        this.key++;
        return Swal.fire({
          icon: 'error',
          title: this.$t("frontend.home.notValidEmail"),
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$t("frontend.home.acept"),
          backdrop: false,
        });
      }
      return true;
    },

    placeChanged (place) {
      this.clearAddressFields();
      for (const addressComponent of place.address_components) {
        if (addressComponent.types.includes("locality")) {
          this.billingAddress.city = addressComponent.long_name;
        } else if (addressComponent.types.includes("administrative_area_level_2")) {
          this.billingAddress.county = addressComponent.long_name;
        } else if (addressComponent.types.includes("administrative_area_level_1")) {
          this.billingAddress.state = addressComponent.long_name;
        } else if (addressComponent.types.includes("postal_code")) {
          this.billingAddress.zip = addressComponent.long_name;
        } else if (addressComponent.types.includes("country")) {
          this.billingAddress.country = addressComponent.long_name;
        } else if (addressComponent.types.includes("street_number")) {
          this.billingAddress.street1 = addressComponent.long_name;
        } else if (addressComponent.types.includes("route")) {
          this.billingAddress.street1 += ' ' + addressComponent.long_name; // Concatenate route to addres1
        }
      }
    },

    clearAddressFields() {
      this.billingAddress.street1 = '';
      this.billingAddress.street2 = '';
      this.billingAddress.street3 = '';
      this.billingAddress.city = '';
      this.billingAddress.county = '';
      this.billingAddress.state = '';
      this.billingAddress.zip = '';
      this.billingAddress.country = '';
    },

    showEmptyFields() {
      const emptyFields = [];

      if (!this.billingAddress.firstName) emptyFields.push('Contact Person');

      if (!this.billingAddress.street1) emptyFields.push('Street 1');

      if (!this.billingAddress.state) emptyFields.push('State');

      if (!this.billingAddress.city) emptyFields.push('City');

      if (!this.billingAddress.countryFrom.country) emptyFields.push('Country');

      if (!this.billingAddress.county) emptyFields.push('County');

      if (!this.billingAddress.zip) emptyFields.push('ZIP Code');

      if (!this.billingAddress.phone) emptyFields.push('Phone');

      if (!this.billingAddress.email) emptyFields.push('Email');

      return emptyFields;
    },

    validateFields() {
      return (
        !this.billingAddress.firstName ||
        !this.billingAddress.street1 ||
        !this.billingAddress.state ||
        !this.billingAddress.city ||
        //!this.billingAddress.countryFrom.country ||
        //!this.billingAddress.county ||
        !this.billingAddress.zip ||
        !this.billingAddress.phone ||
        !this.billingAddress.email
      );
    },

    handleAdd: async function (setDefault) {
    // Verificar si los campos están vacíos
    const emptyFields = this.showEmptyFields();
    if (this.validateFields()) {
      // Al menos uno de los campos está vacío, imprimir en consola y no enviar los datos
      Swal.fire({
        icon: 'error',
        title: this.$t("backoffice.menu.titleInformationIncomplete"),
        text: this.$t("backoffice.menu.informationIncomplete") + `\n` + `Missing: ${emptyFields.join(', ')}`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.$t("frontend.home.acept"),
        backdrop: false,
      });
      return;
    } else {
      this.dataAddress = {
        //Billing
        "firstName": this.billingAddress.firstName,
        "email": this.billingAddress.email,
        "phone": this.billingAddress.phone,
        "street1": this.billingAddress.street1,
        "street2": this.billingAddress.street2,
        "street3": this.billingAddress.street3,
        "additionalDetails": this.billingAddress.additionalDetails,
        "city": this.billingAddress.city,
        "county": this.billingAddress.county,
        "state": this.billingAddress.state,
        "zip": this.billingAddress.zip,
        "country": this.billingAddress.countryFrom.country,
        "type": this.billingAddress.addressType,
        "customerId": this.customerId,
        "countryCode": this.country.code,
        "isDefault": setDefault,
      };

      if (this.isDraft) {
        this.$emit('address', this.dataAddress);
        return;
      }

      if (this.id) {
        // Si estoy editando
        this.dataAddress['_id'] = this.id;
        this.spinner = true;
        await Api.putInAddress(this.modelName, this.id, this.dataAddress)
          .then(response => {
            const index = this.$store.state.addresses.findIndex(a => a._id === this.id)
            if (index !== -1) this.$store.state.addresses[index] = this.dataAddress;

            this.$emit('address', this.dataAddress);

            Swal.fire({
              title: "Address edited successfully",
              icon: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            });

            this.resetForm();
            this.spinner = false;

            if (setDefault) {
              this.setAsDefault(response.data._id);
            }

            return response;
          })
          .catch(e => {
            console.error(e);
            this.spinner = false;
          })
      } else {
        // Si estoy agregando
        this.spinner = true;
        await Api.postIn(this.modelName, this.dataAddress)
          .then(response => {
            Swal.fire({
              title: `Address successfully added`,
              icon: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
            });
            
            this.$emit('address', this.dataAddress);

            if (this.isModal) {
              //dismiss
              this.$modal.hide('create-address');
              this.$store.state.addresses.push(response.data);
              this.resetForm();
              this.spinner = false;

              
              this.setAsDefault(response.data._id);
              return;
            }
            this.$store.state.addresses.push(response.data);
            this.resetForm();
            this.spinner = false;

            
            this.setAsDefault(response.data._id);
            

            return response;
          })
          .catch(e => {
            console.error(e);
            this.spinner = false;
          })
      }
    }
  },
 
  resetForm() {
    this.billingAddress.firstName = '';
    this.billingAddress.email = '';
    this.billingAddress.phone = '';
    this.billingAddress.street1 = '';
    this.billingAddress.street2 = '';
    this.billingAddress.street3 = '';
    this.billingAddress.additionalDetails = '';
    this.billingAddress.city = '';
    this.billingAddress.county = '';
    this.billingAddress.state = '';
    this.billingAddress.zip = '';
    this.billingAddress.countryFrom.country = '';
    this.billingAddress.addressType = '';
    this.customerId = null;
  },

  setAsDefault(address) {
    Api.setAddressAsDefault(address)
      .then(response => {
        //console.log(response);
        if (response) {
          Swal.fire({
            title: 'Dirección predeterminada actualizada',
            backdrop: false,
          });
        } else {
          Swal.fire({
            title: 'Algo salió mal al intentar actualizar la dirección predeterminada',
            backdrop: false,
          });
        }
      })
      .catch(error => {
        console.error('Error al establecer dirección predeterminada:', error);
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al establecer la dirección predeterminada.',
          icon: 'error',
          backdrop: false,
        });
      });
  },

    handleCancel() {
      if(!this.isModal){
        this.$router.push({
          name: 'Myaccount'
        });
      }else{
        this.$modal.hide('create-address')
      }

    },
  },
}
</script>

<style>
.accordion {
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.accordion-icon {
  font-size: 1.5em;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-item.active .accordion-content {
  max-height: 1100px;
  transition: max-height 0.3s ease-in;
}
</style>