<template>
    <div>
        <ion-row class="ion-padding-top">
            <template v-if="entity == 'Staff'">
                <ion-col size="12" size-md="4" size-sm="12" size-xs="12">
                    <ion-item>
                        <ion-label>Occupation</ion-label>
                        <ion-select @ionChange="model.occupation = $event.target.value" v-bind:value="model.occupation">
                            <ion-select-option v-for="occupation in occupations" v-bind:key="occupation.Id"
                                v-bind:value="occupation._id">
                                {{ occupation.Name }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>
                <ion-col size="12" size-md="4" size-sm="12" size-xs="12">
                    <ion-item>
                        <ion-label>Role</ion-label>
                        <ion-select @ionChange="model.role = $event.target.value" v-bind:value="model.role">
                            <ion-select-option v-for="(rol, index) in allRoles" :key="index" :value="rol._id">
                                {{ rol.Name }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>
            </template>

            <template v-else-if="enableDates">
                <ion-col class="class-col" size="6" size-md="3" size-sm="6" size-xs="6">
                    <span style="color: red">*</span><ion-label color="medium">{{ $t('backoffice.form.fields.DateFrom') }}</ion-label>
                    <ion-datetime class="input-stylee" name="dateFrom" @ionChange="model.dateFrom = $event.target.value"
                        v-bind:value="model.dateFrom" :placeholder="$t('backoffice.form.titles.dateFromSelect')"
                        display-format="YYYY-MM-DD HH:mm">
                    </ion-datetime>
                </ion-col>
                <ion-col class="class-col" size="6" size-md="3" size-sm="6" size-xs="6">
                    <span style="color: red">*</span><ion-label color="medium">{{ $t('backoffice.form.fields.DateTo') }}</ion-label>
                    <ion-datetime class="input-stylee" name="dateFrom" @ionChange="model.dateTo = $event.target.value"
                        v-bind:value="model.dateTo" :placeholder="$t('backoffice.form.titles.dateToSelect')"
                        display-format="YYYY-MM-DD HH:mm">
                    </ion-datetime>
                </ion-col>
            </template>

            <ion-col size="6" size-md="4" size-sm="6" size-xs="6" class="ion-text-start align-bottom">
                <!-- <ion-button color="primary filterBtn" size="default" @click="filterData(true)"
                    :disabled="spinner">Filter
                </ion-button> -->
                <ion-button shape="round" class="button-entry" fill="outline" color="primary" @click="filterData(true)">
                    <span class="iconify" data-icon="material-symbols:search"></span>
                    <ion-label>Filter</ion-label>
                </ion-button>
            </ion-col>
        </ion-row>
    </div>
</template>
     
<script>

import { DateTime } from 'luxon';

export default {
    props: {
        entity: {
            type: String,
            required: true
        },
        // fields: { type: Array, default: () => ['First Name', 'Last Name', 'Phone', 'Email', 'Notes', 'Days Since'] },
    },
    data() {
        return {
            spinner: false,
            occupations: [],
            allRoles: [],
            enableDates: false,
            model: {
                occupation: '',
                role: '',
                dateFrom: '',
                dateTo: '',
            }
        };
    },
    created: function () {
        this.occupations = this.$store.state.backConfig.occupation;
        this.allRoles = this.$store.state.backConfig.rol;
        //console.log('Entity received.....', this.entity);
        this.enableDates = ['Subscriber', 'Reservation', 'Order'].includes(this.entity);

        if (this.enableDates) {
            this.model.dateFrom = DateTime.now().startOf('month');
            this.model.dateTo = DateTime.now().endOf('month');
        }
    },
    mounted() {
    },
    methods: {
        filterData() {
            this.$parent.doRefresh(this.getNonEmptyObj());
        },

        getNonEmptyObj() {
            const obj = this.model;
            const newObj = {};
            for (const key in obj) {
                if (obj[key]) {
                    newObj[key] = obj[key];
                }
            }
            if (this.enableDates) {
                newObj.dateFrom = new Date(newObj.dateFrom).toISOString();
                newObj.dateTo = new Date(newObj.dateTo).toISOString();
            }
            //console.log('testing the non empty object', newObj);
            return newObj;
        }
    },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .button-entry {
    width: 100%; /* O cualquier porcentaje adecuado */
    font-size: 12px;
    margin-top:10px
    
  }
}
.align-bottom {
  align-self: flex-end;
}
.icon-primary {
  color: var(--ion-color-primary);
}

.date-class{
    background: var(--ion-color-primary);
    border: 1px solid var(--ion-color-secondary);
    border-radius: 10px;
    color: #fff;
}
.input-stylee {
  border: 1px solid #ccc;
  border-radius: 14px;
  width: 100%;
  padding-left: 10px !important;
}
</style>