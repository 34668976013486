<template>
  <!-- <div :class="list || isOrder ? 'horizontal-pop' : 'vertical-pop'"> -->
    <div :class="(list || isOrder || isAssetPickup || !isOrder) ? 'horizontal-pop' : 'vertical-pop'">

    <!-- class="hide-md" -->

    <!-- Se oculta el lapiz de editar si ya está ordered -->
    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="edit && !isOrder"
      v-tooltip="'Edit'" @click="editElement()">
      <ion-icon slot="icon-only" icon="create"></ion-icon>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="isAssetPickup && !isOrder"
      v-tooltip="'Print'" @click="printAsset()">
      <ion-icon slot="icon-only" icon="print"></ion-icon>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="isAssetPickup && !isOrder"
      v-tooltip="'Preview'" @click="previewAsset()">
      <ion-icon slot="icon-only" icon="eye"></ion-icon>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="showAddButtons"
      v-tooltip="'Preview'" @click="previewCustomer()">
      <ion-icon class="iconify-2" slot="icon-only" icon="eye"></ion-icon>
    </ion-button>

    <ion-button v-if="isAssetPickup && !isOrder || isEvent" fill="clear" :color="list ? 'primary' : 'light'"
        :class="list ? '' : 'pop-btn'" v-tooltip="$t('backoffice.titles.sendByEmailAndSMS')" @click="sendEmail()">
        <!-- <ion-icon slot="icon-only" icon="mail"></ion-icon> -->
        <span class="iconify iconify-2" data-icon="streamline:send-email" data-inline="false"></span>
      </ion-button>
    
    <!-- <ion-button v-if="isEvent" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="'Bulk Send'" @click="bulkSendEmail()">
      <span class="iconify" data-icon="clarity:employee-group-line" data-inline="false"></span>
    </ion-button> -->
      
    <ion-button v-if="isAssetPickup && !isOrder" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="'Share Asset'" @click="shareAsset()">
      <ion-icon slot="icon-only" icon="share"></ion-icon>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="showOrderTable"
      @click="showOrderTableM()">
      <ion-icon slot="icon-only" icon="cart"></ion-icon>
    </ion-button>


    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="see"
      v-tooltip="'See'" @click="editElement()">
      <ion-icon slot="icon-only" icon="eye"></ion-icon>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-tooltip="'Delete'"
      v-if="(remove && !isOrder) || (remove && isOrder && element.State === 0 && !element.isTicket)"
      @click="deleteElement()">
      <ion-icon slot="icon-only" icon="trash"></ion-icon>
    </ion-button>

    <ion-button v-if="showDuplicateItem && !isOrder" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="'Duplicate'" @click="duplicateProduct()">
      <ion-icon slot="icon-only" icon="copy"></ion-icon>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-if="showAddButton && !isOrder" v-tooltip="'Create Invoice'" @click="createInvoice()">
      <span class="iconify iconify-2" data-icon="gridicons:create" data-inline="false"></span>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-if="showAddButton && !isOrder" v-tooltip="'Create Quote'" @click="createQuote()">
      <span class="iconify iconify-2" data-icon="material-symbols:request-quote" data-inline="false"></span>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-if="showAddButtons && !isOrder" v-tooltip="'Create Invoice'" @click="addInvoice()">
      <span class="iconify iconify-2" data-icon="gridicons:create" data-inline="false"></span>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-if="showAddButtons && !isOrder" v-tooltip="'Create Quote'" @click="addQuote()">
      <span class="iconify iconify-2" data-icon="material-symbols:request-quote" data-inline="false"></span>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-if="showAddButtonss && !isOrder" v-tooltip="'Create Order'" @click="addOrder()">
      <span class="iconify iconify-2" data-icon="lets-icons:order-fill" data-inline="false"></span>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-if="showAddButtonss && !isOrder" v-tooltip="'Create New Password'" @click="newPassword()">
      <span class="iconify iconify-2" data-icon="fluent:password-clock-48-filled" data-inline="false"></span>
    </ion-button>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="showQrTable"
      @click="showQrTableM()">
      <ion-icon slot="icon-only" icon="ios-qr-scanner"></ion-icon>
    </ion-button>

    <ion-button v-if="isWorkSheet" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="$t('backoffice.titles.sendByEmailAndSMS')" @click="sendEmail()">
      <!-- <ion-icon slot="icon-only" icon="mail"></ion-icon> -->
      <span class="iconify" data-icon="streamline:send-email" data-inline="false"></span>
    </ion-button>
    <ion-button v-if="isShowPaymentResponse" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="'Show Payment Response'" @click="showPaymentResponse()">
      <ion-icon slot="icon-only" icon="card"></ion-icon>
    </ion-button>
    <ion-button v-if="isShowCommunicationResponse" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="'Show Communication Response'" @click="showCommunicationResponse()">
      <span class="iconify iconify-2" data-icon="tabler:report-search" data-inline="false"></span>
    </ion-button>
    <ion-button v-if="isSubscription && element.isRecurring" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="element.status === 'active' ? 'Stop Subscription' : 'Restart Subscription'" @click="pauseReStartSubscription()">
      <ion-icon v-if="element.status === 'active'" slot="icon-only" icon="close"></ion-icon>
      <ion-icon v-else name="refresh" slot="icon-only"></ion-icon>

    </ion-button>
    <ion-button v-if="isStaff" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
      v-tooltip="'Open Worksheet'" @click="openWorksheet()">
      <span class="iconify iconify-2" data-icon="bx:bx-spreadsheet" data-inline="false"></span>
    </ion-button>

    <div v-if="isOrder">
      <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
        v-if="add && !element.isQuote && !element.isInvoice" v-tooltip="'Reorder'" @click="reOrder()">
        <ion-icon slot="icon-only" icon="cart">ssss</ion-icon>
      </ion-button>

      <ion-button v-if="add && !element.isQuote && !element.isInvoice" fill="clear" :color="list ? 'primary' : 'light'"
        :class="list ? '' : 'pop-btn'" v-tooltip="$t('backoffice.titles.sendByEmailAndSMS')" @click="sendEmail()">
        <!-- <ion-icon slot="icon-only" icon="mail"></ion-icon> -->
        <span class="iconify iconify-2" data-icon="streamline:send-email" data-inline="false"></span>
      </ion-button>

      <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
        v-if="element.OrderType === 'Curbside'" v-tooltip="'Curbside Details'" @click="curbsideDetail()">
        <ion-icon slot="icon-only" icon="car"></ion-icon>
      </ion-button>

      <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
        v-if="element.State === 0 && !element.isTicket && !element.isQuote" v-tooltip="'Qr Payment'"
        @click="openPayment()">
        <ion-icon slot="icon-only" icon="md-qr-scanner"></ion-icon>
      </ion-button>

      <!-- <ion-button
                    fill="clear"
                  :color="list? 'primary': 'light'"  
                    :class="list? '' : 'pop-btn'"
                    v-if="![0,6,5].includes(element.States)  && !element.isQuote && !element.isInvoice"
                    v-tooltip="'Cancel Order'"
                    @click="cancelOrder()"          
                  >              
                  <ion-icon slot="icon-only" icon="close"></ion-icon>
                </ion-button> -->

      <div v-if="element.OrderType === 'Delivery'" style="    display: contents;">
        <div v-if="delivery && $store.state.user.IsDriver">

          <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
            v-if="!element.IsAccept" v-tooltip="'Accept for Delivery'" @click="acceptOrder()">
            <ion-icon slot="icon-only" icon="checkmark"></ion-icon>
          </ion-button>

          <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
            v-if="! element.IsAccept" v-tooltip="'Reject'" @click="notAcceptOrder()">
            <ion-icon slot="icon-only" icon="close"></ion-icon>
          </ion-button>

          <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
            v-if="element.IsAccept" @click="writeLocalization()">
            <ion-icon slot="icon-only" icon="navigate"></ion-icon>
          </ion-button>

        </div>

        <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-if="element.State == 4 && element.IsAccept" v-tooltip="'Show Driver Location'"
          @click="showDeliveringLocation()">
          <ion-icon slot="icon-only" icon="locate"></ion-icon>
        </ion-button>

        <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-if="element.State === 5" v-tooltip="'Show Travel'" @click="showTravel()">
          <ion-icon slot="icon-only" icon="recording"></ion-icon>
        </ion-button>


      </div>

      <div v-if="element.isQuote || element.isInvoice" style="display: contents;">
        <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-tooltip="'Print'"
          @click="printOrder()">
          <ion-icon slot="icon-only" icon="print"></ion-icon>
        </ion-button>

        <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-tooltip="'Preview'" @click="previewQuote()">
          <ion-icon slot="icon-only" icon="eye"></ion-icon>
        </ion-button>

        <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-tooltip="$t('backoffice.titles.sendByEmailAndSMS')" @click="sendEmail()">
          <!-- <ion-icon slot="icon-only" icon="mail"></ion-icon> -->
          <span class="iconify iconify-2" data-icon="streamline:send-email" data-inline="false"></span>
        </ion-button>

        <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-tooltip="'Duplicate'" @click="duplicateOrder()">
          <ion-icon slot="icon-only" icon="copy"></ion-icon>
        </ion-button>

        <ion-button v-if="order" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-tooltip="'Share Order'" @click="shareOrder()">
          <ion-icon slot="icon-only" icon="share"></ion-icon>
        </ion-button>

        <ion-button v-if="quote" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-tooltip="'Share Quote'" @click="shareQuote()">
          <ion-icon slot="icon-only" icon="share"></ion-icon>
        </ion-button>

        <ion-button v-if="invoice" fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'"
          v-tooltip="'Share Invoice'" @click="shareInvoice()">
          <ion-icon slot="icon-only" icon="share"></ion-icon>
        </ion-button>
      </div>


    </div>

    <div v-if="isAttendance" style="display: contents;">
      <ion-button fill="clear" :color="list? 'primary': 'light'" :class="list? '' : 'pop-btn'" v-tooltip="'Print'"
          @click="printOrder()"
      >
        <ion-icon slot="icon-only" icon="print"></ion-icon>
      </ion-button>

      <ion-button fill="clear" :color="list? 'primary': 'light'" :class="list? '' : 'pop-btn'"
                  v-tooltip="''" @click="sendEmail()" >
        <!-- <ion-icon slot="icon-only" icon="mail"></ion-icon> -->
        <span class="iconify iconify-2" data-icon="streamline:send-email" data-inline="false"></span>

      </ion-button>
    </div>

    <ion-button fill="clear" :color="list ? 'primary' : 'light'" :class="list ? '' : 'pop-btn'" v-if="showClock"
      @click="showTime()">
      <ion-icon slot="icon-only" icon="time"></ion-icon>
    </ion-button>


  </div>

</template>

<script>
export default {
  name: "Popover",
  props: {
    showAddButton: {
      type: Boolean,
      default: false,
    },
    showDuplicateItem:{
      type: Boolean,
      default: false,
    } ,
    showAddButtons: {
      type: Boolean,
      default: false,
    },
    showAddButtonss: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    see: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'xx',
    },
    showQrTable: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
      default: () => { }
    },
    showOrderTable: {
      type: Boolean,
      default: false,
    },
    isOrder: {
      type: Boolean,
      default: false
    },
    isAssetPickup: {
      type: Boolean,
      default: false
    },
     isAttendance: {
      type: Boolean,
      default: false
    },
   isWorkSheet:{
      type: Boolean,
      default: false
    },
    isShowPaymentResponse:{
      type: Boolean,
      default: false
    },
    isShowCommunicationResponse:{
      type: Boolean,
      default: false
    },
    isSubscription:{
      type: Boolean,
      default: false
    },
    // isSubscription:{
    //   type: Object,
    //   default: false
    // },
    isEvent:{
      type: Boolean,
      default: false
    },
    isStaff: {
      type: Boolean,
      default: false
    },
    order: {
      type: Boolean,
      default: false
    },
    quote: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: Boolean,
      default: false
    },
    delivery: {
      type: Boolean,
      default: false,
    },
    notAccepted: {
      type: Boolean,
      default: false,
    },
    showClock: {
      type: Boolean,
      default: false,
    },
    print: {
      type: Boolean,
      default: false,
    },
    reservationCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    createInvoice() {
      console.log('Popover', 'createInvoice', this.id);
      this.$emit('createInvoice', this.id);
    },
    createQuote() {
      this.$emit('createQuote', this.id);
    },
    addInvoice() {
      //console.log('Popover', 'addInvoice', this.id);
      this.$emit('addInvoice', this.id);
    },
    addQuote() {
      this.$emit('addQuote', this.id);
    },
    newPassword() {
      this.$emit('newPassword', this.id);
    },
    addOrder() {
      this.$emit('addOrder', this.id);
    },
    editElement() {
      this.$emit("editElement", this.id);
    },
    deleteElement() {
      this.$emit("deleteElement", this.id);
    },
    openPayment() {
      this.$emit("openPayment", this.element);
    },
    showOrderTableM() {
      this.$emit("showOrderTableM", this.element.Name);
    },
    showQrTableM() {
      this.$emit("showQrTableM", this.element.Seats);
    },
    reOrder() {
      this.$emit("reOrder", this.element);
    },

    curbsideDetail() {
      this.$emit('curbsideDetail', this.element);
    },

    cancelOrder() {
      this.$emit('cancelOrder', this.element);
    },

    printOrder() {
      this.$emit('printOrder', this.element);
    },
    printAsset(){
      this.$emit('printAsset', this.element);
    },
    previewAsset(){
      this.$emit('previewAsset', this.element);
    },
    previewCustomer(){
      this.$emit('previewCustomer', this.element);
    },
    previewQuote() {
      this.$emit('previewQuote', this.element);
    },
    sendEmail() {
      this.$emit('sendEmail', this.element);
    },
    bulkSendEmail() {
      this.$emit('bulkSendEmail', this.element);
    },
    showPaymentResponse() {
      this.$emit('showPaymentResponse', this.element);
    },
    showCommunicationResponse() {
      this.$emit('showCommunicationResponse', this.element);
    },
    pauseReStartSubscription() {
      this.$emit('pauseReStartSubscription', this.element);
    },
    openWorksheet() {
      this.$emit('openWorksheet', this.element);
    },
    duplicateOrder() {
      this.$emit('duplicateOrder', this.element);
    },
    duplicateProduct() {
      this.$emit('duplicateProduct', this.element);
    },
    shareQuote() {
      this.$emit('shareQuote', this.element);
    },
    shareInvoice() {
      this.$emit('shareInvoice', this.element);
    },
    shareOrder() {
      this.$emit('shareOrder', this.element);
    },
    shareAsset() {
      this.$emit('shareAsset', this.element);
    },
    acceptOrder() {
      this.$emit('acceptOrder', this.element);
    },

    notAcceptOrder() {
      this.$emit('notAcceptOrder', this.element);
    },

    writeLocalization() {
      this.$emit('writeLocalization', this.element);
    },

    showDeliveringLocation() {
      this.$emit('showDeliveringLocation', this.element);
    },

    showTravel() {
      this.$emit('showTravel', this.element);
    },

     showTime() {
      this.$emit("showTime", this.element._id, this.element.Email);
    },



  },
}
</script>

<style>
/* .pop-btn {
  --padding-start: 0;
  --padding-end: 0;
} */

.pop-btn {
  --padding-start: 0;
  --padding-end: 0px;
  padding: auto;
  margin: auto;
}

.vertical-pop {
  position: relative;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
}

.horizontal-pop {
  position: absolute;
  top: 5px;
  /* right: 5px; */
  right: -5px;
  display: flex;
  flex-direction: row;
}
</style>
