<template>
  <div id="customer" class="page">
    <modal name="new-password-modal" width="70%" height="90%">
      <ion-header>
        <ion-toolbar>
          <ion-title>New Password</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="$modal.hide('new-password-modal')">❌</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <div class="ion-padding" style="height: 90%; overflow: scroll">
        <create-new-password :id="customerId"/>
      </div>
    </modal>

    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="primary" shape="round" size="large" @click="showImportModal()">Import Customers</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <listView 
      :title="$t('backoffice.titles.customers')" 
      :type="'Customer'" :cardTitle="'Customer'" 
      :filter="filterCustomers" 
      :elements="customers"
      :viewSelected="'Admin'" 
      :add="hasPermission('canCreateCustomer')" 
      :edit="hasPermission('canEditCustomer')" 
      :remove="hasPermission('canDeleteCustomer')"
      :showAddButtons="true" 
      :showAddButtonss="true" 
      @handleInput="handleInput" 
      @handleAddClick="addCustomer"
      @editElement="editCustomer" 
      @deleteElement="deleteCustomer" 
      @addInvoice="addInvoice" 
      @addQuote="addQuote" 
      @addOrder="addOrder" 
      @newPassword="newPassword" 
      @previewCustomer="previewCustomer"
      :bulk="true"
      :isModal="isModal"
      ></listView>
      
      <modal style="z-index: 9999;" name="preview" width="80%" height="80%">
        <ion-header>
          <ion-toolbar>
            <ion-title>Interaction</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="$modal.hide('preview')">❌</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <div class="ion-padding" style="height: 90%; overflow: scroll;">
          <CustomerForm @dismiss-modal="handleDismissedCustomerModal" :showInteractionDetails="true" :noShow="true" :isCustomerModal="true" :fromInvoice="true" :customerId="customerId"></CustomerForm>
        </div>
      </modal>

      <modal style="z-index: 9999;" name="preview-history" width="80%" height="80%">
        <ion-header>
          <ion-toolbar>
            <ion-title>History Interaction</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="$modal.hide('preview-history')">❌</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <div v-if="customerInteractions.length > 0" class="ion-padding" style="height: 90%; overflow: scroll;">
          <ion-item v-for="(interaction, index) in customerInteractions" :key="index" class="interaction-item">
            <ion-label>
              <h3>{{ interaction.lastContacted }}</h3>
              <p><strong>Note:</strong> {{ interaction.notesLastContacted }}</p>
              <p><strong>Next Follow-up:</strong> {{ interaction.nextFollowUp }}</p>
              <p><strong>Note:</strong> {{ interaction.notesNextFollowUp }}</p>
              <p><strong>Preferred Contact Time:</strong> {{ interaction.preferredContactTime }} - {{ interaction.preferredContactToTime }}</p>
              <p><strong>Note:</strong> {{ interaction.notesContactToTime }}</p>
            </ion-label>
          </ion-item>
        </div>
        <div v-else>
          <ion-label>No history</ion-label>
        </div>

      </modal>
      <InvoicePreview ref="previewRef" />
  </div>
</template>

<script>

import { Api } from '../api/api.js';
import listView from "../components/ListView";
import Swal from "sweetalert2";
import CreateNewPassword from "./CreateNewPassword.vue";
import { EventBus } from "../../frontend/event-bus";
import InvoicePreview from "@/backoffice/components/InvoicePreview.vue";
import { Commons } from '../../frontend/commons'
import CustomerForm from "./CustomerForm.vue";

export default {

  name: 'customer',
  created: function () {
    //console.log('this.$route.query.invoice', this.$route.params.invoice);
    //console.log('Customer List View created');
    this.fetchCustomers();
    EventBus.$on('refreshAfterDelete', this.doRefresh);

  },
  props: {
    isModal: { type: Boolean, default: false },
  },
  data() {
    return {
      modelName: 'Customer',
      customers: [],
      filterCustomers: [],
      paginate: ['languages'],
      spinner: false,
      keyList: 0,
      customerId: null,
      previewHtml: '',
      customerInteractions: []
    }
  },
  components: {
    listView,
    CreateNewPassword,
    InvoicePreview,
    CustomerForm
  },
  methods: {

    handleDismissedCustomerModal() {
      this.$modal.hide('preview');
    },

    previewCustomer: function(customer, returnHtml = false) {
      const buttons = `
      <ion-chip id='interaction-btn' data-id="${customer._id}" v-tooltip="'Interaction'"><span class='iconify' data-icon='material-symbols:interactive-space-outline' data-inline='false'></span></ion-chip>
      <ion-chip id='history-btn' data-id="${customer._id}" v-tooltip'History'><span class='iconify' data-icon='icon-park-outline:history' data-inline='false'></span></ion-chip>
      `;
      console.log({customer})
      this.previewHtml = Commons.htmlToCustomer(customer, buttons, true);
      if (returnHtml) {
        return this.previewHtml;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          const btn = document.querySelector("#interaction-btn");
          const btnHistory = document.querySelector("#history-btn");
          if (btn) {
            const customerId = btn.getAttribute('data-id');
            btn.addEventListener("click", () => this.customerInteraction(customerId));
          } 
          if(btnHistory){
            const customerId = btnHistory.getAttribute('data-id');
            btnHistory.addEventListener("click", () => this.customerHistoryInteraction(customerId));
          } else {
            console.log("No se encontró el botón en el DOM");
          }
        }, 100);
      });

      this.$refs.previewRef.init('customer', this.previewHtml,  customer)
      //this.$modal.show('preview');
    },

    customerInteraction(id){
      console.log(id)
      this.customerId = id;
      this.$modal.show('preview');
    },

    customerHistoryInteraction(id){
      this.customerId = id;
      const data = this.$store.state.backConfig.allCustomer.find(c => c._id === id)
      this.customerInteractions = data?.interactions?? []
      this.$modal.show('preview-history');
    },

    showImportModal() {
      //console.log('JQD', 'showImportModal');
      const timeComponent = () => import("../components/ImportExport");
      return this.$ionic.modalController
        .create({
          component: timeComponent,
          cssClass: "my-custom-class",
          componentProps: {
            data: {},
            propsData: {
              entity: 'customer',
              fields: ['First Name', 'Last Name', 'Phone', 'Email', 'Notes', 'Days Since'],
            },
          },
        })
        .then((modal) => {
          modal.present();
          modal.onDidDismiss().then((data) => {
            //console.log(data);
          });
        });
    },

    async doRefresh() {
      //console.log('do refresh');
      this.spinner = true;
      await Api.getCustomersByRestaurant(this.modelName).then(response => {
        // Filtrar solo por invoices
        // this.customers = this.$store.state.backConfig.allCustomer = response.data;    
        // this.filterCustomers = this.customers;   
        this.customers = response?.data;
        this.$store.state.backConfig.allCustomer = this.customers;
        this.filterCustomers = this.customers 
        this.spinner = false;
        this.keyList++;
      }).catch(e => {
        e
      });
    },

    beforeDestroy() {
      EventBus.$off('refreshAfterDelete', this.doRefresh);
    },

    ListViewData(option, count) {
      if (count === 1) return null;
      if (count === 2) return option.Name;
      if (count === 3) return null;
      if (count === 4) return option.EmailAddress;
      if (count === 5) return option.Phone;

    },

    ifErrorOccured(action) {
      return this.$ionic.alertController.create({
        title: this.$t('backoffice.list.messages.connectionError'),
        message: this.$t('backoffice.list.messages.connectionErrorMessage'),
        buttons: [
          {
            text: this.$t('backoffice.list.messages.buttons.goToAdministration'),
            handler: () => {
              this.$router.push({
                name: 'ControlPanel',
              });
            }
          },
          {
            text: this.$t('backoffice.list.messages.buttons.retry'),
            handler: () => {
              action();
            }
          }
        ]
      })
        .then(a => a.present());
    },

    handleInput(value) {

      this.filterCustomers = this.customers
      const query = value.toLowerCase();

      requestAnimationFrame(() => {
        //match the input against name and email address
        let cat2 = this.customers.filter(item => {
          return item?.Name?.toLowerCase().indexOf(query) > -1 || item?.EmailAddress?.toLowerCase().indexOf(query) > -1 || item?.tags?.toLowerCase().indexOf(query) > -1
        })

        if (cat2.length > 0) {
          this.filterCustomers = cat2
        } else {
          this.filterCustomers = this.customers
        }
      });
    },

    hasPermission(permission) {

      let res = false;
      if (this.$store.state.authenticated) {
        let roles = this.$store.state.roles;
        for (let index = 0; index < roles.length; index++) {
          switch (permission) {
            case 'canCreateCustomer':
              res = roles[index].canCreateCustomer;
              break;
            case 'canEditCustomer':
              res = roles[index].canEditCustomer;
              break;
            case 'canDeleteCustomer':
              res = roles[index].canDeleteCustomer;
              break;
            default:
              break;
          }
          if (res) {
            return res;
          }
        }
      }
      return res;
    },

    ShowMessage(type, message, topic = '') {
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: type,
          subHeader: topic,
          message: message,
          buttons: [this.$t('backoffice.form.messages.buttons.ok')],
        })
        .then(a => a.present())
    },

    showToastMessage(message, tColor) {
      return this.$ionic.toastController.create({
        color: tColor,
        position: 'top',
        duration: 3000,
        message: message,
        showCloseButton: false
      }).then(a => a.present())
    },

    /****** CRUD category methods ******/
    fetchCustomers: async function () {
      //this.customers = this.$store.state.backConfig.allCustomer;
      //this.filterCustomers = this.customers
      // const response = await Api.getCustomersByRestaurant(this.modelName);
      // this.customers = response?.data;
      // this.$store.state.backConfig.allCustomer = this.customers;
      // this.filterCustomers = this.customers
      this.doRefresh();
    },

    addInvoice: function (id) {
      //console.log('Customer addInvoice', id);
      this.$router.push({
        name: 'InvoiceDetails',
        params: {
          customerId: id,
        }
      });
    },

    addQuote: function (id) {
      this.$router.push({
        name: 'QuoteDetails',
        params: {
          customerId: id,
        }
      });
    },

    newPassword: function (id) {
      this.customerId = id;
      this.$modal.show('new-password-modal');
      // this.$router.push({
      //   name: 'NewPassword',
      //   params: {
      //     customerId: id,
      //   }
      // });
    },

    addOrder: function (id) {
      this.$router.push({
        name: 'Home',
        params: {
          url: this.$store.state.restaurantActive.restaurantUrl,
          customerId: id,
        }
      });
    },

    editCustomer: function (id) {
      this.$router.push({
        name: 'CustomerForm',
        params: { customerId: id, invoice: this.$route.params.invoice }
        //params: { customerId: id }
      });
    },

    addCustomer: function () {
      this.$router.push({
        name: 'CustomerForm',
        params: { invoice: this.$route.params.invoice }
      });
    },

    deleteCustomer: async function (id) {

      return this.$ionic.alertController.create({
        title: this.$t('backoffice.list.messages.confirmDelete'),
        message: this.$t('backoffice.list.messages.deleteCustomer'),
        buttons: [
          {
            text: this.$t('backoffice.list.messages.buttons.cancel'),
            role: 'cancel',
            handler: () => {
            }
          },
          {
            text: this.$t('backoffice.list.messages.buttons.delete'),
            handler: async () => {

              this.spinner = true;
              await Api.deleteById(this.modelName, id)
                .then(response => {
                  const index = this.$store.state.backConfig.allCustomer.findIndex(c => c.id === id)
                  if (index !== -1) this.$store.state.backConfig.allCustomer.splice(index, 1);
                  Swal.fire({
                    title: this.$t('backoffice.list.messages.messageDeleteSuccessCustomer'),
                    icon: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  //this.showToastMessage(this.$t('backoffice.list.messages.messageDeleteSuccessCustomer'), "success");
                  this.fetchCustomers();
                  this.spinner = false;
                  return response;
                })
                .catch(e => {
                  e;
                  this.ifErrorOccured(mess => {
                    this.deleteCustomer(id)
                    this.spinner = false;
                    return mess
                  });
                })

            }
          }
        ]
      })
        .then(a => a.present());

    },
  },

}

</script>

<style>
.menu-col-3 {
  flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  text-align: left;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.paginate-list {
  width: 159px;
  margin: 0 auto;
  text-align: left;

}

.paginate-list li {
  display: block;
}

.paginate-list li:before {
  content: '⚬ ';
  font-weight: bold;
  color: slategray;
}

.paginate-links.items {
  user-select: none;
}

a {
  cursor: pointer;
}

li.active a {
  font-weight: bold;
}

li.next:before {
  content: ' | ';
  margin-right: 13px;
  color: #ddd;
}

li.disabled a {
  color: #ccc;
  cursor: no-drop;
}

.prev,
.next {
  font-size: 40px;
}

@media only screen and (min-width : 1024px) {

  .screen {
    padding-left: 10%;
    padding-right: 10%;
    border: #edf1ee solid 1px;
    margin-right: 10%;
    margin-left: 10%;
    /* border-radius: 25px; */
  }

}
.interaction-item {
    margin-bottom: 15px; /* Separación entre elementos */
    padding: 10px;
    background-color: #f9f9f9; /* Fondo suave para cada item */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
  }

  .interaction-item h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }

  .interaction-item p {
    margin: 5px 0;
    color: #555;
  }

  .interaction-item strong {
    color: #333;
  }
</style>