import { i18n } from '@/plugins/i18n'

export const genderOptions = [
    { label: i18n.t('frontend.genderOptions.male'), value: "Male" },
    { label: i18n.t('frontend.genderOptions.female'), value: "Female" },
    { label: i18n.t('frontend.genderOptions.nonBinary'), value: "Non-Binary" },
    { label: i18n.t('frontend.genderOptions.transgenderMale'), value: "Transgender Male" },
    { label: i18n.t('frontend.genderOptions.transgenderFemale'), value: "Transgender Female" },
    { label: i18n.t('frontend.genderOptions.genderqueer'), value: "Genderqueer" },
    { label: i18n.t('frontend.genderOptions.genderfluid'), value: "Genderfluid" },
    { label: i18n.t('frontend.genderOptions.agender'), value: "Agender" },
    { label: i18n.t('frontend.genderOptions.twoSpirit'), value: "Two-Spirit" },
    { label: i18n.t('frontend.genderOptions.bigender'), value: "Bigender" },
    { label: i18n.t('frontend.genderOptions.demiboy'), value: "Demiboy" },
    { label: i18n.t('frontend.genderOptions.demigirl'), value: "Demigirl" },
    { label: i18n.t('frontend.genderOptions.androgynous'), value: "Androgynous" },
    { label: i18n.t('frontend.genderOptions.intersex'), value: "Intersex" },
    { label: i18n.t('frontend.genderOptions.neutrois'), value: "Neutrois" },
    { label: i18n.t('frontend.genderOptions.pangender'), value: "Pangender" },
    { label: i18n.t('frontend.genderOptions.polygender'), value: "Polygender" },
    { label: i18n.t('frontend.genderOptions.preferNotToSay'), value: "Prefer Not to Say" },
    { label: i18n.t('frontend.genderOptions.other'), value: "Other" },
   ];