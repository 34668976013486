<template>
  <div style="width: 100%;">
    <input class="input-style" @input="address = $event.target.value" :value="address = initialAddress" ref="autocomplete" type="text" />
  </div>
</template>

<script>
export default {
  props: {
    initialAddress: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      address: this.initialAddress,
    };
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    async initializeMap() {
      await new Promise((resolve) => setTimeout(resolve, 200));

      try {
        const input = this.$refs.autocomplete;
        if (!input) {
          throw new Error(
            "The input element for Google Places Autocomplete was not found."
          );
        }

        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.formatted_address) {
            console.error("No address available for the selected place.");
            return;
          }
          this.address = place.formatted_address;
          this.$emit("place_changed", place);
        });
      } catch (error) {
        console.error("Failed to initialize Google Maps Places Autocomplete:", error);
      }
    },
  },
};
</script>

<style scoped>

</style>