<template>
  <div class="page">
    <ion-header translucent class="fixed-header">
      <ion-row v-if="!isModal" class="ion-align-items-center">
        <ion-col size="12" size-md="12" class="btn-title">
          <div @click="goControlView()">
            <div class="back-btn">
              <ion-icon name="ios-arrow-back" color="#9D9D9D"></ion-icon>
              <!-- <span>{{ $t("backoffice.form.buttons.back") }}</span> -->
              <h1>{{ title }}</h1>
            </div>
          </div>

          <div>
            <ion-button
              class="header-btn"
              size="small"
              fill="clear"
              @click.stop="doRefresh()"
            >
              <ion-icon name="refresh" slot="icon-only"></ion-icon>
            </ion-button>

            <ion-button
              v-if="hasQr"
              class="header-btn"
              size="small"
              fill="clear"
              @click.stop="showQr()"
            >
              <ion-icon name="ios-qr-scanner" slot="icon-only"></ion-icon>
            </ion-button>

            <ion-button
              v-if="hasConfig"
              class="header-btn"
              size="small"
              fill="clear"
              @click.stop="showConfig()"
            >
              <ion-icon name="ios-hammer" slot="icon-only"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>

      <ion-row
        class="ion-padding-vertical header-row"
        style="align-items: center"
      >
        <!-- <ion-col size="4" size-md="2" class="header-col" v-if="selectOptions">
          <ion-item>
            <ion-select :value="filterType" interface="alert" style="max-width: 100%; width: 100%; padding-left: 0;"
              @ionChange="changeFilterType($event.target.value)">
              <ion-select-option v-for="option in selectOptions" v-bind:key="option.state" :value="option.state">{{
            option.value }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col> -->
        <ion-col
          :size="selectOptions ? '6' : '6'"
          size-md="6"
          class="ion-align-items-center header-col"
        >
          <ion-searchbar
            class="header-searchbar"
            @input="handleInput($event.target.value)"
            @ionClear="filter = elements"
            :placeholder="$t('frontend.home.search')"
          ></ion-searchbar>
        </ion-col>
        <ion-col
          size="6"
          :offset-md="!selectOptions ? '1' : '0'"
          size-md="4"
          class="ion-padding-vertical ion-text-right right-container"
        >
          <!-- customer list icon -->
          <ion-button
            v-if="shouldCustomerIcon"
            class="header-btn"
            @click="showCustomerList()"
            style="color: var(--ion-color-primary)"
            size="small"
            fill="clear"
            title="Customer List"
          >
            <ion-icon slot="icon-only" icon="md-contact"></ion-icon>
          </ion-button>

          <!-- end customer list icon -->
          <!-- staff list icon -->
          <ion-button
            v-if="isRole"
            class="header-btn"
            @click="showStaffList()"
            style="color: var(--ion-color-primary)"
            size="small"
            fill="clear"
            title="Customer List"
          >
            <ion-icon slot="icon-only" icon="md-contact"></ion-icon>
          </ion-button>

          <!-- end staff list icon -->
          <ion-button
            class="header-btn"
            v-if="isWorkSheet"
            @click="this.$parent.openCalendarModal"
            style="color: var(--ion-color-primary)"
            size="small"
            fill="clear"
            title="Calendar View"
          >
            <ion-icon slot="icon-only" icon="md-calendar"></ion-icon>
          </ion-button>
          <ion-button
            class="header-btn"
            @click="
              (listActive = false), (isOpenRef = false), (optionSelected = '')
            "
            :style="
              !listActive
                ? 'color: var(--ion-color-primary);'
                : 'color: #9d9d9d;'
            "
            size="small"
            fill="clear"
          >
            <ion-icon slot="icon-only" icon="md-apps"></ion-icon>
          </ion-button>
          <ion-button
            class="header-btn"
            @click="
              (listActive = true), (isOpenRef = false), (optionSelected = '')
            "
            :style="
              listActive
                ? 'color: var(--ion-color-primary);'
                : 'color: #9d9d9d;'
            "
            size="small"
            fill="clear"
          >
            <ion-icon slot="icon-only" icon="md-list"></ion-icon>
          </ion-button>
          <ion-button
            v-tooltip="'Search Orders by status'"
            class="header-btn"
            @click="openFilterOrder"
            v-if="order"
            :style="
              listActive
                ? 'color: var(--ion-color-primary);'
                : 'color: #9d9d9d;'
            "
            size="small"
            fill="clear"
          >
            <span
              class="iconify icon-primary iconify"
              data-icon="mage:filter"
              data-inline="false"
            ></span>
          </ion-button>
          <ion-button
            v-tooltip="'Search Quotes by status'"
            class="header-btn"
            @click="openFilterQuotes"
            v-if="quote"
            :style="
              listActive
                ? 'color: var(--ion-color-primary);'
                : 'color: #9d9d9d;'
            "
            size="small"
            fill="clear"
          >
            <span
              class="iconify icon-primary iconify"
              data-icon="mage:filter"
              data-inline="false"
            ></span>
          </ion-button>
          <ion-button
            v-tooltip="'Search Invoices by status'"
            class="header-btn"
            @click="open"
            v-if="invoice"
            :style="
              listActive
                ? 'color: var(--ion-color-primary);'
                : 'color: #9d9d9d;'
            "
            size="small"
            fill="clear"
          >
            <span
              class="iconify icon-primary iconify"
              data-icon="mage:filter"
              data-inline="false"
            ></span>
          </ion-button>
          <ion-chip outline color="primary" @click="handleAddClick" v-if="add">
            <ion-icon name="md-add" color="primary" class="add-icon"></ion-icon>
            <ion-label color="primary" class="hide-md">{{
              $t("backoffice.form.buttons.add")
            }}</ion-label>
          </ion-chip>
        </ion-col>
      </ion-row>

      <list-filters v-if="enableFilter" :entity="entity" />
    </ion-header>

    <div>
      <ion-progress-bar v-if="spinner" type="indeterminate"></ion-progress-bar>
    </div>

    <bulk-delete
      v-if="quote || invoice || order || bulk"
      :items="filteredItems"
      @deleted="handleBulkDelete"
      :type="type"
      :show-buttons="true"
      :showCheckbox="false"
      :selected-items.sync="selectedItems"
    />

    <div class="content-container">
      <paginate
        name="languages"
        :list="filter"
        :per="hidePagination ? 10 : isOrder ? 50 : 8"
      >
        <ion-list class="content-list" v-if="listActive">
          <ion-item
            v-for="option in paginated('languages')"
            v-bind:key="option._id"
            @click.stop="isOpenRef = false"
          >
            <ion-avatar slot="start" v-if="hasImg">
              <img
                :src="
                  option.ImageUrl
                    ? option.ImageUrl
                    : require('../assets/user.png')
                "
              />
            </ion-avatar>
            <ion-label>
              <!-- <h2>{{ listViewData(option,2) }} {{ listViewData(option,3) }}</h2> -->
              <h2 v-if="isWorkSheet">{{ getUserName(option) }}</h2>
              <h2 v-else-if="isSubscription">{{ getSubsTitle(option) }}</h2>
              <h2 v-else-if="isAssetPickup">{{ option.Name }}</h2>
              <h2 v-else-if="!isOrder">{{ option.Name }}</h2>
              <h2 v-else>{{ cardTitle }} - {{ getStateOrder(option) }}</h2>
              <h3>
                <span v-if="isWorkSheet">
                  Created By: {{ option.CreatedBy }} on
                  {{
                    DateTime.fromJSDate(new Date(option.CreateDate)).toFormat(
                      "MM/dd/yyyy HH:mm:ss"
                    )
                  }}
                </span>
                <span v-else-if="isSubscription">
                  Amount: {{ restaurantData.Currency }}
                  {{ option.amount }}&nbsp; Interval:
                  {{
                    option.isRecurring ? option.paymentInterval : "One Time"
                  }}&nbsp; PaymentMethod: {{ option.paymentMethod }}&nbsp;
                  Status:
                  <span class="text-capitalize">{{ option.status }}</span>
                </span>
                <span v-else-if="isAssetPickup">Asset Pickup</span>
                <span v-else>
                  {{ listViewData(option, 4) }}
                  <br
                    v-if="
                      listViewData(option, 4) !== null &&
                      listViewData(option, 5) !== null
                    "
                  />
                  {{ listViewData(option, 5) }}
                </span>
              </h3>
            </ion-label>
            <ion-button
              fill="clear"
              shape="round"
              class="list-group-btn"
              side="end"
              v-if="hasAction && (!isOpenRef || optionSelected !== option._id)"
              @click.stop="(isOpenRef = true), (optionSelected = option._id)"
            >
              <ion-icon
                slot="icon-only"
                icon="md-more"
                class="more-grid"
              ></ion-icon>
            </ion-button>

            <div v-if="isOpenRef && optionSelected == option._id">
              <PopOver
                :isWorkSheet="isWorkSheet"
                :isStaff="isStaff"
                :isShowPaymentResponse="isShowPaymentResponse"
                :isShowCommunicationResponse="isShowCommunicationResponse"
                :isSubscription="isSubscription"
                :isOrder="isOrder"
                :isEvent="isEvent"
                :order="order"
                :quote="quote"
                :invoice="invoice"
                :delivery="delivery"
                :notAccepted="notAccepted"
                :showClock="showClock"
                :add="add"
                :see="see"
                :edit="isSupportUserLogin || (edit && option.CanEdit !== false)"
                :remove="
                  isSupportUserLogin || (remove && option.CanDelete !== false)
                "
                :showQrTable="showQrTable"
                :showOrderTable="showOrderTable"
                :element="option"
                :id="option._id"
                @showOrderTableM="showOrderTableM"
                @reOrder="reOrder"
                @showPaymentResponse="showPaymentResponse"
                @showCommunicationResponse="showCommunicationResponse"
                @curbsideDetail="curbsideDetail"
                @cancelOrder="cancelOrder"
                @printOrder="printOrder"
                @previewQuote="previewQuote"
                @sendEmail="sendEmail"
                @bulkSendEmail="bulkSendEmail"
                @duplicateProduct="duplicateProduct"
                @duplicateOrder="duplicateOrder"
                @shareQuote="shareQuote"
                @shareInvoice="shareInvoice"
                @shareOrder="shareOrder"
                @acceptOrder="acceptOrder"
                @notAcceptOrder="notAcceptOrder"
                @openWorksheet="openWorksheet"
                @writeLocalization="writeLocalization"
                @showDeliveringLocation="showDeliveringLocation"
                @showTravel="showTravel"
                @showTime="showTime"
                @showQrTableM="showQrTableM"
                @editElement="editElement"
                @deleteElement="deleteElement"
                @openPayment="openPayment"
                @previewCustomer="previewCustomer"
              />
            </div>
          </ion-item>
        </ion-list>

        <ion-row v-else>
          <ion-col
            size="12"
            size-md="3"
            class="ion-align-items-center header-col"
            v-for="option in paginated('languages')"
            v-bind:key="option._id"
          >
            <ion-card
              v-if="!isOrder && !isAssetPickup"
              @click.stop="isOpenRef = false"
            >
              <ion-card-header class="card-header space-item">
                <ion-avatar v-if="hasImg">
                  <img
                    :src="
                      listViewData(option, 1)
                        ? listViewData(option, 1)
                        : require('../assets/user.png')
                    "
                  />
                </ion-avatar>
                <ion-checkbox
                  v-if="bulk"
                    :value="option._id"
                    :checked="selectedItems.includes(option._id)"
                    @ionChange="toggleSelection(option._id, $event)"
                  >
                  </ion-checkbox>
                <ion-card-title color="white" class="card-title card-titles">
                  <span v-if="isWorkSheet">
                    {{ getUserName(option) }}
                  </span>
                  <span v-else-if="isSubscription">{{
                    getSubsTitle(option)
                  }}</span>
                  <!-- <span v-else>
                    {{ listViewData(option, 2) }} {{ listViewData(option, 3) }}
                  </span> -->
                  <div v-else class="normalized-html" v-html="`${listViewData(option, 2)?? ''} ${listViewData(option, 3)?? ''}`"></div>
                </ion-card-title>
                <ion-button
                  fill="clear"
                  shape="round"
                  class="list-group-btn"
                  side="end"
                  v-if="
                    hasAction && (!isOpenRef || optionSelected !== option._id)
                  "
                  @click.stop="
                    (isOpenRef = true), (optionSelected = option._id)
                  "
                >
                  <ion-icon
                    slot="icon-only"
                    icon="md-more"
                    class="more-grid"
                  ></ion-icon>
                </ion-button>

                <div v-if="isOpenRef && optionSelected == option._id">
                  <PopOver
                    :list="false"
                    :isWorkSheet="isWorkSheet"
                    :isShowPaymentResponse="isShowPaymentResponse"
                    :isShowCommunicationResponse="isShowCommunicationResponse"
                    :isSubscription="isSubscription"
                    :isStaff="isStaff"
                    :showDuplicateItem="showDuplicateItem"
                    :showAddButton="showAddButton"
                    :showAddButtons="showAddButtons"
                    :showAddButtonss="showAddButtonss"
                    :isOrder="isOrder"
                    :isEvent="isEvent"
                    :order="order"
                    :quote="quote"
                    :invoice="invoice"
                    :delivery="delivery"
                    :notAccepted="notAccepted"
                    :showClock="showClock"
                    :add="add"
                    :see="see"
                    :edit="
                      isSupportUserLogin || (edit && option.CanEdit !== false)
                    "
                    :remove="
                      isSupportUserLogin ||
                      (remove && option.CanDelete !== false)
                    "
                    :showQrTable="showQrTable"
                    :showOrderTable="showOrderTable"
                    :element="option"
                    :id="option._id"
                    @showOrderTableM="showOrderTableM"
                    @reOrder="reOrder"
                    @curbsideDetail="curbsideDetail"
                    @cancelOrder="cancelOrder"
                    @printOrder="printOrder"
                    @previewQuote="previewQuote"
                    @sendEmail="sendEmail"
                    @bulkSendEmail="bulkSendEmail"
                    @showPaymentResponse="showPaymentResponse"
                    @showCommunicationResponse="showCommunicationResponse"
                    @pauseReStartSubscription="pauseReStartSubscription"
                    @duplicateProduct="duplicateProduct"
                    @duplicateOrder="duplicateOrder"
                    @shareQuote="shareQuote"
                    @shareInvoice="shareInvoice"
                    @shareOrder="shareOrder"
                    @acceptOrder="acceptOrder"
                    @notAcceptOrder="notAcceptOrder"
                    @writeLocalization="writeLocalization"
                    @showDeliveringLocation="showDeliveringLocation"
                    @showTravel="showTravel"
                    @showTime="showTime"
                    @showQrTableM="showQrTableM"
                    @editElement="editElement"
                    @addInvoice="addInvoice"
                    @addQuote="addQuote"
                    @newPassword="newPassword"
                    @addOrder="addOrder"
                    @createInvoice="createInvoice"
                    @createQuote="createQuote"
                    @deleteElement="deleteElement"
                    @openPayment="openPayment"
                    @openWorksheet="openWorksheet"
                    @previewCustomer="previewCustomer"
                  />
                </div>
              </ion-card-header>
              <ion-card-content class="ion-padding-vertical">
                <span v-if="isWorkSheet">
                  Created By: {{ option.CreatedBy }} on
                  {{
                    DateTime.fromJSDate(new Date(option.CreateDate)).toFormat(
                      "MM/dd/yyyy HH:mm:ss"
                    )
                  }}
                </span>
                <span v-else-if="isSubscription">
                  Amount: {{ restaurantData.Currency }}
                  {{ option.amount }}&nbsp;&nbsp; Interval:
                  {{
                    option.isRecurring ? option.paymentInterval : "One Time"
                  }}&nbsp;&nbsp; PaymentMethod: {{ option.paymentMethod }}&nbsp;
                  Status:
                  <span class="text-capitalize">{{ option.status }}</span>
                </span>
                <span v-else>
                  {{ listViewData(option, 4) }}
                  <br
                    v-if="
                      listViewData(option, 4) !== null &&
                      listViewData(option, 5) !== null
                    "
                  />
                  <!-- {{ listViewData(option, 5) }}<br /> -->
                  <div class="normalized-html" v-html="`${listViewData(option, 5)?? ''}`"></div><br />
                  <span
                    v-if="isStaff && option.hasWorksheet === false"
                    style="color: rgb(172, 14, 14)"
                    >No worksheet Assigned</span
                  >
                </span>
              </ion-card-content>
            </ion-card>
            <div v-if="isAssetPickup && !isWorkSheet && !isOrder">
              <ion-chip
                style="margin: 0; bottom: -10px; font-weight: bold"
                outline
              >
                {{ getFormateHour(option.Date) }}
              </ion-chip>

              <ion-card style="text-align: left" color="primary">
                <ion-card-header class="card-header"> 
                  <ion-checkbox
                  v-if="bulk"
                    :value="option._id"
                    :checked="selectedItems.includes(option._id)"
                    @ionChange="toggleSelection(option._id, $event)"
                  >
                  </ion-checkbox>
                  <ion-button
                    fill="clear"
                    shape="round"
                    class="list-group-btn"
                    side="end"
                    v-if="
                      hasAction && (!isOpenRef || optionSelected !== option._id)
                    "
                    @click.stop="
                      (isOpenRef = true), (optionSelected = option._id)
                    "
                  >
                    <ion-icon
                      slot="icon-only"
                      icon="md-more"
                      class="more-grid"
                    ></ion-icon>
                  </ion-button>
                  <div v-if="isOpenRef && optionSelected == option._id">
                    <PopOver
                      :list="false"
                      :isOrder="isOrder"
                      :isAssetPickup="isAssetPickup"
                      :see="see"
                      :order="order"
                      :add="add"
                      :edit="
                        isSupportUserLogin || (edit && option.CanEdit !== false)
                      "
                      :remove="
                        isSupportUserLogin ||
                        (remove && option.CanDelete !== false)
                      "
                      :element="option"
                      :id="option._id"
                      @reOrder="reOrder"
                      @printAsset="printAsset"
                      @previewQuote="previewQuote"
                      @previewAsset="previewAsset"
                      @sendEmail="sendEmail"
                      @bulkSendEmail="bulkSendEmail"
                      @editElement="editElement"
                      @deleteElement="deleteElement"
                      @openPayment="openPayment"
                      :isShowPaymentResponse="isShowPaymentResponse"
                      :isShowCommunicationResponse="isShowCommunicationResponse"
                      @showPaymentResponse="showPaymentResponse"
                      @showCommunicationResponse="showCommunicationResponse"
                      @shareAsset="shareAsset"
                    />
                  </div>
                </ion-card-header>
                <ion-card-header
                  style="
                    margin: 10px 5px 2px;
                    padding: 10px;
                    background: white;
                    color: black;
                  "
                >
                <ion-card-title style="color: black">
                  {{ cardTitle }} -
                  <span
                    style="text-transform: uppercase"
                  >
                    {{ option.Number }}
                  </span>
                </ion-card-title>
                  <ion-card-title style="color: black">
                    <ion-card-subtitle
                      v-if="option.Name"
                      style="
                        color: black;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div
                        style="text-align: center"
                        v-tooltip="$t('backoffice.form.titles.customer')"
                      >
                        <span
                          class="iconify"
                          data-icon="bx:bxs-user-circle"
                          data-inline="false"
                          style="
                            color: #808080a6;
                            width: 20px;
                            height: 20px;
                            margin: 0;
                          "
                        ></span>
                      </div>
                      <div style="text-align: right">{{ option.Name }}</div>
                    </ion-card-subtitle>

                    <ion-card-subtitle
                      v-if="option.Email"
                      style="
                        color: black;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div
                        style="text-align: center"
                        v-tooltip="$t('frontend.orderType.email')"
                      >
                        <span
                          class="iconify"
                          data-icon="ic:baseline-email"
                          data-inline="false"
                          style="
                            color: #808080a6;
                            width: 20px;
                            height: 20px;
                            margin: 0;
                          "
                        ></span>
                      </div>
                      <div style="text-align: right">{{ option.Email }}</div>
                    </ion-card-subtitle>

                    <ion-card-subtitle
                      v-if="option.Phone"
                      style="
                        color: black;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div
                        style="text-align: center"
                        v-tooltip="$t('frontend.orderType.phone')"
                      >
                        <span
                          class="iconify"
                          data-icon="bi:phone"
                          data-inline="false"
                          style="
                            color: #808080a6;
                            width: 20px;
                            height: 20px;
                            margin: 0;
                          "
                        ></span>
                      </div>
                      <div style="text-align: right">{{ option.Phone }}</div>
                    </ion-card-subtitle>

                    <ion-card-subtitle
                      v-if="option.Address1"
                      style="
                        color: black;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div
                        style="text-align: center"
                        v-tooltip="$t('backoffice.form.fields.address')"
                      >
                        <span
                          class="iconify"
                          data-icon="mdi:address-marker-outline"
                          data-inline="false"
                          style="
                            color: #808080a6;
                            width: 20px;
                            height: 20px;
                            margin: 0;
                          "
                        ></span>
                      </div>
                      <div style="text-align: right">{{ option.Address1 }}</div>
                    </ion-card-subtitle>
                  </ion-card-title>
                </ion-card-header>
              </ion-card>
            </div>

            <!-- isOrder && filter.length > 0 is to be sure that doesnt show cards with invalid data -->
            <div v-else-if="isOrder && filter.length > 0">
              <ion-chip
                style="margin: 0; bottom: -10px; font-weight: bold"
                outline
              >
                {{ getFormateHour(option.Date) }}
              </ion-chip>

              <ion-card style="text-align: left" color="primary">
                <ion-card-header class="card-header">
                  <ion-button
                    fill="clear"
                    shape="round"
                    class="list-group-btn"
                    side="end"
                    v-if="
                      hasAction && (!isOpenRef || optionSelected !== option._id)
                    "
                    @click.stop="
                      (isOpenRef = true), (optionSelected = option._id)
                    "
                  >
                    <ion-icon
                      slot="icon-only"
                      icon="md-more"
                      class="more-grid"
                    ></ion-icon>
                  </ion-button>
                  <ion-checkbox
                    v-if="(showCheckbox && quote) || invoice || order"
                    :value="option._id"
                    :checked="selectedItems.includes(option._id)"
                    @ionChange="toggleSelection(option._id, $event)"
                  >
                  </ion-checkbox>
                  <div v-if="isOpenRef && optionSelected == option._id">
                    <PopOver
                      :list="false"
                      :isWorkSheet="isWorkSheet"
                      :isShowPaymentResponse="isShowPaymentResponse"
                      :isShowCommunicationResponse="isShowCommunicationResponse"
                      :isStaff="isStaff"
                      :isOrder="isOrder"
                      :isEvent="isEvent"
                      :order="order"
                      :quote="quote"
                      :invoice="invoice"
                      :delivery="delivery"
                      :notAccepted="notAccepted"
                      :showClock="showClock"
                      :add="add"
                      :edit="
                        isSupportUserLogin || (edit && option.CanEdit !== false)
                      "
                      :remove="
                        isSupportUserLogin ||
                        (remove && option.CanDelete !== false)
                      "
                      :showQrTable="showQrTable"
                      :showOrderTable="showOrderTable"
                      :element="option"
                      :id="option._id"
                      @showOrderTableM="showOrderTableM"
                      @reOrder="reOrder"
                      @curbsideDetail="curbsideDetail"
                      @cancelOrder="cancelOrder"
                      @printOrder="printOrder"
                      @previewQuote="previewQuote"
                      @sendEmail="sendEmail"
                      @bulkSendEmail="bulkSendEmail"
                      @showPaymentResponse="showPaymentResponse"
                      @showCommunicationResponse="showCommunicationResponse"
                      @pauseReStartSubscription="pauseReStartSubscription"
                      @duplicateProduct="duplicateProduct"
                      @duplicateOrder="duplicateOrder"
                      @shareQuote="shareQuote"
                      @shareInvoice="shareInvoice"
                      @shareOrder="shareOrder"
                      @acceptOrder="acceptOrder"
                      @notAcceptOrder="notAcceptOrder"
                      @writeLocalization="writeLocalization"
                      @showDeliveringLocation="showDeliveringLocation"
                      @showTravel="showTravel"
                      @showTime="showTime"
                      @showQrTableM="showQrTableM"
                      @editElement="editElement"
                      @deleteElement="deleteElement"
                      @openPayment="openPayment"
                      @openWorksheet="openWorksheet"
                      @previewCustomer="previewCustomer"
                    />
                  </div>
                </ion-card-header>

                <ion-card-header
                  style="
                    margin: 10px 5px 2px;
                    padding: 10px;
                    background: white;
                    color: black;
                  "
                >
                  <ion-card-title style="color: black">
                    {{ cardTitle }} -
                    <!-- <ion-card-title  style="color: black;">{{ option.OrderType }} - -->
                    <span
                      v-if="option.QouteInvoice"
                      style="text-transform: uppercase"
                    >
                      {{ option.QouteInvoice.number }}
                    </span>
                    <span v-else style="text-transform: uppercase">
                      {{ option._id.slice(-4) }}
                    </span>
                    <span
                      v-if="option.CarArrived && option?.State !== 5"
                      class="iconify"
                      data-icon="clarity:car-solid"
                      data-inline="false"
                      style="float: right; color: #48bc76; margin: 0"
                    ></span>
                  </ion-card-title>

                  <ion-card-subtitle
                    v-if="option.CustomerName"
                    style="
                      color: black;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <div
                      style="text-align: center"
                      v-tooltip="$t('backoffice.form.titles.customer')"
                    >
                      <span
                        class="iconify"
                        data-icon="bx:bxs-user-circle"
                        data-inline="false"
                        style="
                          color: #808080a6;
                          width: 20px;
                          height: 20px;
                          margin: 0;
                        "
                      ></span>
                    </div>
                    <div style="text-align: right">
                      {{ option.CustomerName }}
                    </div>
                  </ion-card-subtitle>

                  <ion-card-subtitle
                    v-if="option.StaffName"
                    style="
                      color: black;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <div
                      style="text-align: center"
                      v-tooltip="$t('backoffice.titles.staff')"
                    >
                      <span
                        class="iconify"
                        data-icon="grommet-icons:restaurant"
                        style="
                          color: #808080a6;
                          width: 20px;
                          height: 20px;
                          margin: 0;
                        "
                        data-inline="false"
                      ></span>
                    </div>
                    <span style="text-align: right">{{
                      option.StaffName
                    }}</span>
                  </ion-card-subtitle>

                  <ion-card-subtitle
                    v-if="option.Driver"
                    style="
                      color: black;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <div
                      style="text-align: center"
                      v-tooltip="$t('backoffice.form.fields.driver')"
                    >
                      <span
                        class="iconify"
                        data-icon="fontisto:automobile"
                        style="
                          color: #808080a6;
                          width: 20px;
                          height: 20px;
                          margin: 0;
                        "
                        data-inline="false"
                      ></span>
                    </div>
                    <span style="text-align: right">{{
                      getDriverUserName(option)
                    }}</span>
                  </ion-card-subtitle>

                  <ion-card-subtitle
                    v-if="showAddress"
                    style="
                      color: black;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <div
                      style="text-align: center"
                      v-tooltip="$t('backoffice.form.fields.address')"
                    >
                      <span
                        class="iconify"
                        data-icon="mdi:address-marker-outline"
                        style="
                          color: #808080a6;
                          width: 20px;
                          height: 20px;
                          margin: 0;
                        "
                        data-inline="false"
                      ></span>
                    </div>
                    <span style="text-align: right">{{
                      option.AddressToDeliver
                    }}</span>
                  </ion-card-subtitle>
                </ion-card-header>
                <!--se elimino el estilo de line-through-->
                <ion-card-content
                  style="
                    margin: 1px 5px;
                    padding: 5px;
                    background: white;
                    color: black;
                  "
                  :key="cartKey + 'C'"
                >
                  <!-- start reservation count -->
                  <div
                    class="reservation-count"
                    v-if="
                      option.Products &&
                      option.Products.length > 0 &&
                      option.Products[0] &&
                      option.Products[0].BookablePackage &&
                      option.Products[0].BookablePackage == true
                    "
                  >
                    <ion-button @click="showReservations(option)"
                      >Show Reservations</ion-button
                    >
                  </div>
                  <!-- end reservation count-->
                  <div v-for="(prod, index) in option.Products" :key="index">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        padding: 2px;
                        margin: 5px 0;
                      "
                    >
                      <span
                        :style="
                          prod?.State === 1
                            ? 'text-decoration: none;'
                            : 'text-decoration: none'
                        "
                        >{{ prod.Cant }} X {{ prod.Name }}
                      </span>
                      <!--se agrego el && statesAll[option.State].state!==3  ![3].includes(option.State)-->
                      <ion-checkbox
                        v-if="
                          prod?.State === 0 &&
                          edit &&
                          ![3].includes(option.State)
                        "
                        color="secondary"
                        @click="changeProductState(option, index)"
                      ></ion-checkbox>
                      <!-- <ion-checkbox v-if="prod.State ===1 && edit"  checked disabled color="secondary"></ion-checkbox> -->
                      <!--se agrego el && statesAll[option.State].state===3 [3].includes(option.State)-->
                      <span
                        v-if="
                          (prod?.State === 1 && edit) ||
                          [3].includes(option.State)
                        "
                        color="secondary"
                        class="iconifyco2"
                        ><ion-chip
                          ><span
                            class="iconify iconifyco"
                            data-icon="websymbol:ok"
                            data-inline="false"
                          ></span
                          >{{ $t("frontend.order.prepare") }}</ion-chip
                        ></span
                      >
                    </div>

                    <div
                      v-if="prod.Aggregates.length > 0"
                      style="padding-left: 5px"
                    >
                      <div
                        v-for="(agg, index1) in prod.Aggregates"
                        :key="index1"
                        style="
                          padding-left: 2px;
                          display: list-item;
                          list-style: inside;
                        "
                      >
                        {{ agg.Cant }} X {{ agg.Name }}
                      </div>
                    </div>

                    <div v-if="prod.Note" style="padding-left: 15px">
                      <span style="background: #ffff0047"
                        >{{ $t("frontend.order.notes") }}: {{ prod.Note }}</span
                      >
                    </div>
                  </div>
                  <div v-if="option.OtherCharges">
                    <div v-if="option.OtherCharges.length > 0">
                      <ion-label class="ion-text-wrap">
                        <strong class="titles-order">
                          {{ $t("frontend.order.otherCharges") }}
                        </strong>
                      </ion-label>

                      <div
                        v-for="charge in option.OtherCharges"
                        v-bind:key="charge.Id"
                      >
                        <div
                          style="
                            padding-left: 2px;
                            display: list-item;
                            list-style: inside;
                          "
                        >
                          {{ charge.Name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-card-content>

                <!--   <ion-card-content style="margin:5px; background:white;color: black;text-align: center; padding: 0;" >
                    <p style="font-size: 18px;font-weight: 500;padding: 5px;">{{statesAll[option.State].name}}</p>
                  </ion-card-content> -->
                <!--Se agrego el v-text para que de manera en tiempo real se traduzca el estado de la qoute o invoice-->
                <ion-card-content
                  style="
                    margin: 5px;
                    background: white;
                    color: black;
                    text-align: center;
                    padding: 0;
                  "
                >
                  <!--We add that validation to show only on Quote that text-->
                  <p class="orderState fw-bold">{{ getStateOrder(option) }}</p>
                  <p class="orderState">
                    <!-- Deposit {{ getFormatPrice(option.Deposit || 0) }} -->
                    Deposit {{ 
                      option.Partial && option.Partial.length > 0 
                        ? getFormatPrice(calculateTotalPartialPayments(option.Partial[0]?.paymentInfo?.PartialPaymentsMade)) 
                        : getFormatPrice(option.Deposit || 0) 
                    }}
                  </p>
                  <p class="orderState">
                    <!-- Balance
                    {{ option.Payment ? getFormatPrice(0) : getFormatPrice((option.Total || 0) - (option.Deposit || 0) - (option.Discount || 0))}} -->
                    Balance
                    {{
                      option.Payment && !option.Partial
                        ? getFormatPrice(0)
                        : option.Partial
                        ? getFormatPrice((option?.Total || 0) - calculateTotalPartialPayments(option.Partial[0]?.paymentInfo?.PartialPaymentsMade))
                        : getFormatPrice((option?.Total || 0) - (option?.Deposit || 0) - (option?.Discount || 0))
                    }}
                  </p>
                </ion-card-content>

                <div
                  v-if="edit && !isDeliveryDriverScreen"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    v-tooltip="$t('frontend.order.notes')"
                    @click="showOrderNote(option)"
                  >
                    <span
                      class="iconify"
                      data-icon="bi:info-circle-fill"
                      data-inline="false"
                      style="width: 20px; height: 20px"
                    ></span>
                  </div>
                  <h3 style="text-align: center">
                    {{ getFormatPrice(option.Total - (option.Discount || 0)) }}
                  </h3>
                  <div
                    v-tooltip="$t('frontend.tooltips.editTicket')"
                    @click="editElement(option._id)"
                  >
                    <span
                      class="iconify"
                      data-icon="el:file-edit-alt"
                      data-inline="false"
                      style="width: 20px; height: 20px"
                    ></span>
                  </div>
                </div>

                <div v-if="edit && isDeliveryDriverScreen">
                  <div
                    v-if="
                      (getLoggedInUser.IsDriver && option.IsAccept) ||
                      !getLoggedInUser.IsDriver
                    "
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div
                      v-tooltip="$t('frontend.order.notes')"
                      @click="showOrderNote(option)"
                    >
                      <span
                        class="iconify"
                        data-icon="bi:info-circle-fill"
                        data-inline="false"
                        style="width: 20px; height: 20px"
                      ></span>
                    </div>
                    <h3 style="text-align: center">
                      {{
                        getFormatPrice(option.Total - (option.Discount || 0))
                      }}
                    </h3>
                    <div
                      v-tooltip="$t('frontend.tooltips.editTicket')"
                      @click="editElement(option._id)"
                    >
                      <span
                        class="iconify"
                        data-icon="el:file-edit-alt"
                        data-inline="false"
                        style="width: 20px; height: 20px"
                      ></span>
                    </div>
                  </div>
                </div>
              </ion-card>
            </div>
          </ion-col>
        </ion-row>
      </paginate>

      <div style="text-align: right; font-weight: 700" class="card-title">
        {{ listViewData({}, 6) }}
      </div>

      <paginate-links
        v-if="!hidePagination"
        for="languages"
        color="primary"
        :simple="{
          next: '»',
          prev: '« ',
        }"
      ></paginate-links>
    </div>
  </div>
</template>

<script>
import PopOver from "./Popover.vue";
import { Utils } from "../utils/utils.js";
import ListFilters from "./ListFilters.vue";
import { DateTime } from "luxon";
import { actionSheetController } from "@ionic/core";
import BulkDelete from "./BulkDelete.vue";
export default {
  name: "listView",
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    showCustomerListIcon: {
      type: Boolean,
      default: true,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    isSubscription: {
      type: Boolean,
      default: false,
    },
    isAssetPickup: {
      type: Boolean,
      default: false,
    },
    isEvent:{
      type: Boolean,
      default: false,
    },
    isRole: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    isStaff: {
      type: Boolean,
      default: false,
    },
    isWorkSheet: {
      type: Boolean,
      default: false,
    },
    isShowPaymentResponse: {
      type: Boolean,
      default: false,
    },
    isShowCommunicationResponse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    entity: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    enableFilter: {
      type: Boolean,
      default: false,
    },
    cardTitle: {
      type: String,
    },
    selectOptions: {
      type: Array,
      required: false,
    },
    filter: {
      type: Array,
    },
    elements: {
      type: Array,
    },
    add: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    see: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    delivery: {
      type: Boolean,
      default: false,
    },
    isSupportUserLogin: {
      type: Boolean,
      default: false,
    },
    hasAction: {
      type: Boolean,
      default: true,
    },
    hasImg: {
      type: Boolean,
      default: false,
    },
    hasQr: {
      type: Boolean,
      default: false,
    },
    hasConfig: {
      type: Boolean,
      default: false,
    },
    showQrTable: {
      type: Boolean,
      default: false,
    },
    showOrderTable: {
      type: Boolean,
      default: false,
    },
    isOrder: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Boolean,
      default: false,
    },
    bulk: {
      type: Boolean,
      default: false,
    },
    showDuplicateItem: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    showAddButtons: {
      type: Boolean,
      default: false,
    },
    showAddButtonss: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: "",
    },
    cartKey: {
      type: Number,
      default: 0,
    },
    notAccepted: {
      type: Boolean,
      default: false,
    },
    showClock: {
      type: Boolean,
      default: false,
    },
    viewSelected: {
      type: String,
      default: "",
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    isDeliveryDriverScreen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PopOver,
    ListFilters,
    BulkDelete,
  },
  data() {
    return {
      filteredItems: this.filter,
      showFilterState: false,
      listActive: false,
      paginate: ["languages"],
      spinner: false,
      isOpenRef: false,
      event: {},
      optionSelected: "",
      statesAll: [
        {
          state: 0,
          name: this.$t("frontend.order.state0"),
        },
        {
          state: 1,
          name: this.$t("frontend.order.state1"),
        },
        {
          state: 2,
          name: this.$t("frontend.order.state2"),
        },
        {
          state: 3,
          name: this.$t("frontend.order.state3"),
        },
        {
          state: 4,
          name: this.$t("frontend.order.state4"),
        },
        {
          state: 5,
          name: this.$t("frontend.order.state5"),
        },
        {
          state: 6,
          name: this.$t("frontend.order.state6"),
        },
        {
          state: 7,
          name: this.$t("frontend.order.state7"),
        },
        {
          state: 8,
          name: this.$t("frontend.order.state8"),
        },
        {
          state: 12,
          name: this.$t("frontend.order.state12"),
        },
      ],
      allStaff: [],
      showCheckbox: true,
      selectedItems: [],
    };
  },
  created: function () {
    //console.log('listview', this.$store.state.restaurantActive);
    this.allStaff = this.$store.state.backConfig.staff;
    //console.log(this.elements, this.filter);
  },
  computed: {
    restaurantData() {
      return this.$store.state.backConfig.restaurant;
    },
    DateTime() {
      return DateTime;
    },
    shouldCustomerIcon() {
      return this.invoice; // this.$route.path != '/customer';
    },
    getLoggedInUser() {
      return this.$store.state.user;
    },
  },
  mounted() {},
  watch: {
    filter(newFilter) {
      this.filteredItems = newFilter;
    },
  },
  methods: {
    calculateTotalPartialPayments(partialPayments) {
      if (!partialPayments || partialPayments.length === 0) {
        return 0; // Si no hay pagos parciales, retorna 0
      }
      return partialPayments.reduce((total, payment) => total + payment?.amount, 0);
    },
    toggleSelection(itemId, event) {
      if (event.target.checked) {
        if (!this.selectedItems.includes(itemId)) {
          this.selectedItems.push(itemId);
        }
      } else {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      }
      this.$emit("update:selectedItems", this.selectedItems);
      //console.log("Selected items:", this.selectedItems);
    },

    handleBulkDelete(deletedIds) {
      this.filteredItems = this.filteredItems.filter(
        (item) => !deletedIds.includes(item._id)
      );
    },
    getUserName(staff) {
      const ss = this.allStaff.find((el) => staff.IdStaff === el._id);
      return ss ? `${ss.FirstName} ${ss.LastName}` : "N/A";
    },
    getDriverUserName(driver) {
      const filteredDriver = this.allStaff.find(
        (el) => driver.Driver === el._id
      );
      return filteredDriver
        ? `${filteredDriver.FirstName} ${filteredDriver.LastName}`
        : "N/A";
    },
    getSubsTitle(opt) {
      return opt
        ? `${opt?.billingAddress?.firstName} ${opt?.billingAddress?.lastName}`
        : "N/A";
    },
    /**
     * Show reservation popup
     */
    showReservations(option) {
      //console.log('showReservations', option);
      const reservationListComponent = () => import("./ReservationList");
      return this.$ionic.modalController
        .create({
          component: reservationListComponent,
          cssClass: "my-custom-class",
          componentProps: {
            data: {},
            propsData: {
              order: option,
            },
          },
        })
        .then((modal) => {
          modal.present();
          modal.onDidDismiss().then((data) => {
            console.log(data);
          });
        });
    },
    //Method to show state on card and reused code
    getStateOrder(option) {
      //console.log('getStateOrder', option);

      //just like on the InvoiceDetail where it just checksfor the existence
      //of Payment field, do the same for consistency
      if (option.Payment?.length > 0) {
        if ([12, 13].includes(option.Payment[0].state)) {
          return this.$t(`frontend.order.state${option.Payment[0].state}`);
        }
        return this.$t("frontend.payment.goodPayment");
      }


      if (this.isInvoice) {
        if (this.statesAll[option?.State]?.state === 0) {
          return this.$t("frontend.order.pendingAcceptance");
        } else if (this.statesAll[option?.State]?.state === 1) {
          return this.$t("frontend.order.accepted");
        }
      } else {
        return this.$t(`frontend.order.state${this.statesAll[option?.State]?.state}`);
      }
    },

    handleInput(e) {
      this.$emit("handleInput", e);
    },
    handleAddClick() {
      this.$emit("handleAddClick");
    },
    handleFilterClick() {
      this.$emit("handleFilterClick");
    },
    async open() {
      const actionSheet = await actionSheetController.create({
        header: "Filter by states.",
        buttons: [
          {
            text: "By Pending Payment",
            handler: () => {
              this.$emit("filterInvoices", 0); // Emitimos el evento con el estado 0 (pendiente de pago)
            },
          },
          {
            text: "By Paid",
            handler: () => {
              this.$emit("filterInvoices", 1); // Emitimos el evento con el estado 1 (pagado)
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },

    async openFilterQuotes() {
      const actionSheet = await actionSheetController.create({
        header: "Filter by states.",
        buttons: [
          {
            text: "By Pending Acceptance",
            handler: () => {
              this.$emit("filterQuotes", 0); // Emitimos el evento con el estado 0 (pendiente de aceptar)
            },
          },
          {
            text: "Accepted",
            handler: () => {
              this.$emit("filterQuotes", 1); // Emitimos el evento con el estado 1 (aceptada)
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },

    async openFilterOrder() {
      const actionSheet = await actionSheetController.create({
        header: "Filter by states.",
        buttons: [
          {
            text: "By Pending Payment",
            handler: () => {
              const dateFrom = this.$refs.dateFrom.value;
              const dateTo = this.$refs.dateTo.value;
              this.$emit("filterOrders", 0, dateFrom, dateTo); // Emitimos el evento con el estado 0 (pendiente de pago)
            },
          },
          {
            text: "By Paid",
            handler: () => {
              const dateFrom = this.$refs.dateFrom.value;
              const dateTo = this.$refs.dateTo.value;
              this.$emit("filterOrders", 1, dateFrom, dateTo); // Emitimos el evento con el estado 1 (pagado)
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },

    listViewData(option, count) {
      const data = this.$parent.ListViewData(option, count);
      return data;
    },
    editElement(id) {
      this.$emit("editElement", id);
    },
    addInvoice(id) {
      this.$emit("addInvoice", id);
    },
    addQuote(id) {
      this.$emit("addQuote", id);
    },
    newPassword(id) {
      this.$emit("newPassword", id);
    },
    addOrder(id) {
      this.$emit("addOrder", id);
    },
    createInvoice(id) {
      console.log("ListView createInvoice", id);
      this.$emit("createInvoice", id);
    },
    createQuote(id) {
      this.$emit("createQuote", id);
    },
    deleteElement(id) {
      this.$emit("deleteElement", id);
    },
    async doRefresh(data = {}) {
      this.spinner = true;
      await this.$parent.doRefresh(data);
      this.spinner = false;
    },
    showQr() {
      this.$parent.show();
    },
    showConfig() {
      this.$parent.showSettingModal();
    },
    showOrderTableM(optionName) {
      return this.$parent.getOrdersDetails(optionName);
    },
    showQrTableM(seats) {
      return this.$parent.seeQrCode(seats);
    },

    changeFilterType(value) {
      this.$parent.changeFilterType(value);
    },

    changeProductState(order, index) {
      this.$parent.changeProductState(order, index);
    },

    showOrderNote(order) {
      this.$parent.showOrderNote(order);
    },

    getFormatPrice: function (price) {
      return Utils.getFormatPrice(price);
    },

    getFormateHour: function (date) {
      return Utils.getFormatHour(date);
    },

    reOrder(order) {
      this.$parent.reOrder(order);
    },

    curbsideDetail(order) {
      this.$parent.curbsideDetail(order);
    },

    cancelOrder(order) {
      this.$parent.cancelOrder(order);
    },

    printOrder(order) {
      this.$parent.printOrder(order);
    },
    printAsset(asset){
      this.$parent.printAsset(asset)
    },
    previewQuote(order) {
      this.$parent.previewQuote(order);
    },
    previewAsset(asset){
      this.$parent.previewAsset(asset);
    },
    previewCustomer(customer){
      this.$parent.previewCustomer(customer);
    },
    sendEmail(order) {
      this.$parent.sendEmail(order);
    },
    bulkSendEmail(order) {
      this.$parent.bulkSendEmail(order);
    },
    showPaymentResponse(order) {
      this.$parent.showPaymentResponse(order);
    },
    showCommunicationResponse(communication) {
      this.$parent.showCommunicationResponse(communication);
    },
    pauseReStartSubscription(order) {
      this.$parent.pauseReStartSubscription(order);
    },
    openWorksheet(order) {
      this.$parent.openWorksheet(order);
    },
    duplicateOrder(order) {
      this.$parent.duplicateOrder(order);
    },
    duplicateProduct(product) {
      this.$parent.duplicateProduct(product);
    },
    shareQuote(order) {
      this.$parent.shareQuote(order);
    },
    shareInvoice(order) {
      this.$parent.shareInvoice(order);
    },
    shareOrder(order) {
      this.$parent.shareOrder(order);
    },
    shareAsset(asset){
      this.$parent.shareAsset(asset);
    },
    acceptOrder(order) {
      this.$parent.acceptOrder(order);
    },

    notAcceptOrder(order) {
      this.$parent.notAcceptOrder(order);
    },

    writeLocalization(order) {
      this.$parent.writeLocalization(order);
    },

    showDeliveringLocation(order) {
      this.$parent.showDeliveringLocation(order);
    },

    showTravel(order) {
      this.$parent.showTravel(order);
    },

    showTime(id, email) {
      this.$parent.showTime(id, email);
    },

    goControlView: function () {
      return this.$router.push({
        name: "ControlPanel",
        params: { viewSelected: this.viewSelected },
      });
    },

    openPayment(order) {
      this.$emit("openPayment", order);
    },

    /**
     * Go to the customer list
     */
    showCustomerList() {
      //this.$router.push("/customer");
      this.$router.push({
        name: "Customer",
        params: { invoice: true },
      });
    },
    /**
     * Go to the staff list
     */
    showStaffList() {
      this.$router.push("/user");
    },
  },
};
</script>

<style>
.paginate-links li::marker {
  display: none !important;
  content: '' !important;
}

.normalized-html {
  /* Resetear todos los elementos de texto a un tamaño base */
  h1, h2, h3, h4, h5, h6, p, span {
    font-size: 14px !important;
    font-weight: normal !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.5 !important;
    white-space: normal !important; /* Aseguramos que el texto se envuelva */
    word-wrap: break-word !important;
  }

  /* Si necesitas preservar negritas o cursivas */
  strong, b {
    font-weight: bold !important;
  }

  em, i {
    font-style: italic !important;
  }
}
.icon-primary {
  color: var(--ion-color-primary);
}
.orderState.fw-bold {
  font-weight: bold !important;
}

.orderState {
  font-size: 18px;
  font-weight: 500;
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

/* .space-item {
  display: flex;
  align-items: center; 
  min-height: 100px; 
  max-height: 300px;
  overflow: hidden;
}
.card-titles {
  flex: 1; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap; 
} */

.space-item {
  display: flex;
  align-items: center; /* Alinea el contenido verticalmente */
  min-height: 100px; /* Define una altura mínima */
  max-height: 300px; /* Define una altura máxima */
  overflow: hidden; /* Oculta el desbordamiento */
}
.card-titles {
  flex: 1; /* Permite que el título ocupe todo el espacio disponible */
  overflow: hidden; /* Oculta el desbordamiento */
  word-wrap: break-word;  white-space: nowrap; /* Evita que el texto se envuelva */
}



.iconifyco {
  color: green;
  margin: 0 3px 3px 0;
  width: 12px;
  height: 12px;
}

.iconifyco2 {
  font-size: 11px;
}

.pop-card {
  position: absolute;
  z-index: 20076;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.icon-pop {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.content-icon {
  display: flex;
  flex-direction: column;
}

.btn-title {
  display: flex;
  justify-content: space-between;
}

.reservation-count {
  text-align: center;
}
</style>
