<template>
    <ion-card>
      <ion-card-header>
        <ion-title>{{ $t('backoffice.form.titles.socialeMediaChannels') }}</ion-title>
      </ion-card-header>
      <ion-card-content class="accordion-item active">
        <ion-row class="accordion-header">
            <h5></h5>
            <span class="accordion-icon">+</span>
        </ion-row>
        <ion-grid>
          <ion-row class="accordion-content">
            <template v-for="(channel, index) in socialMediaChannels">
              <ion-col size="6" :key="index">
                <ion-item>
                  <ion-label position="floating">{{ channel?.label }}</ion-label>
                  <ion-input
                    class="input-style"
                    :value="formData.socialMedia[channel.key]"
                    @input="formData.socialMedia[channel.key] = $event.target.value"
                    v-model="formData.socialMedia[channel.key]"
                    :placeholder="`Enter your ${channel?.label} handle or URL`"
                  ></ion-input>
                </ion-item>
              </ion-col>
  
              <ion-row v-if="(index + 1) % 2 === 0" />
            </template>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </template>
  
<script>
export default {
 name: "SocialMediaLinks",
 data() {
   return {
     socialMediaChannels: [
       { label: "Bluesky", key: "bluesky" },
       { label: "X (formerly Twitter)", key: "twitter" },
       { label: "Facebook", key: "facebook" },
       { label: "LinkedIn", key: "linkedin" },
       { label: "Instagram", key: "instagram" },
       { label: "WhatsApp", key: "whatsapp" },
       { label: "Pinterest", key: "pinterest" },
       { label: "TikTok", key: "tiktok" },
       { label: "Snapchat", key: "snapchat" },
       { label: "YouTube", key: "youtube" },
       { label: "Reddit", key: "reddit" },
     ],
   };
 },
 props: {
   modelValue: {
     type: Object,
     default: () => ({}),
   },
 },
 computed: {
    formData: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    document.querySelectorAll('.accordion-header').forEach(header => {
      header.addEventListener('click', () => {
        const item = header.parentNode;
        const isOpen = item.classList.contains('active');
        document.querySelectorAll('.accordion-item').forEach(item => item.classList.remove('active'));
        if (!isOpen) {
          item.classList.add('active');
        }
      });
    });
  },
  created() {

    if (!this.formData.socialMedia) {
      this.formData.socialMedia = {};
    }

    this.socialMediaChannels.forEach((channel) => {
      if (!(channel.key in this.formData.socialMedia)) {
        this.$set(this.formData.socialMedia, channel.key, ""); 
      }
    });
    //console.log(this.socialMediaChannels)
  },
};
</script>

<style>
.accordion {
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.accordion-icon {
  font-size: 1.5em;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-item.active .accordion-content {
  max-height: 1100px;
  transition: max-height 0.3s ease-in;
}
</style>