<template>
  <modal name="invoice-view" width="90%" height="95%">
    <div>
      <ion-toolbar>

        <ion-buttons slot="end">
          <ion-button @click="$modal.hide('invoice-view')">❌</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div>
    <ion-content>
      <div class="ion-padding" height="80%">
        <ion-row>
          <ion-col class="text-left">
            <div style="margin-top: 20px; margin-bottom: 20px;">
              <v-sheet>
                <span v-html="html"></span>
                <CustomFooter v-if="type !== 'asset' && type !== 'customer'" :type="type" :text="editorData" :key="editorData" :data="data" />
<!--                <ion-button class="mt-5" expand="full" color="primary" @click="saveSetting()">{{-->
<!--                    $t("backoffice.form.buttons.save") }}</ion-button>-->
              </v-sheet>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
  </modal>
</template>


<script>
import { Api } from "@/backoffice/api/api";
import Swal from "sweetalert2";
import CustomFooter from "@/backoffice/components/CustomFooter.vue";

export default {
  name: "InvoicePreview",
  components: {
    CustomFooter
  },
  data() {
    return {
      type: '',
      html: '',
      id: '',
      data: null,
      editorData: '',
      forPrint: false
    }
  },
  methods: {
    init (type, html, data, forPrint) {
      this.type = type
      this.html = html
      this.data = data
      this.forPrint = forPrint
      this.id = this.$store.state.user.RestaurantId;
      if (this.id) {
        const resData = this.$store.state.backConfig.restaurant;
        this.$modal.show('invoice-view')
        switch (type) {
          case 'invoice':
            this.editorData = resData.invoiceFooterData?.text || this.editorData
            break;
          case 'order':
            this.editorData = resData.orderFooterData?.text || this.editorData
            break;
          case 'quote':
            this.editorData = resData.quoteFooterData?.text || this.editorData
            break;
          case 'asset':
            this.editorData = '';
            break;
          case 'customer':
            this.editorData = '';
            break;
        }
      }
    },
  }
}
</script>