<template>
  <ion-footer v-if="!disabledFooter || !data" class="custom-order-footer">
    <div class="footer-container" style="background: white; padding-top: 10px">
<!--      <img :src="restaurant.restaurantLogo" alt="Logo" class="logo">-->
      <qrcode-vue v-if="data" :value="getURL()" :size=100 level="H"></qrcode-vue>
      <div  class="footer-text">
        <p v-if="type === 'order'">
          <span>Order Processed By: {{ restaurantData.Name }}</span>
        </p>
        <p v-if="type === 'quote'">
          <span v-if="!data">Thank you for considering our services. This quote is valid until [Insert Expiry Date].</span>
          <span v-else>Thank you for considering our services. This quote is valid until {{ data?.QouteInvoice?.expirationQuote }}.</span>
        </p>
        <p v-if="type === 'quote'">
          <span>You may accept this quote as is, recommend any changes or updates via our portal.</span>
        </p>
        <span v-html="editorData"></span>
        <p v-if="type === 'order'">
          <span v-if="!data">Estimated Delivery Date: [Insert Date]</span>
          <span v-else-if="data && data.OrderType.toLowerCase() === 'pickup'">Estimated <span class="capitalize">{{ data.OrderType.toLowerCase() }}</span> date: {{ getFormatDate(data.DateToPick, 'MM/dd/yyyy') }} {{ getFormatDate(data.HourToPick, 'HH:mm') }}</span>
          <br v-if="!data || (data && data.OrderType.toLowerCase() === 'pickup')">
          <br v-if="!data || (data && data.OrderType.toLowerCase() === 'pickup')">
          <span v-if="!data">Keep this order confirmation for your records. Order No: [Insert Order Number]</span>
          <span v-else-if="data">Keep this order confirmation for your records. Order No: {{ data._id.slice(-4) }}</span>
        </p>
        <p>
          {{restaurantData.Name}} | {{restaurantData.Address}}
          | <a :href="restaurantData.Web" target="_blank">{{restaurantData.Web}}</a>
          | <a :href="'mailto:' + restaurantData.Email" target="_blank">{{restaurantData.Email}}</a> | {{restaurantData.Phone}}
        </p>
        <p>
          <small>
            <a v-if="policy && policy.showPrivacy" :href="policy.textUrl" target="_blank" class="footer-link">Privacy Policy</a>
            <a v-if="terms && terms.showTerm" :href="terms.textUrl" target="_blank" class="footer-link">Terms of Service</a>
            <a v-if="otherPolicy && otherPolicy.showPolicies" :href="otherPolicy.textUrl" target="_blank" class="footer-link">Other Policies</a>
          </small>
        </p>
      </div>
    </div>
  </ion-footer>
</template>

<script>

import {Api} from "@/backoffice/api/api";
import {DateTime} from "luxon";
import QrcodeVue from "qrcode.vue";

export default {
  name: "CustomFooter",
  components: {QrcodeVue},
  computed: {
    restaurant () {
      return this.$store.state.restaurantActive
    },
    disabledFooter () {
      return this.$store?.state.backConfig?.setting?.disabledFooter
    }
  },
  props: {
    data: {
      required: false,
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    text: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      editorData: '',
      terms: null,
      policy: null,
      otherPolicy: null,
      restaurantData: []
    }
  },
  async mounted () {
    const id = this.$store?.state?.restaurantActive?.restaurantId;
    //console.log(id);
    
    const response = await Api.fetchAll('restaurant');
    const restaurante = response.data;
    this.restaurantData = restaurante.find(r => r._id === id) ?? [];
    //console.log("**", this.restaurantData);

    const resId = this.$store.state.user.RestaurantId;
    if (resId && !this.text) {
      const data = this.$store.state.backConfig.restaurant;
      switch (this.type) {
        case 'invoice':
          this.editorData = data.invoiceFooterData?.text || this.editorData
          break;
        case 'order':
          this.editorData = data.orderFooterData?.text || this.editorData
          break;
        case 'quote':
          this.editorData = data.quoteFooterData?.text || this.editorData
          break;
      }
    } else {
      this.editorData = this.text
    }
    this.getTerms(resId)
    this.getPolicy(resId)
    this.getOtherPolicy(resId)

  },
  methods: {
    getURL () {
      switch (this.type) {
        case 'invoice':
          return `${Api.baseUrl}/?rid=${this.$store.state.restaurantActive.restaurantId}&payinvoice=${this.data._id}`
        case 'order':
          return `${Api.baseUrl}/${this.$store.state.restaurantActive.restaurantUrl}/reservation?orderId=${this.data._id}`
        case 'quote':
          return `${Api.baseUrl}/?rid=${this.$store.state.restaurantActive.restaurantId}&payinvoice=${this.data._id}`
      }
    },
    getFormatDate(date, formate) {
      const luxonDate = DateTime.fromISO(date);
      return luxonDate.toFormat(formate);
    },
    async getTerms (resId) {
      try {
        const respterms = await Api.fetchAll('terms');
        this.terms = respterms.data.find(t => t.RestaurantId === resId);
      } catch (e) {
      }
    },
    async getPolicy (resId) {
      try {
        const respPolicy = await Api.fetchAll('privacypolicy');
        this.policy = respPolicy.data.find(t => t.RestaurantId === resId);
      } catch (e) {
      }
    },
    async getOtherPolicy (resId) {
      try {
        const respOther = await Api.fetchAll('otherprivacy');
        this.otherPolicy = respOther.data.find(t => t.RestaurantId === resId);
      } catch (e) {
      }
    }
  }
}
</script>
<style>
.custom-order-footer {
  margin-bottom: 40px;
}
.footer-container{
  text-align: center;
  background-color: #f0f0f0;
}

.logo {
  width: 50px;
  height: 50px;
}

.footer-text, .footer-text p {
  font-size: 14px;
  line-break: anywhere;
}

.links-container {
  display: flex;
}

.footer-link {
  margin-right: 10px;
  font-size: 14px;
}
</style>