<template>
  <div>
    <div v-if="showButtons" class="ion-text-start">
      <ion-button @click="selectAll" size="small" fill="clear" v-tooltip="'Select All'">
        <span>Select All</span>
      </ion-button>
      <ion-button @click="deselectAll" size="small" fill="clear" v-tooltip="'Deselect All'">
        <span>Deselect All</span>
      </ion-button>
      <ion-button @click="deleteSelected" :disabled="!selectedItems.length" size="small" fill="clear" v-tooltip="'Delete Selected'">
        <span>Delete Selected</span>
      </ion-button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { IonButton } from '@ionic/vue';
import { Api } from '../api/api';
import { EventBus } from "../../frontend/event-bus";
import { Utils } from "../utils/utils.js";
export default {
  name: "BulkDelete",
  components: { IonButton },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type:{
      type: String,
      default: "",
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {

    selectAll() {
      //console.log(this.type)
      this.$emit('update:selectedItems', this.items.map(item => item._id));
    },

    deselectAll() {
      this.$emit('update:selectedItems', []);
    },

    async deleteSelected() {
      const totalSelected = this.selectedItems.length;

      const selectedItemsDetails = this.items.filter(item => this.selectedItems.includes(item._id));
      
      const itemsList = selectedItemsDetails.map(item => {
        const price = Utils.getFormatPrice(item.Total || (item.Payment?.[0]?.total ?? 0));

        let details = `<br/><strong>${this.$t('backoffice.form.fields.name')}:</strong> ${item?.CustomerName ?? item?.Name}`;

        if (['Order', 'assetpickup'].includes(this.type)) {
          details += `, <strong>${this.$t('backoffice.form.titles.number')}:</strong> ${item.QouteInvoice?.number || item.Number}, <strong>${this.$t('backoffice.form.titles.total')}:</strong> ${price}`;
        }

        return details;
      }).join('<br/>'); 
      this.$ionic.alertController.create({
        title: this.$t('backoffice.list.messages.confirmDelete'),
        message: `${this.$t('backoffice.form.titles.doYou')} ${totalSelected} ${this.$t('backoffice.form.titles.marked')} <br/> ${itemsList}`,
        buttons: [
          {
            text: this.$t('backoffice.list.messages.buttons.cancel'),
            role: 'cancel',
            handler: () => {
            }
          },
          {
            text: this.$t('backoffice.list.messages.buttons.delete'),
            handler: () => {
              //const entity = 'Order';
              const entity = this.type;
              try {
                const response = Api.postInBulk(entity, { selectedItems: this.selectedItems, operation: "deletebulk" });
                EventBus.$emit('refreshAfterDelete');
                this.deselectAll();
                return response;
              } catch (error) {
                console.error('Error deleting items:', error);
              }
            }
          }
        ]
      })
      .then(a => a.present());
    }

  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
