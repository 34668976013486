<template>
  <div>
    <ion-button v-if="!imageData.file" @click="openCamera()">
      {{ $t('backoffice.form.fields.takePicture') }}
    </ion-button>

    <div v-if="imageData.file">
      <a
        v-if="!photoAccepted"
        @click="previewImage"
        style="cursor: pointer; color: blue"
      >
      {{ $t('backoffice.form.fields.previewPhoto') }}
      </a>

      <div v-if="showPreview">
        <img
          :src="imageData.file"
          alt="Photo Preview"
          style="max-width: 100%"
        />
        <div style="margin-top: 10px">
          <ion-button color="success" @click="togglePreview">
            ✓ {{ isHidden ? $t('backoffice.form.fields.hidde') : $t('backoffice.form.fields.accept') }}
          </ion-button>
          <ion-button color="danger" @click="deletePhoto">✕ {{ $t('backoffice.form.fields.delete') }}</ion-button>
          <ion-button color="primary" @click="retakePhoto">↻ {{ $t('backoffice.form.fields.retake') }}</ion-button>
        </div>
      </div>
    </div>

    <a
      v-if="photoAccepted && !showPreview"
      @click="previewImage"
      style="cursor: pointer; color: blue"
    >
    {{ $t('backoffice.form.fields.previewAcceptedPhoto') }}
    </a>
  </div>
</template>

<script>
import { Camera, CameraResultType } from '@capacitor/camera'

export default {
  name: 'CameraComponent',
  data() {
    return {
      imageData: {
        file: '',
        fileName: ''
      },
      showPreview: false,
      photoAccepted: false,
      isHidden: false // New flag to track hiding state
    }
  },
  methods: {
    // Open the camera and capture an image
    async openCamera() {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.Uri
        })
        const imageUrl = image.webPath
        this.imageData.fileName = this.getImageName(image)
        let blob = await this.fetchBlob(imageUrl)
        this.imageData.file = await this.blobToBase64(blob)
        this.showPreview = true
      } catch (error) {
        console.error('Error capturing image: ', error)
      }
    },

    // Fetch image blob from URL
    async fetchBlob(blobUrl) {
      const response = await fetch(blobUrl)
      if (!response.ok) {
        throw new Error('Failed to fetch blob')
      }
      return await response.blob()
    },

    // Convert blob to base64
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },

    // Generate image name
    getImageName(image) {
      return new Date().getTime() + '.' + image.format
    },

    // Toggle between showing and hiding the photo preview
    togglePreview() {
      if (this.isHidden) {
        this.showPreview = false
        this.isHidden = false
      } else {
        this.photoAccepted = true
        this.showPreview = false
        this.isHidden = true
      }
      this.$emit('image-updated', {
        file: this.imageData.file,
        fileName: this.imageData.fileName
      });
    },

    // Delete the photo and reset states
    deletePhoto() {
      this.imageData.file = ''
      this.imageData.fileName = ''
      this.showPreview = false
      this.photoAccepted = false
      this.isHidden = false
    },

    // Retake the photo
    retakePhoto() {
      this.deletePhoto()
      this.openCamera()
    },

    // Preview the accepted photo
    previewImage() {
      this.showPreview = true
    }
  }
}
</script>

<style scoped>
.photo-preview {
  text-align: center;
  margin-top: 10px;
}

.action-buttons {
  margin-top: 10px;
}

ion-button {
  margin: 5px;
}
</style>
