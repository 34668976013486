<template>
    <ion-card>
        <ion-card-header>
            <ion-title>{{ $t('backoffice.form.titles.identificationInformation') }}</ion-title>
        </ion-card-header>
        <ion-card-content>
            <!-- ID Type Selection -->
            <ion-item>
                <ion-label position="floating">{{ $t('backoffice.form.titles.idType') }}</ion-label>
                <ion-select :value="identificationData?.idType" @ionChange="handleIdentification($event.target.value)">
                    <ion-select-option value="DriverLicense">{{ $t('backoffice.form.titles.driverLicense') }}</ion-select-option>
                    <ion-select-option value="Cedula">{{ $t('backoffice.form.titles.internationalId') }}</ion-select-option>
                </ion-select>
            </ion-item>
            <!-- Driver's License Fields -->
            <template v-if="identificationData?.idType === 'DriverLicense'">
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.nameLicense') }}</ion-label>
                    <ion-input class="input-style" @input="identificationData.licenseName = $event.target.value" :value="identificationData.licenseName" type="text" required></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.licenseNumber') }}</ion-label>
                    <ion-input class="input-style" @input="identificationData.licenseNumber = $event.target.value" :value="identificationData.licenseNumber" type="text" required></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.issueDate') }}</ion-label>
                    <ion-datetime @ionChange="identificationData.licenseIssueDate = $event.target.value" :value="identificationData.licenseIssueDate" display-format="MM/DD/YYYY"></ion-datetime>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.expirationDate') }}</ion-label>
                    <ion-datetime @ionChange="identificationData.licenseExpirationDate = $event.target.value" :value="identificationData.licenseExpirationDate" display-format="MM/DD/YYYY"></ion-datetime>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.sex') }}</ion-label>
                    <ion-select :value="identificationData.sex" @ionChange="handleSex">
                        <ion-select-option value="Male">Male</ion-select-option>
                        <ion-select-option value="Female">Female</ion-select-option>
                        <ion-select-option value="Other">Other</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.dateBirth') }}</ion-label>
                    <ion-datetime @ionChange="identificationData.dob = $event.target.value" :value="identificationData.dob" display-format="MM/DD/YYYY" required></ion-datetime>
                </ion-item>
                <ion-item style="--border-style: none;">
                    <ion-label position="floating">Country</ion-label>
                    <ion-input id="pacInput" ref="pacInput" class="input-style" type="text" name="name"
                    @input="identificationData.country = $event.target.value" 
                    :value="identificationData.country">
                    </ion-input>
                </ion-item>
                <ion-row>
                    <ion-col size="6" size-md="6" size-sm="6" size-xs="6">
                        <ion-card>
                            <ion-title position="floating">{{$t('backoffice.form.titles.height')}}</ion-title>
                            <ion-item>
                                <ion-label position="floating">Unit</ion-label>
                                <ion-select :value="identificationData.unit" @ionChange="handleUnit($event.target.value)">
                                    <ion-select-option value="Feet">Feet</ion-select-option>
                                    <ion-select-option value="Meters">Meters</ion-select-option>
                                </ion-select>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating" v-if="identificationData.unit === 'Meters'">Meters</ion-label>
                                <ion-label position="floating" v-if="identificationData.unit === 'Feet'">Feet</ion-label>
                                <ion-input 
                                class="input-style" 
                                @input="identificationData.primaryValue = $event.target.value" 
                                :value="identificationData.primaryValue" 
                                type="number">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating" v-if="identificationData.unit === 'Meters'">Centimeters</ion-label>
                                <ion-label position="floating" v-if="identificationData.unit === 'Feet'">Inches</ion-label>
                                <ion-input 
                                class="input-style" 
                                @input="identificationData.secondaryValue = $event.target.value" 
                                :value="identificationData.secondaryValue" 
                                type="number">
                                </ion-input>
                            </ion-item>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </template>
            <!-- Cedula Fields -->
            <template v-if="identificationData?.idType === 'Cedula'">
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.nameId') }}</ion-label>
                    <ion-input class="input-style" @input="identificationData.cedulaName = $event.target.value" :value="identificationData.cedulaName" type="text" required></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.identificationNumber') }}</ion-label>
                    <ion-input class="input-style" @input="identificationData.cedulaNumber = $event.target.value" :value="identificationData.cedulaNumber" type="text" required></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.expirationDate') }}</ion-label>
                    <ion-datetime @ionChange="identificationData.cedulaExpirationDate = $event.target.value" :value="identificationData.cedulaExpirationDate" display-format="MM/DD/YYYY"></ion-datetime>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.dateBirth') }}</ion-label>
                    <ion-datetime @ionChange="identificationData.dob = $event.target.value" :value="identificationData.dob" display-format="MM/DD/YYYY" required></ion-datetime>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.sex') }}</ion-label>
                    <ion-select :value="identificationData.sex" @ionChange="handleSex">
                        <ion-select-option value="Male">Male</ion-select-option>
                        <ion-select-option value="Female">Female</ion-select-option>
                        <ion-select-option value="Other">Other</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.nationality') }}</ion-label>
                    <ion-input class="input-style" @input="identificationData.nationality = $event.target.value" :value="identificationData.nationality" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('backoffice.form.titles.martialStatus') }}</ion-label>
                    <ion-select :value="identificationData.maritalStatus" @ionChange="handleMaritalStatus">
                        <ion-select-option value="Single">Single{{ $t('backoffice.form.titles.single') }}</ion-select-option>
                        <ion-select-option value="Married">Married{{ $t('backoffice.form.titles.married') }}</ion-select-option>
                        <ion-select-option value="Divorced">Divorced{{ $t('backoffice.form.titles.divorced') }}</ion-select-option>
                        <ion-select-option value="Widowed">Widowed{{ $t('backoffice.form.titles.widowed') }}</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item style="--border-style: none;">
                    <ion-label position="floating">Country</ion-label>
                    <ion-input id="pacInput" ref="pacInput" class="input-style" type="text" name="name"
                    @input="identificationData.country = $event.target.value" 
                    :value="identificationData.country">
                    </ion-input>
                </ion-item>
                <ion-row>
                    <ion-col size="6" size-md="6" size-sm="6" size-xs="6">
                        <ion-card>
                            <ion-title position="floating">{{$t('backoffice.form.titles.height')}}</ion-title>
                            <ion-item>
                                <ion-label position="floating">Unit</ion-label>
                                <ion-select :value="identificationData.unit" @ionChange="handleUnit($event.target.value)">
                                    <ion-select-option value="Feet">Feet</ion-select-option>
                                    <ion-select-option value="Meters">Meters</ion-select-option>
                                </ion-select>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating" v-if="identificationData.unit === 'Meters'">Meters</ion-label>
                                <ion-label position="floating" v-if="identificationData.unit === 'Feet'">Feet</ion-label>
                                <ion-input 
                                class="input-style" 
                                @input="identificationData.primaryValue = $event.target.value" 
                                :value="identificationData.primaryValue" 
                                type="number">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating" v-if="identificationData.unit === 'Meters'">Centimeters</ion-label>
                                <ion-label position="floating" v-if="identificationData.unit === 'Feet'">Inches</ion-label>
                                <ion-input 
                                class="input-style" 
                                @input="identificationData.secondaryValue = $event.target.value" 
                                :value="identificationData.secondaryValue" 
                                type="number">
                                </ion-input>
                            </ion-item>
                        </ion-card>
                    </ion-col>
                </ion-row>
                <ion-item>
                    <ion-label position="floating">Occupation{{ $t('backoffice.form.titles.occupation') }}</ion-label>
                    <ion-input class="input-style" @input="identificationData.occupation = $event.target.value" :value="identificationData.occupation" type="text"></ion-input>
                </ion-item>
            </template>
            <ion-row>
                <ion-col size-xs="12" size="8" size-lg="8">
                  <ion-card style="width: 50%;">
                    <ion-label v-if="checkImage()">
                      <img :src="identificationData.file" />
                    </ion-label>
                    <CameraComponent @image-updated="handleImage" />
                  </ion-card>
                </ion-col>
            </ion-row>
        </ion-card-content>
    </ion-card>
</template>
<script>
import CameraComponent from '../../backoffice/components/CameraComponent.vue'

export default {
    data() {
        return {
            identificationData: {
                idType: "", // DriverLicense or Cedula
                // Driver's License Fields
                licenseName: "",
                licenseNumber: "",
                licenseIssueDate: "",
                licenseExpirationDate: "",
                sex: "",
                unit: "",
                dob: "",
                height: "",
                // Cedula Fields
                cedulaName: "",
                cedulaNumber: "",
                cedulaExpirationDate: "",
                nationality: "",
                maritalStatus: "",
                occupation: "",
                file: null,
                fileName: '',
                country: '',
                pacInput: null,
                primaryValue: '',
                secondaryValue: '',
            },
        };
    },
    components: {
        CameraComponent
    },
    props:{
        identification:{
            type: Object,
        },
    },
    created(){
        //console.log("here",this.identification)
        this.identificationData = this.identification
        this.identificationData.file = this.identification?.imageUrl
    },
    mounted() {
        this.initApp();
    },
    methods: {

        async initApp() {
            await new Promise(resolve => setTimeout(resolve, 500));
            const input = document.querySelector('#pacInput input');
            const autocomplete = new google.maps.places.Autocomplete(input);

            autocomplete.setTypes(['(regions)']); // Restrict autocomplete to countries

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place && place.address_components) {
                // Loop through address components to find the country
                for (const addressComponent of place.address_components) {
                    if (addressComponent.types.includes("country")) {
                    this.identificationData.country = addressComponent.long_name;
                    //this.abreviature = addressComponent.short_name;
                    break; // We found the country, so exit the loop
                    }
                }
                }
            });
        },

    checkImage: function() {
        return this.identificationData.file != null && this.identificationData.file != '';
    },

    handleImage: function(imageData) {
        if (imageData && imageData.file && imageData.fileName) {
            console.log('Captured Image:', imageData.file);
            console.log('Captured File Name:', imageData.fileName);

            this.identificationData.fileName = imageData.fileName;

            this.createBase64Img(imageData.file);
        } else {
            console.error("imageData no contiene las propiedades necesarias.");
        }
    },

    createBase64Img: function(fileObject) {
        if (typeof fileObject === 'string' && fileObject.startsWith('data:image/')) {
            this.identificationData.file = fileObject;
            console.log('Base64 Image set directly:', this.identificationData.file);
        } else {
            console.error('El objeto no es un string en formato Base64:', fileObject);
        }
    },

        handleIdentification(value){
            this.identificationData.idType = value;
            this.$emit('update-identification', this.identificationData);
            this.resetIDFields()
        },
        handleSex(event){
            this.identificationData.sex = event.target.value;
        },
        handleUnit(event){
            this.identificationData.unit = event;
                 
            this.identificationData.primaryValue = '';
            this.identificationData.secondaryValue = '';
        },
        handleMaritalStatus(event){
            this.identificationData.maritalStatus = event.target.value;
        },
        resetIDFields() {
            // Reset all ID-related fields when switching between ID types
            this.identificationData = {
            ...this.identificationData,
            licenseName: "",
            licenseNumber: "",
            licenseIssueDate: "",
            licenseExpirationDate: "",
            sex: "",
            unit: "",
            dob: "",
            height: "",
            cedulaName: "",
            cedulaNumber: "",
            cedulaExpirationDate: "",
            nationality: "",
            maritalStatus: "",
            occupation: "",
            file: null,
            fileName: '',
            country: '',
            primaryValue: '',
            secondaryValue: '',
            };
            this.$emit('update-identification', this.identificationData);
        },
    },
    watch: {
        identificationData: {
            deep: true,
            handler(newValue) {
                this.$emit('update-identification', newValue);
            },
        },
    },
};
</script>